import { createSelector } from '@reduxjs/toolkit';

import {
  PromoBannerType,
  type TPromoBannerCommon,
  type TPromoBannerMerchandisingProps,
} from '@/features/promoBanners/types';
import { type RootState } from '@/store';
import { type TOptional } from '@/types/common';

export const selectPromoBanners = (state: RootState) => state?.promoBanners?.data;

export const selectPromoBannerPlaces = createSelector(selectPromoBanners, (items) =>
  items.map((item: TPromoBannerCommon) => item?.placeId),
);

export const selectMerchandisingBannerDetails = createSelector(selectPromoBanners, (banners) =>
  banners?.find((banner): banner is TPromoBannerMerchandisingProps => banner?.type === PromoBannerType.merchandising),
);

export const selectPromoColors = createSelector(
  selectMerchandisingBannerDetails,
  (banner?: TPromoBannerMerchandisingProps): TOptional<Record<string, string>> => {
    const prefix = 'colorSwatch';
    if (banner) {
      const entries = Object.entries(banner).filter(([key, _]) => key.startsWith(prefix)) as [string, string][];
      if (entries.length)
        return Object.fromEntries<string>(entries.map(([key, value]) => [key.substring(prefix.length), value]));
    }
  },
);
