import dynamic from 'next/dynamic';
import { memo, useEffect } from 'react';

import i12 from '@publicImages/animations/Sailor/1-2.json';
import i13 from '@publicImages/animations/Sailor/1-3.json';
import i14 from '@publicImages/animations/Sailor/1-4.json';
import i21 from '@publicImages/animations/Sailor/2-1.json';
import i23 from '@publicImages/animations/Sailor/2-3.json';
import i24 from '@publicImages/animations/Sailor/2-4.json';
import i31 from '@publicImages/animations/Sailor/3-1.json';
import i32 from '@publicImages/animations/Sailor/3-2.json';
import i34 from '@publicImages/animations/Sailor/3-4.json';
import i41 from '@publicImages/animations/Sailor/4-1.json';
import i42 from '@publicImages/animations/Sailor/4-2.json';
import i43 from '@publicImages/animations/Sailor/4-3.json';

import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';

const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

const animations: Record<string, unknown> = {
  i12,
  i13,
  i14,
  i21,
  i23,
  i24,
  i31,
  i32,
  i34,
  i41,
  i42,
  i43,
};

const getAnimation = (previous: number, next: number) => {
  if (previous === next) {
    previous = previous !== 1 ? 1 : 2;
  }
  return animations[`i${previous}${next}`];
};

const STORAGE_KEY = 'PREVIOUS_VALUE_SAILOR_ANIMATION';

const SailorAnimation = ({ defaultSailor = 2, selectedSailor }: { defaultSailor?: number; selectedSailor: number }) => {
  const previous = getSessionStorageValue(STORAGE_KEY) ?? defaultSailor;
  useEffect(() => {
    setSessionStorageValue(STORAGE_KEY, selectedSailor);
  }, [selectedSailor]);

  return (
    <Lottie
      animationData={getAnimation(previous, selectedSailor)}
      aria-hidden
      autoplay
      loop={false}
      style={{
        height: 78,
        margin: '0 auto',
        width: 287,
      }}
    />
  );
};

export default memo(SailorAnimation);
