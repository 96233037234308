import classnames from 'classnames';

import './Image.scss';

interface ImageProps {
  alt: string;
  /** As height/width. Will wrap image in a container with that aspect ratio if set */
  aspectRatio?: number;
  /** Renders slides as divs with background images */
  bgImage?: boolean;
  categoryName?: string;
  /** Added to img tag, or the container div if aspectRatio is set */
  className?: string;
  /** Sets max-width 100% on img */
  fluid?: boolean;
  removePaddingBy?: number;
  src: string;
}

const Image = ({
  alt = '',
  aspectRatio,
  bgImage = false,
  categoryName = '',
  className = '',
  fluid = false,
  removePaddingBy = 0,
  src,
  ...props
}: ImageProps) => {
  const imageClasses = classnames({
    '-fluid': fluid || aspectRatio,
    [className]: className && !aspectRatio,
    Image: true,
  });

  const containerClasses = classnames({
    '-fixedRatio': true,
    [className]: className,
    ImageContainer: true,
  });

  let slide = <img {...props} alt={alt} className={imageClasses} src={src} />;

  if (bgImage && aspectRatio) {
    slide = (
      <div
        aria-label={alt}
        className="ImageContainer__imgDiv"
        role="img"
        style={{
          backgroundImage: `url("${src}")`,
        }}
      />
    );
  }

  if (aspectRatio) {
    const padding = aspectRatio * 100 - removePaddingBy;
    return (
      <div className={containerClasses}>
        <div className="ImageContainer__inner" style={{ paddingBottom: `${padding}%` }}>
          {slide}
          <p className="title --mobile">{categoryName}</p>
        </div>
      </div>
    );
  }

  return slide;
};

export default Image;
