import {
  ACCOUNTS_DASHBOARD_DATA_ERROR,
  ACCOUNTS_DASHBOARD_DATA_SUCCESS,
  ADD_OTHER_GUEST_DETAILS_ERROR,
  ADD_OTHER_GUEST_DETAILS_SUCCESS,
  CLEAR_LOGGED_IN_SESSION_DATA,
  UPDATE_RESERVATION_ERROR_STATUS,
  UPDATE_RESERVATION_SUCCESS_STATUS,
} from '@/constants/actionTypes';

const dashboardReducer = (state = { data: [], error: {} }, action) => {
  switch (action.type) {
    case ACCOUNTS_DASHBOARD_DATA_ERROR:
    case ADD_OTHER_GUEST_DETAILS_ERROR:
      return { ...state, data: [], error: action.payload };
    case ACCOUNTS_DASHBOARD_DATA_SUCCESS:
    case ADD_OTHER_GUEST_DETAILS_SUCCESS:
    case CLEAR_LOGGED_IN_SESSION_DATA:
      return { ...state, data: action.payload, error: {} };
    case UPDATE_RESERVATION_ERROR_STATUS:
    case UPDATE_RESERVATION_SUCCESS_STATUS:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
