/* eslint-disable perfectionist/sort-objects */
import Observer from '../helpers/observer';

// if 1+ more track events need to send for same action _ use observer

const TRACKING_EVENTS_FOR_OBSERVER = {
  CHOOSE_SAILING_PAGE_LOADED: 'CHOOSE_SAILING_PAGE_LOADED',
  SAILING_PACK_APPEARED: 'SAILING_PACK_APPEARED',
  CHOOSE_CABINS_APPEARED: 'CHOOSE_CABINS_APPEARED',
  CHOOSE_CABIN_CATEGORY_APPEARED: 'CHOOSE_CABIN_CATEGORY_APPEARED',
  CHOOSE_CABIN_META_CATEGORY_APPEARED: 'CHOOSE_CABIN_META_CATEGORY_APPEARED',
  CHECKOUT_SUMMARY_PAGE_LOADED: 'CHECKOUT_SUMMARY_PAGE_LOADED',
  CHECKOUT_SUBSCRIBED_FOR_NEWS: 'CHECKOUT_SAILOR_DETAILS_SUBSCRIBED_FOR_NEWS',
  INITIATE_CHECKOUT: 'INITIATE_CHECKOUT',
  PAYMENT_PAGE_LOADED: 'PAYMENT_PAGE_LOADED',
  INFO_CLICK: 'INFO_CLICKED',
  SAILOR_FORM_FIELD_CHANGED: 'SAILOR_FORM_FIELD_CHANGED',
};

const trackingEventsObserver = new Observer();

export default {
  trackingEventsObserver,
  TRACKING_EVENTS_FOR_OBSERVER,
};
