import { usePathname, useSearchParams } from 'next/navigation';

import { useAsyncRouter } from '@/hooks/router/useAsyncRouter';

import { setPathname, setRouter, setSearchParams } from './history';

const useHackForRouter = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useAsyncRouter();
  setSearchParams(searchParams);
  setPathname(pathname);
  setRouter(router);
};

export default useHackForRouter;
