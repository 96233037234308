import { CLEAR, SET_IS_ELIGIBLE, SET_IS_READY } from './actions';

const initialState = {
  isEligible: false,
  isReady: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR:
      return { ...initialState };
    case SET_IS_ELIGIBLE:
      return { ...state, isEligible: action.payload };
    case SET_IS_READY:
      return { ...state, isReady: !!action.isReady };
    default:
      return state;
  }
};

export default reducer;
