'use client';

import { type ComponentProps, useEffect, useMemo, useState } from 'react';

import { voyageSearchCabinExhaustErrorSetAction, voyageSearchVoyageExhaustErrorSetAction } from '@/actions';
import AccessKeyBanner from '@/components/AccessKeyBanner';
import AdvancedSelection from '@/components/ChooseVoyage/AdvancedSelection';
import PackageCardDetails from '@/components/ChooseVoyage/PackageCardDetails';
import MNVVOfferBanner from '@/components/MNVVOfferBanner';
import PromoBannerPlace, { PromoBannerPlaceId } from '@/components/PromoBanner/Place';
import UIResource from '@/components/UIResource';
import WarningBox, { WarningTarget } from '@/components/WarningBox';
import WarningBoxView from '@/components/WarningBox/View';
import WhatsIncluded from '@/components/WhatsIncluded';
import { type TUIResourceId, useUIResourcePool } from '@/ducks/common/resources';
import { selectPriceType } from '@/ducks/filters/selectors';
import useClearWarningOnAway from '@/features/warnings/hooks/useClearWarningOnAway';
import { WarningType } from '@/features/warnings/types';
import withSideqikScriptForBookingFlow from '@/helpers/hoc/utility/withSideqikScriptForBookingFlow';
import { useGetIsTaxIncluded } from '@/helpers/pricing/lib/tax';
import { useAppDispatch, useAppSelector } from '@/store';
import { WHATS_INCLUDED_KEYS } from '@/types/vwo';

import '../PackageResults.scss';

const UI_RESOURCE_POOL = {
  cabinExhaustLabel: 'CabinExhaust.ChooseVoyage.error',
  voyageExhaustLabel: 'VoyageExhaust.ChooseVoyage.error',
} as const;

type TProps = {
  ssrInitial?: ComponentProps<typeof PackageCardDetails>['ssrInitial'];
};

const PackageItineraryResults = ({ ssrInitial }: TProps) => {
  const dispatch = useAppDispatch();

  const cabinExhaustError = useAppSelector((state) => state?.voyagePlanner?.itineraryResults?.cabinExhaustError);
  const voyageExhaustError = useAppSelector((state) => state?.voyagePlanner?.itineraryResults?.voyageExhaustError);
  const priceType = useAppSelector(selectPriceType);

  const { cabinExhaustLabel, voyageExhaustLabel } = useUIResourcePool(UI_RESOURCE_POOL);
  const [showCabinExhaustError, setShowCabinExhaustError] = useState(false);
  const [showVoyageExhaustError, setVoyageExhaustError] = useState(false);
  const isTaxIncluded = useGetIsTaxIncluded();

  useClearWarningOnAway(WarningTarget.chooseVoyage);

  useEffect(() => {
    if (cabinExhaustError) {
      dispatch(voyageSearchCabinExhaustErrorSetAction(false));
      setShowCabinExhaustError(true);
    }
    if (voyageExhaustError) {
      dispatch(voyageSearchVoyageExhaustErrorSetAction(false));
      setVoyageExhaustError(true);
    }
  }, [cabinExhaustError, voyageExhaustError]);

  const errorList = useMemo(() => {
    const errors = [showCabinExhaustError && cabinExhaustLabel, showVoyageExhaustError && voyageExhaustLabel].filter(
      Boolean,
    );

    if (errors.length === 1) {
      return errors[0];
    }

    return (
      <>
        {errors.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </>
    );
  }, [cabinExhaustLabel, showCabinExhaustError, showVoyageExhaustError, voyageExhaustLabel]);

  return (
    <div className="PackageItineraryResults__body">
      <WhatsIncluded vwoFeatureVariableKey={WHATS_INCLUDED_KEYS.CHOOSE_VOYAGE_PAGE} />
      {(showCabinExhaustError || showVoyageExhaustError) && (
        /* TODO: It's better to use <WarningBox/> instead with suitable action */
        <WarningBoxView
          id="CabinExhaustChooseVoyageErrors"
          text={errorList}
          title="This page contains errors 🤦"
          type={WarningType.ERROR}
        />
      )}
      <AdvancedSelection />
      <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsB} />
      <WarningBox className="PackageItineraryResults__warningBox" scrollToBox target={WarningTarget.chooseVoyage} />
      <AccessKeyBanner />
      <MNVVOfferBanner />
      <PackageCardDetails ssrInitial={ssrInitial} />

      <div className="PackageItineraryResults__footer" suppressHydrationWarning>
        <UIResource
          id={`NewChooseVoyage.TaxesFees${isTaxIncluded ? 'Included' : 'Excluded'}.${priceType}` as TUIResourceId}
        />
      </div>
    </div>
  );
};

export default withSideqikScriptForBookingFlow(PackageItineraryResults, {});
