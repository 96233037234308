import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TAgencyAgentResponse } from '@/infra/types/fmlink/agent';

const initialState = {} as TAgencyAgentResponse;

/**
 * Case Reducers
 */

const fillCase: CaseReducer<TAgencyAgentResponse, PayloadAction<TAgencyAgentResponse>> = (_, { payload }) => payload;

const clearCase: CaseReducer<TAgencyAgentResponse, PayloadAction<undefined>> = () => ({ ...initialState });

const { actions, reducer } = createSlice({
  initialState,
  name: 'agentData',
  reducers: { clear: clearCase, fill: fillCase },
});

export const { clear: agentDataClear, fill: agentDataFill } = actions;

export default reducer;
