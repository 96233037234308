import type { RefObject } from 'react';

import delay from '@/helpers/delay';

type ScrollIntoViewOptions = {
  behavior?: 'auto' | 'instant' | 'smooth';
  block?: 'center' | 'end' | 'nearest' | 'start';
  inline?: 'center' | 'end' | 'nearest' | 'start';
};

const scrollIntoView = (
  selector: string,
  ref?: RefObject<HTMLElement>,
  options?: boolean | ScrollIntoViewOptions,
): void => {
  (ref?.current != null ? ref.current : document)
    ?.querySelector(selector)
    ?.scrollIntoView(
      Object.assign(
        { behavior: 'smooth', block: 'center' } as ScrollIntoViewOptions,
        typeof options === 'boolean' ? { block: options ? 'start' : 'end' } : options,
      ),
    );
};

export const scrollIntoViewAsync = (
  selector: string,
  ref?: RefObject<HTMLElement>,
  options?: boolean | ScrollIntoViewOptions,
  timeout: number = 0,
): Promise<void> => delay(timeout).then(() => scrollIntoView(selector, ref, options));

export default scrollIntoView;
