import type { RootState } from '@/store';

import { getFiltersOptions } from '@/ducks/filtersOptions';
import { getMnvvReservation } from '@/ducks/mnvv/utils';

import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '../types';
import { toInt } from '../utils';

const isValueValidForURL = ({
  searchParams,
  state,
  value,
}: {
  searchParams: URLSearchParams;
  state: RootState;
  value: string | null | string[];
}) => {
  const options = getFiltersOptions(state);
  const { isMNVV } = getMnvvReservation(searchParams);
  const sailors = toInt(value);

  if (isMNVV && sailors === 1) {
    return false;
  }

  return (
    sailors === null ||
    (sailors >= options.minCabinOccupancy && sailors <= options.maxCabinCount * options.maxCabinOccupancy)
  );
};

const sailors: FiltersConfigValue<FiltersTypes[FILTER_KEY.sailors]> = {
  getValue: ({ searchParams, state, value }) => {
    const defaultValue = getFiltersOptions(state).defaultFilters.sailors;
    const sailors = toInt(value);

    return (isValueValidForURL({ searchParams, state, value }) && sailors) || defaultValue;
  },
  isValueValidForURL,
  serializeValue(value, { state }) {
    const options = getFiltersOptions(state);
    return !value || value.toString() === options?.defaultFilters?.sailors.toString() ? null : value;
  },
};

export default sailors;
