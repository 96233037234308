'use client';

import { useCallback } from 'react';

import Tooltip, { TooltipMode } from '@/components/Tooltip';
import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import { resetFmFlow } from '@/ducks/fm/reset';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

const {
  tracker: {
    common: { trackIconClick, trackLinkClick },
  },
  trackerConstants,
} = tagmanager;

const onOpenTooltip = () => {
  trackIconClick({ label: trackerConstants.EVENT_LABELS.FM_INFO });
};

type TProps = {
  agentName: string;
};

const FMAgencyTooltip = ({ agentName }: TProps) => {
  const dispatch = useAppDispatch();

  const optOut = useCallback(() => {
    dispatch(resetFmFlow());
    trackLinkClick({ label: trackerConstants.EVENT_LABELS.FM_OPT_OUT });
  }, [dispatch]);

  return (
    <Tooltip
      isInline
      mode={TooltipMode.INFO}
      onOpen={onOpenTooltip}
      openerClassName="FM__tooltipOpener"
      popupClassName="FM__tooltipContent"
      side="bottom"
    >
      <UIResourceHtml
        className="tooltipParagraph"
        id="FMLinking.tooltip"
        node="span"
        values={{ Name: agentName || '' }}
      />
      <span className="tooltipParagraph">
        <UIResource id="FMLinking.optOut" />
        <button className="optOutButton" onClick={optOut}>
          <UIResource id="FMLinking.optOutButton" />
        </button>
      </span>
    </Tooltip>
  );
};

export default FMAgencyTooltip;
