import { useMemo } from 'react';

import UIResource from '@/components/UIResource';
import { combineUIResource, type TUIResourceId, type TUIResourceValueMap } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { useAppSelector } from '@/store';

type Props = {
  defaultMessage: string;
  id: string;
  values?: TUIResourceValueMap;
};

/**
 * return JSX.Element with value from state.common.resources by key
 * @param id - string - key of value in the state.common.resources
 * @param defaultMessage - string, object - default value if there is no value from state.common.resources by id
 * @param values - object - collection of values which are passing in the message pattern with arguments
 * @deprecated
 */
const FormattedMessage = ({ defaultMessage, id, values }: Props) => {
  const hasMessage = !!useAppSelector(selectResources)?.[id as TUIResourceId];
  const content = useMemo(
    // @ts-expect-error We would need to retype combineUIResource to fix that. Faster to remove FormattedMessage
    () => (!hasMessage ? combineUIResource({ [id]: defaultMessage }, id, values) : undefined),
    [defaultMessage, hasMessage, id, values],
  );
  return <>{hasMessage ? <UIResource id={id as TUIResourceId} values={values} /> : content}</>;
};

export default FormattedMessage;
