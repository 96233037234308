import isEmpty from 'lodash/isEmpty';

import type { Port } from '@/infra/types/voyageInfo/port';

import { selectLookup } from '@/ducks/common/selectors';
import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions';

import { toStringArray } from '../utils';

const homePorts: FiltersConfigValue<FiltersTypes[FILTER_KEY.homePorts]> = {
  getValue: ({ state, value }) => {
    const data = selectLookup(state)?.homePorts || getFiltersOptions(state)?.homePorts;
    return toStringArray(value).reduce<Port[]>((ports, code) => {
      const port = data?.find((port) => port.code === code);
      return port ? [...ports, port] : ports;
    }, []);
  },
  serializeValue(value) {
    return !isEmpty(value) ? value.map(({ code }) => code.toString()) : null;
  },
};

export default homePorts;
