import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import type { TCabinCategory, TMetaCabinCategory } from '@/infra/types/cabin';
import type { RootState } from '@/store';

import { selectCurrencyCode } from '@/ducks/filters/selectors';

const selectCabinCategoriesSlice = (state: RootState) => state.voyagePlanner.chooseCabin?.cabinCategories;

export const selectCabinCategories = createSelector(selectCabinCategoriesSlice, (slice) => slice?.data);
export const selectIsCabinCategoriesLoaded = createSelector(selectCabinCategoriesSlice, (slice) => slice?.isLoaded);
export const selectIsCabinCategoriesIsLoading = createSelector(selectCabinCategoriesSlice, (slice) => slice?.isLoading);
export const selectCabinCategoryBySubCategoryCode = createSelector(
  [selectCabinCategories, (_, subCategoryCode) => subCategoryCode],
  (cabinCategories, subCategoryCode) =>
    cabinCategories.find((category: TCabinCategory) =>
      category.submetas.find((subCategory) => subCategory.code === subCategoryCode),
    ),
);

const selectMetaCategoriesSlice = (state: RootState) => state.voyagePlanner.chooseCabin?.metaCabinCategories;

export const selectMetaCategories = createSelector(selectMetaCategoriesSlice, (slice) => slice?.data);
export const selectMetaCategoriesError = createSelector(selectMetaCategoriesSlice, (slice) => slice?.error);

export const selectIsMetaCategoriesLoaded = createSelector(selectMetaCategoriesSlice, (slice) => slice?.isLoaded);
export const selectIsMetaCategoriesIsLoading = createSelector(selectMetaCategoriesSlice, (slice) => slice?.isLoading);

export const selectIsMetaCategoriesOutdated = createSelector(
  selectMetaCategories,
  selectCurrencyCode,
  (categories, currencyCode) =>
    categories?.some(
      (category: TMetaCabinCategory) =>
        isEmpty(category?.bookingPriceDetails) || category.bookingPriceDetails.currencyCode !== currencyCode,
    ),
);

export const selectIsMetaCategoriesReady = createSelector(
  selectIsMetaCategoriesLoaded,
  selectIsMetaCategoriesOutdated,
  (isLoaded, isOutdated) => isLoaded && !isOutdated,
);
