import isEmpty from 'lodash/isEmpty';

import type { RootState } from '@/store';

export const selectVoyagePlanner = (state: RootState) => state.voyagePlanner;

export const selectSailorDetails = (state: RootState) => selectVoyagePlanner(state)?.sailorDetails;

export const selectInsuranceFlyoutData = (state: RootState) =>
  selectSailorDetails(state)?.travelInsuranceFlyoutData?.data;

export const selectWhatsIncluded = (state: RootState) => selectVoyagePlanner(state)?.whatsIncluded?.data;
export const selectIsWhatsIncludedLoaded = (state: RootState) =>
  selectVoyagePlanner(state)?.whatsIncluded?.loaded || !isEmpty(selectVoyagePlanner(state)?.whatsIncluded?.error);

export const selectIsFilteringLoading = (state: RootState) =>
  state?.chooseVoyageNew?.isLoading === true || state?.chooseVoyageNew?.isLoading === null;
