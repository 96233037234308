import React, { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import FormCheck from '@/components/elements/FormCheck';
import FilterDestination from '@/components/Icon/filterDestination';
import RefinementButtons from '@/components/ResultRefinements/RefinementButtons';
import RefinementPopover from '@/components/ResultRefinements/RefinementPopover';
import RefinementPopoverMobileHeader from '@/components/ResultRefinements/RefinementPopover/MobileHeader';
import { Tabs, TabsContent } from '@/components/Tabs';
import UIResource from '@/components/UIResource';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import DestinationItemsListBox from './ItemsListBox';
import DestinationRegionsListBox from './RegionsListBox';
import DestinationToggle from './Toggle';
import { DestinationType } from './types';
import useDestinationRefinementData from './useDestinationRefinementData';
import { type DestinationRefinementProps, getCheckedItemsIds, isWholeChecked } from './utils';

import styles from './PopupMobile.module.scss';

const itinerariesListId = 'mobile-itineraries-list';
const portsOfCallListId = 'mobile-ports-of-call-list';

const DestinationRefinementPopupMobile = (props: DestinationRefinementProps) => {
  const {
    allItemsIds,
    ariaLiveMessageItems,
    destinationType,
    getRegions,
    isDataApplied,
    isDataChanged,
    onDestinationTypeChange,
    onItemCheck,
    onRegionCheck,
    onRegionSelect: onRegionSelectOrig,
    onReset: onResetOrig,
    onSubmit,
    popoverProps,
    popoverProps: { closePopover, isOpen },
    selectedRegion,
    selectedRegionId,
  } = useDestinationRefinementData(props);

  const backButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    if (!showItems) {
      document.getElementById(`open-region-items-${selectedRegionId}`)?.focus();
    }
  }, [showItems, selectedRegionId]);

  useEffect(() => {
    if (!isOpen) {
      setShowItems(false);
    }
  }, [isOpen]);

  const onClickBack = useCallback(
    (event: React.MouseEvent) => {
      if (showItems) {
        setShowItems(false);
      } else {
        closePopover?.({ event, payload: { closedByBackButton: true } });
      }
    },
    [showItems, closePopover],
  );

  const onClickClose = useCallback(
    (event: React.MouseEvent) => {
      closePopover?.({ event });
    },
    [closePopover],
  );

  const onRegionSelect = useCallback(
    (...args: Parameters<typeof onRegionSelectOrig>) => {
      onRegionSelectOrig(...args);
      setShowItems(true);
      backButtonRef?.current?.focus();
    },
    [onRegionSelectOrig],
  );

  const onCheckAll = useCallback(() => onRegionCheck(selectedRegionId), [onRegionCheck, selectedRegionId]);
  const onCheckAllKeyDown = useOnKeyDown(onCheckAll, { isDefaultPrevented: true });

  const onReset = useCallback(
    (...args: Parameters<typeof onResetOrig>) => {
      onResetOrig(...args);
      backButtonRef?.current?.focus();
    },
    [onResetOrig],
  );

  if (!isOpen) {
    return null;
  }

  const allChecked = isWholeChecked(selectedRegion, destinationType, allItemsIds);

  return (
    <RefinementPopover
      {...popoverProps}
      dialogLabel="Destination Options"
      focusTrapDisableEvents={['x-arrows', 'y-arrows']}
      id="DestinationRefinement"
    >
      <div className={cn(styles.root, { [styles._showItems]: showItems })}>
        <RefinementPopoverMobileHeader
          backButtonRef={backButtonRef}
          className={styles.header}
          icon={<FilterDestination />}
          onClickBack={onClickBack}
          onClickClose={onClickClose}
        >
          <UIResource id="DestinationRefinement.Book" />
        </RefinementPopoverMobileHeader>

        <div aria-live="polite" className="sr-only" role="status">
          {ariaLiveMessageItems}
        </div>

        {showItems && selectedRegion && (
          <div className={styles.subHeader}>
            <div className={styles.destinationTitle}>{selectedRegion.label}</div>
            <div className={styles.selectAll}>
              <FormCheck
                checked={allChecked}
                id={`ALL-${selectedRegionId}`}
                indeterminate={
                  !allChecked && getCheckedItemsIds(allItemsIds, destinationType, selectedRegionId).length > 0
                }
                inputTabIndex={0}
                labelProps={{ tabIndex: -1 }}
                name="allCheck"
                onChange={onCheckAll}
                onKeyDown={onCheckAllKeyDown}
                tabIndex={-1}
                type="checkbox"
              >
                <span className="allItineraryCheckText">
                  <UIResource
                    id="Destination.allCheckbox"
                    values={{
                      count: selectedRegion?.allItemsIds.length,
                      toggleValue: destinationType,
                    }}
                  />
                </span>
              </FormCheck>
            </div>
          </div>
        )}

        <Tabs className={styles.details} onValueChange={onDestinationTypeChange} value={destinationType}>
          <DestinationToggle className={styles.toggle} destinationType={destinationType} />

          <DestinationRegionsListBox
            allItemsIds={allItemsIds}
            aria-controls={`${itinerariesListId} ${portsOfCallListId}`}
            avoidRegionSelectOnCheck
            className={cn(styles.listBox, styles.destinationsListBox)}
            destinationType={destinationType}
            getRegions={getRegions}
            id="regions-list"
            listBoxNavigationDisabled
            onCheck={onRegionCheck}
            onSelectItem={onRegionSelect}
            value={selectedRegionId}
          />

          <TabsContent
            className={styles.itemsList}
            preserveNativeAriaLabelledby
            role="tabpanel"
            tabIndex={-1}
            value={DestinationType.ITINERARIES}
          >
            <DestinationItemsListBox
              allItemsIds={allItemsIds}
              aria-labelledby={`${itinerariesListId}-label`}
              className={styles.listBox}
              destinationType={destinationType}
              id={itinerariesListId}
              onCheck={onItemCheck}
              scrollIntoViewBlock="center"
              selectedRegion={selectedRegion}
            />
          </TabsContent>

          <TabsContent
            className={styles.itemsList}
            preserveNativeAriaLabelledby
            role="tabpanel"
            tabIndex={-1}
            value={DestinationType.PORTS_OF_CALL}
          >
            <DestinationItemsListBox
              allItemsIds={allItemsIds}
              aria-labelledby={`${portsOfCallListId}-label`}
              className={styles.listBox}
              destinationType={destinationType}
              id={portsOfCallListId}
              onCheck={onItemCheck}
              scrollIntoViewBlock="center"
              selectedRegion={selectedRegion}
            />
          </TabsContent>
        </Tabs>
      </div>
      <RefinementButtons
        isDataApplied={isDataApplied}
        isDataChanged={isDataChanged}
        onApply={onSubmit}
        onReset={onReset}
        withGlow
      />
    </RefinementPopover>
  );
};

export default DestinationRefinementPopupMobile;
