import type { HttpError } from '@/helpers/api/utils';

import { fetchMetaCategories } from '@/ducks/cabinsCommon/metaCategories/api';
import { selectPayloadForCabinsFetch } from '@/ducks/cabinsCommon/selectors';
import { clearChoseCategoriesAndInvoice } from '@/ducks/voyageInfo/category/actions';
import { backToMainPage, toCategoryPage } from '@/ducks/voyageInfo/category/navigation';
import { setWarningAnyCabinsExhausted, setWarningCabinCategoryExhausted } from '@/features/warnings/actions';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import { BookNowFailureCode, type TApiErrorData } from '@/infra/types/api/common';
import { type TMetaCabinCategory } from '@/infra/types/cabin';
import { rootStore } from '@/store';
import { type TOptional } from '@/types/common';

const isEquivalentInTokens = (pattern: string, value: TOptional<string>): TOptional<boolean> => {
  if (value && pattern) {
    const reTokens = /[^a-z0-9]+/gi;
    const [a, b] = [value, pattern].map((str) => str.replace(reTokens, ' ').trim().toLowerCase());
    return a!.startsWith(b!);
  }
};

const CATCHABLE_EXHAUST_ERROR = {
  code: BookNowFailureCode.CABIN_EXHAUSTED,
  message: 'Seaware Error: 323: The booking is waitlisted for one or more Selling limit',
};

const bookNowExhaustCatcher = async (error: HttpError): Promise<TOptional<boolean>> => {
  const data = error.responseBody;
  const { status } = error?.response ?? ({} as Response);
  if (status < 400) return;

  const { code, message } = (data ?? {}) as TApiErrorData;
  if (CATCHABLE_EXHAUST_ERROR.code === code || isEquivalentInTokens(CATCHABLE_EXHAUST_ERROR.message, message)) {
    const { dispatch, getState } = rootStore!;

    const searchParams = getCurrentSearchParams();

    const payload = selectPayloadForCabinsFetch(getState());
    const voyageId = searchParams.get('voyageId')!;

    let metaCategories;
    try {
      metaCategories = await fetchMetaCategories({ ...payload, voyageId });
    } catch {
      /* nothing */
    }
    const hasAnyCabins = metaCategories?.some((metaCategory: TMetaCabinCategory) => metaCategory?.isAvailable);
    if (hasAnyCabins) {
      dispatch(setWarningCabinCategoryExhausted(true));
      dispatch(clearChoseCategoriesAndInvoice());
      toCategoryPage();
    } else {
      dispatch(setWarningAnyCabinsExhausted(true));

      backToMainPage({ withCharter: true, withFilters: true });
    }
    return true;
  }
};

export default bookNowExhaustCatcher;
