import type { TImage, TLink, TMediaSet, TNullable } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

export const enum PromoBannerPlaceId {
  itineraryResultsA = 'itineraryResultsA',
  itineraryResultsB = 'itineraryResultsB',
  itineraryResultsC = 'itineraryResultsC',
  fullCruiseDetailsA = 'fullCruiseDetailsA',
  preCheckoutA = 'preCheckoutA',
  preCheckoutB = 'preCheckoutB',
  preCheckoutC = 'preCheckoutC',
  preCheckoutD = 'preCheckoutD',
  chooseMetaC = 'chooseMetaC',
  chooseSubMetaC = 'chooseSubMetaC',
  chooseSubMetaD = 'chooseSubMetaD',
  chooseSubMetaE = 'chooseSubMetaE',
  summaryA = 'summaryA',
  summaryB = 'summaryB',
  summaryC = 'summaryC',
  summaryD = 'summaryD',
  summaryE = 'summaryE',
}

export const enum PromoBannerContentAlign {
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  topLeft = 'topLeft',
  topRight = 'topRight',
}

export const enum PromoBannerType {
  billboard = 'billboard',
  double = 'double',
  featurette = 'featurette',
  leaderboard = 'leaderboard',
  marquee = 'marquee',
  merchandising = 'merchandising',
}

export enum EyebrowAppearance {
  DEFAULT = 'default',
  TAG = 'tag',
  COUNTDOWN_FULL = 'fullCountdown',
  COUNTDOWN_ABBREVIATED = 'abbreviatedCountdown',
  COUNTDOWN_MINIMAL = 'minimalCountdown',
}

export type TPromoBannerCtaLink = { appearance: TOptional<string> } & Partial<TLink>;

export type TPromoBannerEyebrow = {
  appearance: TOptional<string>;
  countdown: TOptional<string>;
  emoji: TOptional<string>;
  tag: TOptional<string>;
  text: TOptional<string>;
  theme: TOptional<string>;
};

export type TPromoBannerPerks = {
  priority: TOptional<string>;
  subheading: TOptional<string>;
  title: TOptional<string>;
};

export type TPromoBannerHalf = {
  contentAlign: TOptional<string>;
  cta: TOptional<Partial<TLink>>;
  imageSet: TOptional<TMediaSet>;
  title: TOptional<string>;
};

export type TPromoBannerDoubleClickDetails = { half: 'one' | 'two' };

export type TPromoBannerOnClick<CD = unknown> = (link: Partial<TLink>, clickDetails?: CD) => void;

export type TWithOnClick<P, CD = unknown> = { onClick?: TPromoBannerOnClick<CD> } & P;

// Banner props types:

export type TPromoBannerAny =
  | TPromoBannerBillboardProps
  | TPromoBannerDoubleProps
  | TPromoBannerFeaturetteProps
  | TPromoBannerLeaderboardProps
  | TPromoBannerMarqueeProps
  | TPromoBannerMerchandisingProps;

// TODO: It seems that not all fields are really needed for banners. It is necessary to remove those that are not in use

export type TPromoBannerCommon = {
  __fetchedAt?: TOptional<number>;
  placeId: PromoBannerPlaceId;
  type: PromoBannerType;
};

export type TPromoBannerMain = {
  description: TNullable<string>;
  title: TNullable<string>;
};

export type TPromoBannerBillboardProps = {
  appearance: TNullable<string>;
  contentAlign: TNullable<string>;
  cta: TOptional<TPromoBannerCtaLink>;
  eyebrow: TOptional<TPromoBannerEyebrow>;
  imageSet: TOptional<TMediaSet>;
  overlayColor: TNullable<string>;
  theme: TNullable<string>;
} & TPromoBannerCommon &
  TPromoBannerMain;

export enum PromoBannerDoubleAppearance {
  plain = 'plain',
  portHole = 'portholeFrame',
  vStyle = 'vFrame',
}

export enum PromoBannerDoubleOverlayColor {
  OceanBlue = 'oceanBlue',
  Rockstar = 'rockstar',
  RockstarDark = 'rockstarDark',
  Squid = 'squidInk',
  Uplift = 'uplift',
  VirginRed = 'virginRed',
  VirginRedDark = 'virginRedDark',
}

export type TPromoBannerDoubleProps = {
  appearance: TNullable<PromoBannerDoubleAppearance>;
  one: TPromoBannerHalf;
  overlayColor: TNullable<PromoBannerDoubleOverlayColor>;
  two: TPromoBannerHalf;
} & TPromoBannerCommon;

export type TPromoBannerFeaturetteProps = {
  appearance: TNullable<string>;
  contentAlign: TNullable<string>;
  cta: TOptional<TPromoBannerCtaLink>;
  eyebrow: TOptional<TPromoBannerEyebrow>;
  imageSet: TOptional<TMediaSet>;
  overlayColor: TNullable<string>;
  theme: TNullable<string>;
  videoSet: TOptional<TMediaSet>;
} & TPromoBannerCommon &
  TPromoBannerMain;

export type TPromoBannerLeaderboardProps = {
  appearance: TNullable<string>;
  contentAlign: TOptional<string>;
  cta: TOptional<TPromoBannerCtaLink>;
  imageSet: TOptional<TMediaSet>;
  overlayColor: TNullable<string>;
  theme: TNullable<string>;
} & TPromoBannerCommon &
  TPromoBannerMain;

export type TPromoBannerMarqueeProps = {
  eyebrow: TOptional<TPromoBannerEyebrow>;
  url: TNullable<string>;
} & TPromoBannerCommon &
  TPromoBannerMain;

export type TPromotionAddonProps = {
  max: TNullable<undefined>;
  min: TNullable<undefined>;
  name: TNullable<string>;
  voyageLengthRestriction: TNullable<string>;
};
export type TPreCheckoutLineItemProps = {
  bulletItemIcon: TNullable<TImage>;
  bulletItemText: TNullable<string>;
  id: TNullable<string>;
  name: TNullable<string>;
  path: TNullable<string>;
};

export type TPromoBannerMerchandisingProps = TPromoBannerCommon & {
  barTabHeroImageToggle: TNullable<boolean>;
  bookingHeaderBackgroundToggle: TNullable<boolean>;
  colorSwatch1: TNullable<string>;
  colorSwatch2: TNullable<string>;
  colorSwatch3: TNullable<string>;
  colorSwatch4: TNullable<string>;
  colorSwatch5: TNullable<string>;
  colorSwatch6: TNullable<string>;
  countdown: TOptional<TPromoBannerEyebrow['countdown']>;
  countdownClockToggle: TNullable<boolean>;
  exitInterstitial: TNullable<boolean>;
  perks: TOptional<TPromoBannerPerks>;
  preCheckoutLineItems: Array<TPreCheckoutLineItemProps>;
  preCheckoutModuleToggle: TNullable<boolean>;
  promotionAddons: Array<TPromotionAddonProps>;
  quickSearch: TNullable<boolean>;
  rotundaToggle: TNullable<boolean>;
  sailingItemToggle: TNullable<boolean>;
  sailingsToBeExcluded: TNullable<string>;
  voyageCardBarTab: TNullable<boolean>;
  voyageCardMerchandising: TNullable<boolean>;
  voyageEnd: TNullable<string>;
  voyageStart: TNullable<string>;
};
