import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import type { RootState } from '@/store';

import { selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';
import { selectIsMnvv } from '@/ducks/mnvv/selectors';
import uniq from '@/helpers/uniq';
import { ensureArray } from '@/helpers/util/misc';

import { config } from './config';
import { selectFilters, selectFiltersDependencies } from './selectors';
import { FILTER_KEY, type FiltersKey, type FiltersTypes, type GetFilterValuesProps } from './types';

const toArray = (value: unknown) => {
  let arr: unknown[] = [];
  if (Array.isArray(value)) {
    arr = value;
  } else {
    if (typeof value === 'string' && value.indexOf(',') !== -1) {
      arr = value.split(/\s*,\s*/);
    } else {
      arr = [value];
    }
  }

  return arr;
};

export const toInt = (value: unknown) => {
  const int = parseInt(value as string, 10);
  return isNaN(int) ? null : int;
};

export const toIntArray = (value: unknown) => {
  return toArray(value).reduce<number[]>((arr, val) => {
    const int = parseInt(val as string, 10);
    return isNaN(int) ? arr : [...arr, int];
  }, []);
};

export const toStringArray = (value: unknown) => {
  return toArray(value).reduce<string[]>((arr, val) => {
    const str = val?.toString();
    return isEmpty(str) ? arr : [...arr, str!];
  }, []);
};

export const toBoolean = (value: null | unknown) => {
  return value !== null && value !== 'false' && value !== '0';
};

export const getSerializedValue = <T>({
  key,
  searchParams,
  state,
  value,
}: {
  key: FiltersKey;
  searchParams: URLSearchParams;
  state: RootState;
  value: T;
}) => {
  const serializeValue = config[key]?.serializeValue;
  return typeof serializeValue === 'function'
    ? serializeValue!(value, { searchParams, state })
    : (value?.toString() ?? null);
};

const getDependencies = ({ searchParams, state }: GetFilterValuesProps) => {
  return JSON.stringify({
    isFm: selectIsAgencyDataAvailable(state),
    isMnvv: selectIsMnvv(state),
    searchParams: searchParams.toString(),
  });
};

export const getUpdatedKeys = (filters: FiltersTypes, updatedFilters: FiltersTypes) => {
  if (filters === updatedFilters) {
    return [];
  }

  return (Object.keys(filters) as FiltersKey[]).reduce<FiltersKey[]>(
    (updatedKeys, key) => (isEqual(filters[key], updatedFilters[key]) ? updatedKeys : [...updatedKeys, key]),
    [],
  );
};

export const addExplicitValuesToURL = ({ state }: { state: RootState }) => {
  let updated = false;
  const filters = selectFilters(state);
  const newSearchParams = new URLSearchParams(window.location.search);
  (Object.keys(config) as FiltersKey[]).forEach((key) => {
    if (config[key].explicit) {
      const value = getSearchParamsValue(key, newSearchParams);
      const newValue = getSerializedValue({ key, searchParams: newSearchParams, state, value: filters?.[key] });
      const searchParamsKeys = getSearchParamsKeys(key);
      if (!value || newValue !== value) {
        updated = true;

        searchParamsKeys.forEach((searchParamsKey) => {
          newSearchParams.delete(searchParamsKey);
        });

        if (newValue) {
          newSearchParams.append(searchParamsKeys[0]!, newValue as string);
        }
      }
    }
  });

  if (updated) {
    const q = newSearchParams.toString();
    history.replaceState(null, '', `${window.location.pathname}${q ? `?${q}` : ''}`);
  }
};

export const getFilterValues = (props: GetFilterValuesProps, isForced?: boolean) => {
  const { searchParams, state } = props;

  const dependencies = getDependencies(props);
  if (!isForced && dependencies === selectFiltersDependencies(state)) {
    return state.filters;
  }

  const values = Object.entries(config).reduce((filterValues, [key, { getValue }]) => {
    let value: FiltersTypes[keyof FiltersTypes] | null = getSearchParamsValue(key as FiltersKey, searchParams);
    if (getValue && typeof getValue === 'function') {
      value = getValue({ ...props, value });
    }
    return {
      ...filterValues,
      [key]: value,
    };
  }, {} as FiltersTypes);

  return {
    dependencies,
    values,
  };
};

const mapFilterKeyToSearchParam: { [key in FiltersKey]?: string | string[] } = {
  [FILTER_KEY.accessible]: ['isCabinAccessible'],
  [FILTER_KEY.dateFrom]: ['dateFrom', 'fromDate'],
  [FILTER_KEY.dateTo]: ['dateTo', 'toDate'],
  [FILTER_KEY.destPackages]: ['selectedPackages', 'selectedPackage'],
  [FILTER_KEY.destPackagesRegions]: [
    'selectedRegions',
    'selectedRegion',
    'selectedRegionsIds',
    'selectedPackagesRegions',
  ],
  [FILTER_KEY.destPorts]: ['selectedPorts', 'selectedPort'],
  [FILTER_KEY.destPortsRegions]: ['selectedPortsRegion', 'selectedPortsRegions'],
  [FILTER_KEY.priceMax]: ['priceMax', 'maxPrice'],
  [FILTER_KEY.priceMin]: ['priceMin', 'minPrice'],
  [FILTER_KEY.ships]: ['ships', 'shipCode', 'selectedShipList'],
  [FILTER_KEY.weekend]: ['weekend', 'isWeekend'],
};

export const getSearchParamsKeys = (key: FiltersKey) => {
  return ensureArray(mapFilterKeyToSearchParam[key as FiltersKey] || [key]) as FiltersKey[];
};

export const getInvalidFiltersSearchParamsKeys = (props: GetFilterValuesProps) => {
  return (Object.keys(config) as FiltersKey[]).reduce<string[]>((keys, key) => {
    const isValueValidForURL = config[key].isValueValidForURL;
    const value: FiltersTypes[keyof FiltersTypes] | null = getSearchParamsValue(key as FiltersKey, props.searchParams);
    const isValid =
      !isValueValidForURL || (typeof isValueValidForURL === 'function' && isValueValidForURL({ ...props, value }));
    return isValid ? keys : [...keys, ...getSearchParamsKeys(key)];
  }, []);
};

export const getSearchParamsValue = (key: FiltersKey, searchParams: URLSearchParams): null | string | string[] => {
  const foundKey = getSearchParamsKeys(key).find((key) => searchParams.has(key));
  if (foundKey) {
    const values = uniq(searchParams.getAll(foundKey));
    return values.length > 1 ? values : values[0]!;
  }

  return null;
};

export const isFiltersApplied = (keys: FiltersKey | FiltersKey[], searchParams: URLSearchParams): boolean => {
  return (ensureArray(keys) as FiltersKey[] | undefined)!.some(
    (key) => getSearchParamsValue(key, searchParams) !== null,
  );
};

export const checkIfFilterOverridden = (key: FiltersKey, searchParams: URLSearchParams) => {
  return Boolean(some(getSearchParamsKeys(key), (key) => searchParams.has(key)));
};
