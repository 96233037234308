import { createSelector } from '@reduxjs/toolkit';

import { combineUIResource, type TUIResourceId } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { selectDefaultPriceRange } from '../defaultValuesSelectors';
import { selectCurrencyCode, selectMaxPrice, selectMinPrice, selectPriceType } from '../selectors';

export const getPricesForLabel = (
  defaultPriceRange: ReturnType<typeof selectDefaultPriceRange>,
  maxPrice: ReturnType<typeof selectMaxPrice>,
  minPrice: ReturnType<typeof selectMinPrice>,
) => {
  const updatedMaxPrice = maxPrice !== null ? maxPrice : defaultPriceRange.maxPrice;
  const updatedMinPrice = minPrice !== null ? minPrice : defaultPriceRange.minPrice;
  return {
    priceMax: Math.floor(updatedMaxPrice || 0),
    priceMin: Math.floor(updatedMinPrice || 0),
  };
};

export const selectPricesPreparedForLabel = createSelector(
  selectDefaultPriceRange,
  selectMaxPrice,
  selectMinPrice,
  getPricesForLabel,
);

export const selectPriceLabel = createSelector(
  selectPricesPreparedForLabel,
  selectPriceType,
  selectCurrencyCode,
  selectResources,
  ({ priceMax, priceMin }, priceType, currencyCode, resources) => {
    let id: TUIResourceId = 'AdvancedFilter.PriceRange.label.perSailor';
    if (priceType === FiltersPriceType.perCabin) {
      id = 'AdvancedFilter.PriceRange.label.perCabin';
    } else if (priceType === FiltersPriceType.sailorPerNight) {
      id = 'AdvancedFilter.PriceRange.label.SailorPerNight';
    }

    return combineUIResource(resources, id, {
      m: `${getSymbolFromCurrenciesData(currencyCode)}${priceMax}`,
      n: `${getSymbolFromCurrenciesData(currencyCode)}${priceMin}`,
    });
  },
);
