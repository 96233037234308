import PropTypes from 'prop-types';
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import BaseFilter from '@/components/AdvancedFilterRefinement/BaseFilter';
import { selectLookup } from '@/ducks/common/selectors';
import { selectHomePortsLabel } from '@/ducks/filters/labels/homePorts';
import { selectHomePorts } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectDisabledHomePortsCodes, selectMainSailings } from '@/ducks/pages/chooseVoyage/selectors';

import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';

class DeparturePortFilter extends BaseFilter {
  checkIfDisabled = (port) => {
    return this.props.disabledHomePorts.includes(port.code);
  };

  onSelection = (port) => {
    const { selectedDeparturePorts } = this.props;
    const selected = selectedDeparturePorts.find((currPort) => currPort.code === port.code);

    if (!selected) {
      setFilter.homePorts([...selectedDeparturePorts, port]);
      return;
    }
    const homePorts = selectedDeparturePorts.filter((selecPort) => selecPort.code !== port.code);
    setFilter.homePorts(homePorts);
  };

  render() {
    const { closeFilterModal, isOpenFilterSection, label, metaDeparturePorts, selectedDeparturePorts } = this.props;

    return (
      <AdvancedFilterHOC
        className="DeparturePortFilter__main"
        closeFilterModal={closeFilterModal}
        defaultValue={label}
        filterName="AdvancedFilter.DeparturePort"
        isDeparturePort
        isOpen={isOpenFilterSection}
        toggleFilterSectionState={this.toggleFilterSectionState}
      >
        <div className="port-list action">
          {metaDeparturePorts &&
            metaDeparturePorts.map((port) => (
              <FormCheck
                aria-label={`${port.name} ${port.countryName}`}
                checked={!isEmpty(selectedDeparturePorts?.find((dp) => dp?.code === port?.code))}
                disabled={this.checkIfDisabled(port)}
                id={`port_${port.code}`}
                inputTabIndex={0}
                key={port.code}
                labelProps={{ 'aria-hidden': true }}
                name={`port_${port.name}`}
                onChange={() => {
                  this.onSelection(port);
                }}
                role="presentation"
                tabIndex={-1}
                type="checkbox"
              >
                <p className="name">{port.name}</p>
                <p className="location">{port.countryName}</p>
              </FormCheck>
            ))}
        </div>
      </AdvancedFilterHOC>
    );
  }
}
DeparturePortFilter.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  disabledHomePorts: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpenFilterSection: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mainPackageSailings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  metaDeparturePorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedDeparturePorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DeparturePortFilter.defaultProps = {
  isOpenFilterSection: false,
};

const mapStateToProps = (state) => ({
  disabledHomePorts: selectDisabledHomePortsCodes(state),
  label: selectHomePortsLabel(state),
  mainPackageSailings: selectMainSailings(state),
  metaDeparturePorts: selectLookup(state)?.homePorts ?? [],
  selectedDeparturePorts: selectHomePorts(state),
});

export default connect(mapStateToProps)(DeparturePortFilter);
