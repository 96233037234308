import { isServerSide } from '@/helpers/isServerSide';

const getUserAgent = (): string => {
  if (isServerSide()) {
    const { headers } = require('next/headers');
    return headers().get('user-agent')!;
  }
  return window.navigator.userAgent;
};

export default getUserAgent;
