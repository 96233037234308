import React, { useMemo } from 'react';

import cn from 'classnames';

import type { TUIResourceId } from '@/ducks/common/resources';

import Chevron from '@/components/Icon/Chevron';
import CircleClose from '@/components/Icon/CircleClose';
import UIResource from '@/components/UIResource';

import './index.scss';

export enum FilterPillState {
  DEFAULT = 'default',
  APPLIED = 'applied',
  OPEN = 'open',
}

const ControlIcon = ({ state }: { state: FilterPillState }) => {
  switch (state) {
    case FilterPillState.OPEN:
      return <Chevron position="up" />;
    case FilterPillState.DEFAULT:
    default:
      return <Chevron />;
  }
};

export type FilterPillProps = {
  buttonProps?: Record<string, unknown>;
  buttonRef?: React.RefObject<HTMLElement | null>;
  disabled?: boolean;
  isApplied?: boolean;
  isOpen: boolean;
  label: string;
  onReset?: () => void;
  titleId: TUIResourceId;
};

const RefinementPill = (props: FilterPillProps) => {
  const { buttonProps, buttonRef, disabled, isApplied, isOpen, label, onReset, titleId } = props;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onReset?.();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
      case 'NumpadEnter':
      case 'Space':
        event.stopPropagation();
        event.preventDefault();
        onReset?.();
        break;
      default:
        return;
    }
  };

  const state = useMemo(() => {
    if (isOpen) {
      return FilterPillState.OPEN;
    }

    if (isApplied) {
      return FilterPillState.APPLIED;
    }

    return FilterPillState.DEFAULT;
  }, [isOpen, isApplied]);

  return (
    <div
      className={cn('filter-button-container', buttonProps?.className as string, {
        '-disabled': disabled,
        '-open': isOpen,
      })}
    >
      <button
        ref={buttonRef as React.RefObject<HTMLButtonElement>}
        {...(disabled ? {} : buttonProps)}
        className="filter-button"
        role="button"
        tabIndex={0}
      >
        <span className="filter-restore-anchor"></span>
        <span className="filter-button-title">
          <UIResource id={titleId} />
        </span>
        <span className="filter-button-label" suppressHydrationWarning>
          {label}
        </span>
      </button>

      {state === FilterPillState.APPLIED ? (
        <span
          aria-label="Reset travel dates"
          className={`filter-button-icon -${state}`}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <CircleClose />
        </span>
      ) : (
        <span className={`filter-button-icon -${state}`}>
          <ControlIcon state={state} />
        </span>
      )}
    </div>
  );
};

export default RefinementPill;
