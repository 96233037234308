'use client';

import Script from 'next/script';
import React, { useEffect, useState } from 'react';

import ReCaptchaContext from '@/contexts/ReCaptchaContext';
import { env } from '@/environment';

const ReCaptchaWrapper = ({ children }: { children: React.ReactNode }) => {
  const [reCaptchaContext, setReCaptchaContext] = useState({
    isLoaded: false,
    siteKey: env.RECAPTCHA_SITE_KEY,
  });

  useEffect(() => {
    // @ts-expect-error ReCaptcha window global property
    window.onloadReCaptchaCallback = () => {
      setReCaptchaContext({
        ...reCaptchaContext,
        isLoaded: true,
      });
    };
  }, [reCaptchaContext]);

  return (
    <ReCaptchaContext.Provider value={reCaptchaContext}>
      <Script async defer src={env.RECAPTCHA_SCRIPT_URL} />
      {children}
    </ReCaptchaContext.Provider>
  );
};

export default ReCaptchaWrapper;
