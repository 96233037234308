import React, { type RefObject } from 'react';

import cn from 'classnames';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import Close from '@/components/Icon/Close';

import Header, { type RefinementPopoverHeaderProps } from './Header';

import styles from './MobileHeader.module.scss';

type RefinementPopoverMobileHeaderProps = {
  backButtonClassName?: string;
  backButtonRef?: RefObject<HTMLButtonElement>;
  closeButtonClassName?: string;
  closeButtonId?: string;
  onClickBack?: (e: React.MouseEvent) => void;
  onClickClose?: (e: React.MouseEvent) => void;
  subHeader?: React.ReactNode;
} & RefinementPopoverHeaderProps;

const RefinementPopoverMobileHeader = ({
  backButtonClassName,
  backButtonRef,
  children,
  className,
  closeButtonClassName,
  closeButtonId,
  icon,
  onClickBack,
  onClickClose,
  subHeader,
}: RefinementPopoverMobileHeaderProps) => {
  return (
    <div className={cn(styles.root, className, { [styles._noBackButton]: !onClickBack })}>
      {onClickBack && (
        <button
          aria-label="Go back"
          className={cn(styles.backButton, backButtonClassName)}
          onClick={onClickBack}
          ref={backButtonRef}
        >
          <ArrowLeft />
        </button>
      )}

      <button
        aria-label="Close"
        className={cn(styles.closeButton, closeButtonClassName)}
        id={closeButtonId}
        onClick={onClickClose}
      >
        <Close />
      </button>

      <Header className={styles.header} icon={icon}>
        {children}
      </Header>

      {subHeader && (
        <div className={styles.subHeader}>
          <span>{subHeader}</span>
        </div>
      )}
    </div>
  );
};

export default RefinementPopoverMobileHeader;
