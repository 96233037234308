import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import isArray from 'lodash/isArray';

import Chevron from '@/components/Icon/Chevron';
import { Skeleton } from '@/components/Skeleton';
import { SKELETON_COLOR_SCHEMES } from '@/components/Skeleton/constants';
import Status from '@/ducks/a11y/components/Status';
import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import FormControl from '../FormControl';

// TODO: Refactor this component and extract as separate components: Select, Checkbox and may be other

const TextField = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [focussed, setFocussed] = useState(false);
  const [hasContent, setHasContent] = useState(!!(props.value || props.defaultValue));
  const supportedChildren = ['option'];

  const isByKeyboard = useKeyboardFocus();

  useEffect(() => {
    if (props.value !== undefined && !!props.value !== hasContent) {
      setHasContent(!!props.value);
    }
  }, [props.value, hasContent]);

  const isContainer = useCallback(() => {
    const { children } = props;
    return (
      children &&
      (React.isValidElement(children) ||
        (React.Children.count(children) > 1 && !supportedChildren.includes(children[0]?.type)))
    );
  }, [props.children]);

  const handleBlur = (event) => {
    setExpanded(false);
    setFocussed(false);
    setHasContent(!!event.target.value);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleClick = (event) => {
    const isSelect = !!React.Children.count(props.children);
    setExpanded(isSelect && !expanded);
    setHasContent(!!event.target.value);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const handleFocus = (event) => {
    setFocussed(true);
    setHasContent(!!event.target.value);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const {
    children = null,
    className,
    disabled,
    error,
    getRef,
    id,
    isLabelSROnly,
    isLoading,
    label,
    labelClassname,
    required,
    shouldActiveStateIgnoreChildren,
    subText,
    value,
  } = props;

  const subtextID = `TextFieldSubtext_${id}`;

  // If disabled option chosen, then Select is empty
  const selectedDisabledOption = Boolean(
    isArray(children) && children?.find((option) => option?.props?.value === value)?.props?.disabled === true,
  );

  const containerCheck = isContainer();
  const classes = classNames({
    active: hasContent || (!shouldActiveStateIgnoreChildren && !!children),
    [className]: !!className,
    disabled,
    empty: selectedDisabledOption,
    error: !!error,
    expanded,
    focus: focussed,
    isByKeyboard: focussed && isByKeyboard,
    loading: isLoading,
    'no-label': !label || isLabelSROnly,
    TextField: true,
  });

  const renderInput = () =>
    !containerCheck ? (
      <div className="TextField__input">
        <FormControl
          {...props}
          aria-describedby={subText ? subtextID : undefined}
          aria-invalid={!!error}
          component={children ? 'select' : 'input'}
          getRef={getRef}
          id={id}
          native
          onBlur={handleBlur}
          onClick={handleClick}
          onFocus={handleFocus}
        />
        {children && (
          <div className="TextField__caret">
            <Chevron />
          </div>
        )}
        {isLoading && (
          <div className="TextField__loadingSkeletonWrapper">
            <Skeleton {...SKELETON_COLOR_SCHEMES['gray-216-gray-100']} />
          </div>
        )}
      </div>
    ) : (
      <div className="TextField__container">{children}</div>
    );

  const combinedLabelClassName = classNames(labelClassname, { 'sr-only': isLabelSROnly });

  return (
    <div className={classes}>
      {label ? (
        <label className={combinedLabelClassName} htmlFor={id}>
          {label}
          {required && <span className="TextField__required">*</span>}
        </label>
      ) : (
        <label className="sr-only" htmlFor={id}>
          No Label
        </label>
      )}
      {renderInput()}
      {subText && (
        <span className="TextField__subText" id={subtextID}>
          {subText}

          <Status hideAfter={1000} isShown={error} srOnly>
            {subText}
          </Status>
        </span>
      )}
    </div>
  );
};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  getRef: PropTypes.func,
  id: PropTypes.string,
  isLabelSROnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassname: PropTypes.string,
  required: PropTypes.bool,
  shouldActiveStateIgnoreChildren: PropTypes.bool,
  subText: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextField.propTypes = propTypes;

export default TextField;
