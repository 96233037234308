import { useEffect, useRef, useState } from 'react';
import type { MutableRefObject } from 'react';

import { createPortal } from 'react-dom';

type ClientOnlyPortalProps = {
  children: React.ReactNode;
  id: string;
  withCleanup?: boolean;
};

const ClientOnlyPortal = ({ children, id, withCleanup }: ClientOnlyPortalProps) => {
  const ref = useRef() as MutableRefObject<HTMLElement>;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      ref.current = element;
      if (withCleanup) element.innerHTML = '';
      setMounted(true);
    }
  }, [id, withCleanup]);

  return mounted ? createPortal(children, ref.current) : null;
};

export default ClientOnlyPortal;
