import { type ReactNode, useEffect, useState } from 'react';

import Spinner from '@/components/elements/Spinner';
import useRouteConfig from '@/features/routeConfig/useRouteConfig';

import detectEnterByLink from './detectEnterByLink';
import detectNonAuthMnvv from './detectNonAuthMnvv';
import startRedirect from './startRedirect';
import { type TRedirectDetector } from './types';

// Place here only simple redirect detectors based on routeConfig and url params
const detectors: TRedirectDetector[] = [detectEnterByLink, detectNonAuthMnvv];

type TProps = {
  children: ReactNode;
};

const RedirectGuard = ({ children }: TProps) => {
  const routeConfig = useRouteConfig();
  const [isRedirected, setIsRedirected] = useState<boolean>();

  useEffect(() => {
    const [redirect] = detectors.map((fn) => fn(routeConfig)).filter(Boolean);
    if (redirect) {
      startRedirect(redirect);
      setIsRedirected(true);
    }
  }, []);

  if (isRedirected) return <Spinner shouldFixed />;
  return children;
};

export default RedirectGuard;
