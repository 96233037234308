import Image from 'next/image';

import cn from 'classnames';

import FormCheck from '@/components/elements/FormCheck';
import Price from '@/components/elements/Price';
import UIResource from '@/components/UIResource';
import WithFrenchContent from '@/components/WithFrenchContent';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { getAmountTaxAware } from '@/helpers/data/mappers/Summary';
import { useAppSelector } from '@/store';

import { type DestinationItem as Item } from './types';

import styles from './DestinationItem.module.scss';

type DestinationItemProps = {
  checked: boolean;
  className?: string;
  focused?: boolean;
  id: string;
  item: Item;
  onCheck?: (id: string) => void;
};

const DestinationItem = ({ checked, className, id, item, onCheck }: DestinationItemProps) => {
  const defaultCurrencyCode = useAppSelector(selectDefaultCurrencyCode);
  const labelId = `${id}_label`;

  return (
    <div
      aria-labelledby={labelId}
      className={cn(styles.root, className)}
      id={id}
      onClick={(e: React.UIEvent) => {
        e.preventDefault();
        onCheck?.(item.id);
      }}
    >
      <FormCheck
        aria-label="select an itinerary to filter the results"
        checked={checked}
        id={`${id}_checkbox`}
        inputTabIndex={-1}
        tabIndex={-1}
        type="checkbox"
        value={item.id}
      >
        <div className={styles.label} id={labelId}>
          <div className={styles.labelTitle}>
            <WithFrenchContent text={item.label} />
          </div>

          {item.duration && item.startingPrice?.amount && (
            <div className={styles.labelDescription}>
              <UIResource
                id="RegionRefinements.itineraryDescription"
                values={{
                  numNights: item.duration,
                  price: (
                    <Price
                      amount={getAmountTaxAware(item.startingPrice)}
                      currencyCode={item.startingPrice.currencyCode || defaultCurrencyCode}
                    />
                  ),
                }}
              />
            </div>
          )}

          {/* key prop forces recreation of node for safari Voiceover to properly read contents */}
          <div className="sr-only" key={`${checked}`}>
            {', '}
            {checked ? `Checked` : `Not checked`}
          </div>
        </div>
        <div className={styles.image}>
          {item.image && <Image alt="" fill sizes="100vw, 100vh" src={item.image.src} />}
        </div>
      </FormCheck>
    </div>
  );
};

export default DestinationItem;
