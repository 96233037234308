import kebabCase from 'lodash/kebabCase';

import { getSailingsToDisplay } from '@/components/ChooseVoyage/PackageCardDetails/PackageCardTextContent/helpers';
import { selectAccessible, selectFilters } from '@/ducks/filters/selectors';
import { getCabinTypeName, getDurationDays } from '@/ducks/filtersOptions';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import tagmanager from '@/tagmanager';
import { trackViewOnScroll } from '@/tagmanager/event/types/common';

import { NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS } from './actionTypes';
import { getSailingsFromPackages } from './getters';
import { getFilteredPackages, selectTrackedSailings } from './selectors';

const trackPriceType =
  ({ priceType, ...restProps }) =>
  (dispatch, getState) => {
    const state = getState();
    const transformedPriceType = transformPriceType(priceType);
    tagmanager.tracker.voyagesFilter.trackApplyFilter({
      name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.PRICE_PER,
      results: getFilteredSailingsCount(state),
      values: [transformedPriceType],
      ...restProps,
    });
  };

const trackDurations = (durations, isWeekend) => (state) => {
  const durationDays = getDurationDays(durations);
  const values = [`${durationDays.join('-')}_weekend-voyages-only`, isWeekend];
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.NUMBER_OF_NIGHTS,
    results: getFilteredSailingsCount(state),
    values,
  });
};

const trackCabinType = (cabinType) => (dispatch, getState) => {
  const state = getState();
  const cabinTypeName = getCabinTypeName(state, cabinType) || kebabCase(cabinType);
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.CABIN_TYPE,
    results: getFilteredSailingsCount(state),
    values: [cabinTypeName],
  });
};

const trackDeparturePorts = (ports) => (state) => {
  const transformedPorts = ports.map(({ name }) => kebabCase(name)).join('-');
  const values = [transformedPorts];
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.DEPARTURE_PORT,
    results: getFilteredSailingsCount(state),
    values,
  });
};

const trackPriceRange =
  ({ priceMax, priceMin }) =>
  (dispatch, getState) => {
    const state = getState();
    const { currencyCode, priceType } = selectFilters(state);
    const currencySymbol = getSymbolFromCurrenciesData(currencyCode);
    const transformedPriceType = transformPriceType(priceType);
    const values = [
      `${currencySymbol}${priceMin}-to-${currencySymbol}${priceMax}`,
      tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.PRICE_PER,
      transformedPriceType,
    ];
    tagmanager.tracker.voyagesFilter.trackApplyFilter({
      module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
      name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.PRICE_RANGE,
      results: getFilteredSailingsCount(state),
      values,
    });
  };

const trackShips = (ships) => (state) => {
  const transformedShips = ships.map(({ name }) => kebabCase(name)).join('-');
  const values = [transformedShips];
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.SHIP,
    results: getFilteredSailingsCount(state),
    values,
  });
};

const trackSailors = (sailors) => (state) => {
  const isAccessible = selectAccessible(state);
  const values = [`${sailors}_accessible`, isAccessible];
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.TRAVEL_PARTY,
    results: getFilteredSailingsCount(state),
    values,
  });
};

const trackSortChange = (sortType) => (state) => {
  const values = [sortType];
  tagmanager.tracker.voyagesFilter.trackApplyFilter({
    name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.FILTER_NAMES.SORT_BY,
    results: getFilteredSailingsCount(state),
    values,
  });
};

const trackDefaultFilter = (name) => {
  tagmanager.tracker.voyagesFilter.trackRemoveFilter({ name });
};

const getFilteredSailingsCount = (state) => {
  const packages = getFilteredPackages(state);
  const sailings = getSailingsFromPackages(packages);
  return sailings?.length;
};

const transformPriceType = (priceType) => priceType?.replace('per', '');

export const trackVoyageView =
  ({ labels = undefined, list, packagesData = [], viewedIdsSelector = selectTrackedSailings }) =>
  () =>
  (dispatch, getState) => {
    const [packageData] = packagesData;
    const { sailingsToDisplay } = getSailingsToDisplay(packageData);

    const onViewed = (visibleVoyages) => {
      if (!visibleVoyages?.length) {
        return;
      }

      tagmanager.observer.trackingEventsObserver.notify({
        data: {
          dispatch,
          getState,
          labels,
          list,
          sailings: visibleVoyages,
        },
        name: tagmanager.observer.TRACKING_EVENTS_FOR_OBSERVER.SAILING_PACK_APPEARED,
      });

      dispatch({
        payload: visibleVoyages.map((voyage) => voyage.id),
        type: NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS,
      });
    };

    dispatch(
      trackViewOnScroll({
        onViewed,
        viewedIdsSelector,
      })(sailingsToDisplay),
    );
  };

export default {
  trackCabinType,
  trackDefaultFilter,
  trackDeparturePorts,
  trackDurations,
  trackPriceRange,
  trackPriceType,
  trackSailors,
  trackShips,
  trackSortChange,
};
