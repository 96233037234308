import AdvancedSelectionSkeleton from '@/components/ChooseVoyage/AdvancedSelection/Skeleton';
import UIResource from '@/components/UIResource';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { useAppSelector } from '@/store';

import SortPriceType from '../SortPriceType';
import SortRefinementForPackages from '../SortRefinementForPackages';

import './AdvancedSelection.scss';

const SEARCH_STATUS_TEXT_CONTAINER_ID = 'searchStatusTextContainer';

const AdvancedSelection = () => {
  const sailingsCount = useAppSelector(selectFilteredSailings)?.length || 0;
  const isLoading = useAppSelector(selectIsFilteringLoading);

  return isLoading ? (
    <AdvancedSelectionSkeleton />
  ) : (
    <section className="AdvancedSelection">
      <div
        aria-labelledby={SEARCH_STATUS_TEXT_CONTAINER_ID}
        aria-live="polite"
        className="AdvancedSelection__text"
        role="status"
        tabIndex={0}
      >
        <div id={SEARCH_STATUS_TEXT_CONTAINER_ID}>
          <UIResource id="ChooseVoyage.Results.showing" />{' '}
          <b suppressHydrationWarning>
            <UIResource id="ChooseVoyage.Results.number" values={{ N: sailingsCount }} />
          </b>
          <UIResource id="ChooseVoyage.Results.match" />
        </div>
      </div>
      <div className="AdvancedSelection__action">
        <div className="sortDropdown">
          <SortPriceType />
        </div>
        <div className="sortDropdown">
          <SortRefinementForPackages />
        </div>
      </div>
    </section>
  );
};

export default AdvancedSelection;
