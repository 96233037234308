import { gql } from '@apollo/client';

import type { GraphqlQuickSearchItemsQualifier } from '@/features/promo/QuickSearchCards/model/types';
import type { GuestCounts } from '@/infra/types/api/guest';
import type { TCurrencyCode } from '@/infra/types/common';

export const QUICK_SEARCH_ITEMS = gql`
  query getQuickSearchItems($value: QuickSearchItemsRequest) {
    quickSearchItems(value: $value) {
      name
      minPrice
      discount
      tax
      currencyCode
      regions
    }
  }
`;

export type QuickSearchItemsResponse = {
  quickSearchItems: {
    currencyCode: TCurrencyCode;
    discount: number;
    minPrice: number;
    name: string;
    regions: string[];
    tax: number;
  }[];
};

export type QuickSearchItemsRequest = {
  currencyCode: TCurrencyCode;
  guestCounts: GuestCounts;
  searchQualifiers: GraphqlQuickSearchItemsQualifier[];
};
