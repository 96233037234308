import type { Package } from '@/infra/types/voyageInfo/package';

export const REGION_FULLNAME_MAP: { [key: string]: string } = {
  // temp fix, until backend cannot be changed - https://virginvoyages.atlassian.net/browse/MSH-97067
  CARIB: 'CARIBBEAN',
  SOUTH: 'SOUTH PACIFIC',
  TRANSA: 'TRANSATLANTIC',
};

export const correlateFullAndShortRegion = (selectedPackage: Package) => {
  const fullNameFound = Object.keys(REGION_FULLNAME_MAP).find((shortName) =>
    selectedPackage?.region?.includes(shortName),
  );

  return fullNameFound ? REGION_FULLNAME_MAP[fullNameFound] : selectedPackage?.region;
};
