import { useMemo } from 'react';

import { selectPromoColors } from '@/features/promoBanners/selectors';
import { useAppSelector } from '@/store';

const buildCssRules = (colors: Record<string, string>): string[] =>
  Object.entries(colors).map(([key, value]) => `--promo-color-${key}: ${value};`);

type TProps = {
  colors?: Record<string, string>;
};

export const PromoColorsStyle = ({ colors }: TProps) => {
  const cssText = useMemo(() => (colors ? `:root {${buildCssRules(colors).join('')}}` : undefined), [colors]);
  if (cssText) return <style>{cssText}</style>;
};

const ApplyPromoColors = () => {
  const colors = useAppSelector(selectPromoColors);
  return <PromoColorsStyle colors={colors} />;
};

export default ApplyPromoColors;
