import { useMemo } from 'react';

import addToSearchParams from '@/helpers/url/addToSearchParams';
import { type TUrlParams } from '@/infra/types/common';
import { type RootState, setupStore } from '@/store';
import buildInitialState from '@/store/buildInitialState';
import { type TFetchableResult } from '@/store/fetchableParts/types';
import chooseRelevant from '@/store/prefill/chooseRelevant';

const useSetupStore = (fetched?: TFetchableResult, urlParams?: TUrlParams) => {
  return useMemo(
    () => {
      let initialState;
      if (fetched) {
        const { relevant, versions } = chooseRelevant(fetched) ?? {};
        const searchParams = urlParams ? addToSearchParams(new URLSearchParams(), urlParams) : new URLSearchParams();
        initialState = { ...buildInitialState(relevant!, searchParams), prefillInfo: versions! } as RootState;
      }
      return setupStore(initialState, { reconfigure: !!fetched });
    },
    // Don't add deps as it will trigger making store from scratch
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useSetupStore;
