import type { FilteredPackage } from '@/infra/types/voyageInfo/package';

import Button from '@/components/elements/Button';
import UIResource from '@/components/UIResource';
import { resetAllFilters } from '@/ducks/filters/setters';
import { selectDefaultPackages } from '@/ducks/pages/chooseVoyage/selectors';
import { useAppSelector } from '@/store';

type Props = {
  renderPackage: (e: FilteredPackage) => React.ReactNode;
};

const DefaultZeroResults = ({ renderPackage }: Props) => {
  const defaultPackages = useAppSelector(selectDefaultPackages) as FilteredPackage[];

  return (
    <>
      <p className="ZeroResultsDescription">
        <UIResource id="ChooseVoyage.ZeroResults.message" />
      </p>
      <p className="ZeroResultsReset">
        <Button onClick={resetAllFilters} variant="link">
          <UIResource id="ResultRefinements.resetAll" />
        </Button>
      </p>
      {!!defaultPackages?.length && (
        <>
          <h3 className="ZeroResultsDefaultVoyagesHeading">
            <UIResource id="ChooseVoyage.Discover.heading" />
          </h3>
          <div className="ZeroResultsDefaultVoyages">{defaultPackages?.map(renderPackage)}</div>
        </>
      )}
    </>
  );
};

export default DefaultZeroResults;
