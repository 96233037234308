import {
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
} from '@/constants/actionTypes';

export const clearCabinCategoriesDataAction = () => async (dispatch) => {
  dispatch({
    payload: {},
    type: CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  });
};

export const cabinCategorySetUnavailableCabinAction = (subCategoryCode) => async (dispatch, getState) => {
  dispatch({
    payload: {
      cabinCategories: getState()?.voyagePlanner?.chooseCabin?.cabinCategories?.data,
      subCategoryCode,
    },
    type: CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
  });
};

export default {
  cabinCategorySetUnavailableCabinAction,
  clearCabinCategoriesDataAction,
};
