import type { LabelForTracking, LabelInFlyoutForTracking, LabelOnTogglerForTracking } from '@/tagmanager/types';

import constants from '@/tagmanager/constants';

import { trackLinkClick, trackPopupState } from './common';

export const trackMoreInfoClick = ({ label }: LabelForTracking) => {
  trackLinkClick({ labels: [label] });
};

export const trackModalStateToggle = ({ state }: LabelOnTogglerForTracking) => {
  const moduleLabel = constants.FLYOUTS.MORE_INFO_POPUP;
  trackPopupState({ isOpen: state, module: moduleLabel });
};

export const trackTermsLinkClick = ({ label }: LabelInFlyoutForTracking) => {
  const moduleLabel = constants.FLYOUTS.MORE_INFO_POPUP;
  trackLinkClick({ labels: [label], module: moduleLabel });
};
