import { TabsList, TabsTrigger } from '@/components/Tabs';
import UIResource from '@/components/UIResource';

import { DestinationType } from './types';

type DestinationToggleProps = {
  className?: string;
  destinationType: DestinationType;
};

const DestinationToggle = ({ className, destinationType }: DestinationToggleProps) => {
  return (
    <TabsList className={className} tabIndex={-1}>
      <TabsTrigger
        tabIndex={destinationType === DestinationType.ITINERARIES ? 0 : -1}
        value={DestinationType.ITINERARIES}
      >
        <UIResource id="RegionRefinements.itineraries" />
      </TabsTrigger>
      <TabsTrigger
        tabIndex={destinationType === DestinationType.PORTS_OF_CALL ? 0 : -1}
        value={DestinationType.PORTS_OF_CALL}
      >
        <UIResource id="RegionRefinements.port" />
      </TabsTrigger>
    </TabsList>
  );
};

export default DestinationToggle;
