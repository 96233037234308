import {
  GET_VOYAGE_SEARCH_DATA_FAILURE,
  GET_VOYAGE_SEARCH_DATA_START,
  GET_VOYAGE_SEARCH_DATA_SUCCESS,
} from '@/constants/actionTypes';
import { defaultDataLoadingState } from '@/reducers/defaultDataLoadingState';

export default function voyageSearchReducer(state = defaultDataLoadingState, action) {
  switch (action.type) {
    case GET_VOYAGE_SEARCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VOYAGE_SEARCH_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case GET_VOYAGE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
