import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import without from 'lodash/without';

import type { RefinementPopoverProps } from '@/components/ResultRefinements/RefinementPopover/useGetRefinementPopoverProps';

import uniq from '@/helpers/uniq';

import {
  type AllItemsIds,
  type DestinationCountryItem,
  type DestinationItem,
  type DestinationRegion,
  type DestinationsFilterData,
  DestinationType,
} from './types';

type ItemsIds = Record<string, string[]>;

export const getCheckedItemsIds = (allItemsIds: AllItemsIds, destinationType: DestinationType, regionId?: string) => {
  return (regionId && allItemsIds[destinationType]?.[regionId]) || [];
};

export const isWholeChecked = (
  region: DestinationRegion,
  destinationType: DestinationType,
  allItemsIds: AllItemsIds,
) => {
  return region && region.allItemsIds.length === getCheckedItemsIds(allItemsIds, destinationType, region.id).length;
};

export const getAppliedItemsIds = (data?: DestinationsFilterData) => {
  const reducer = (ids: ItemsIds, region: DestinationRegion) =>
    !isEmpty(region.selectedItemsIds) ? { ...ids, [region.id]: region.selectedItemsIds } : ids;
  return {
    [DestinationType.ITINERARIES]: data?.[DestinationType.ITINERARIES].reduce<ItemsIds>(reducer, {}) || {},
    [DestinationType.PORTS_OF_CALL]: data?.[DestinationType.PORTS_OF_CALL].reduce<ItemsIds>(reducer, {}) || {},
  };
};

export const getItemLabel = (
  itemId: string,
  items: DestinationCountryItem[] | DestinationItem[],
  destinationType: DestinationType,
) => {
  if (destinationType === DestinationType.ITINERARIES) {
    return (items as DestinationItem[]).find((item) => item.id === itemId)!.label;
  } else {
    return (items as DestinationCountryItem[])
      .find((country) => country.allItemsIds.includes(itemId))!
      .items.find((item) => item.id === itemId)!.label;
  }
};

export const getEmptyItemsIds = () => {
  return getAppliedItemsIds();
};

export const setItemsIds = (
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId: string,
  ids: string[],
) => {
  if (ids.length === 0) {
    return {
      ...itemsIds,
      [destinationType]: omit(itemsIds[destinationType], regionId),
    };
  }

  return {
    ...itemsIds,
    [destinationType]: {
      ...itemsIds[destinationType],
      [regionId]: ids,
    },
  };
};

export const addItemsIds = (
  data: DestinationsFilterData,
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  ids: string[],
) => {
  // add items IDs to all regions where they might belong
  // to cover cases where one item may be present in multiple regions (like ports Miami and San Juan)...

  return data[destinationType].reduce((itemsIds, region) => {
    const suitableIds = intersection(ids, region.allItemsIds);
    if (suitableIds.length === 0) {
      return itemsIds;
    }

    return setItemsIds(
      itemsIds,
      destinationType,
      region.id,
      uniq([...(itemsIds[destinationType]?.[region.id] || []), ...suitableIds]),
    );
  }, itemsIds);
};

export const removeItemsIds = (itemsIds: AllItemsIds, destinationType: DestinationType, ids: string[]) => {
  // when removing items IDs, we delete them from all regions at once
  // to cover cases where one item may be present in multiple regions (like ports Miami and San Juan)

  return Object.keys(itemsIds[destinationType] || {}).reduce(
    (itemsIds, regionId) =>
      setItemsIds(itemsIds, destinationType, regionId, without(itemsIds[destinationType]?.[regionId], ...ids)),
    itemsIds,
  );
};

export const isItemIdSelected = (
  id: string,
  itemsIds: AllItemsIds,
  destinationType: DestinationType,
  regionId?: string,
) => Boolean(regionId && itemsIds[destinationType]?.[regionId]?.includes(id));

export type DestinationRefinementProps = {
  isMobile?: boolean;
  popoverProps: RefinementPopoverProps;
};
