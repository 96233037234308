import React from 'react';

import cn from 'classnames';

import { ListBox, type ListBoxProps, ListItem } from '@/components/ListBox';
import { getSafeElementId } from '@/helpers/util';

import DestinationItem from './DestinationItem';
import { type AllItemsIds, type DestinationCountryItem, type DestinationRegion, DestinationType } from './types';
import { isItemIdSelected } from './utils';

import styles from './ItemsListBox.module.scss';

type DestinationItemsListBoxProps = {
  allItemsIds: AllItemsIds;
  'aria-labelledby'?: string;
  className?: string;
  destinationType: DestinationType;
  id?: string;
  onCheck: ListBoxProps<string>['onPressEnterItem'];
  onKeyDown?: ListBoxProps<string>['onKeyDown'];
  scrollIntoViewBlock?: ScrollLogicalPosition;
  selectedRegion: DestinationRegion;
  value?: string;
};

const DestinationItemsListBox = ({
  allItemsIds,
  ['aria-labelledby']: ariaLabelledby,
  className,
  destinationType,
  id,
  onCheck,
  onKeyDown,
  scrollIntoViewBlock,
  selectedRegion,
}: DestinationItemsListBoxProps) => {
  const safeSelectedRegionId = getSafeElementId(selectedRegion.id);

  return (
    <ListBox
      aria-labelledby={ariaLabelledby}
      className={cn(styles.listBox, className)}
      id={id}
      onKeyDown={onKeyDown}
      onPressEnterItem={onCheck}
      scrollIntoViewBlock={scrollIntoViewBlock}
      selectOnFocusChange
    >
      {selectedRegion.items.map((item, index) => {
        const key = `${selectedRegion.id} ${item.id} - ${index}`;

        if ('items' in item) {
          return (
            <React.Fragment key={key}>
              <div aria-hidden="true" className={styles.itemContryName}>
                {item.label}
              </div>
              {(item as DestinationCountryItem).items?.map((item, index) => (
                <ListItem
                  className={cn(styles.item, { [styles.itemFirstPort!]: index === 0 })}
                  key={`${selectedRegion.id} ${item.id} ${item.label}`}
                  value={item.id}
                >
                  <DestinationItem
                    checked={isItemIdSelected(item.id, allItemsIds, destinationType, selectedRegion.id)}
                    id={`item-${safeSelectedRegionId}-${item.id}`}
                    item={item}
                    onCheck={onCheck}
                  />
                </ListItem>
              ))}
            </React.Fragment>
          );
        } else {
          return (
            <ListItem key={key} value={item.id}>
              <DestinationItem
                checked={isItemIdSelected(item.id, allItemsIds, destinationType, selectedRegion.id)}
                id={`item-${safeSelectedRegionId}-${item.id}`}
                item={item}
                onCheck={onCheck}
              />
            </ListItem>
          );
        }
      })}
    </ListBox>
  );
};

export default DestinationItemsListBox;
