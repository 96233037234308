import { pickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { type TRedirectConfig } from '@/features/routeConfig/types';
import redirect from '@/helpers/ssrSafe/redirect';

const startRedirect = (options: TRedirectConfig) => {
  const { getParams, params, pickUrlParams, redirectTo } = options ?? {};
  const searchParams = { ...params, ...(pickUrlParams && pickAnyFromSearchParams(pickUrlParams)), ...getParams?.() };
  redirect(redirectTo, { isReplace: true, params: searchParams });
};

export default startRedirect;
