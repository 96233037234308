import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import type { BookConfirmationResponse } from '@/helpers/api/graphql/queries/BookConfirmation';

type ConfirmationData = Partial<BookConfirmationResponse['bookConfirmationV2']>;

const initialState = {} as ConfirmationData;

const paymentConfirmationSlice = createSlice({
  initialState,
  name: 'paymentConfirmation',
  reducers: {
    resetData: () => initialState,
    setData: (state, { payload }: PayloadAction<ConfirmationData | undefined>) => {
      if (
        payload?.paymentMethod &&
        (payload.paymentMethod.paymentModeCode !== 'card' || !isEmpty(payload.paymentMethod.cardMaskedNo))
      ) {
        state.paymentMethod = payload.paymentMethod;
      }

      state.todoList = payload?.todoList;
    },
  },
});

export const { resetData, setData } = paymentConfirmationSlice.actions;
export default paymentConfirmationSlice.reducer;
