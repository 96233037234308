import { isServerSide } from '@/helpers/isServerSide';

const getCurrentOrigin = () => {
  if (isServerSide()) {
    const { headers } = require('next/headers');
    const hs = headers();
    const host = hs.get('host');
    const protocol = hs.get('x-forwarded-proto');
    return `${protocol}://${host}`;
  }
  return window.location.origin;
};

export default getCurrentOrigin;
