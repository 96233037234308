import { defaultDataLoadingState } from '@/reducers/defaultDataLoadingState';

import { CLEAR_FLYOUT_DATA, GET_FLYOUT_DATA, GET_FLYOUT_DATA_FAILURE, GET_FLYOUT_DATA_SUCCESS } from './actionTypes';

const flyoutReducer = (state = defaultDataLoadingState, action) => {
  switch (action.type) {
    case CLEAR_FLYOUT_DATA:
      return {
        ...state,
        data: {},
        error: {},
      };
    case GET_FLYOUT_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_FLYOUT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_FLYOUT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default flyoutReducer;
