import { v4 as uuid } from 'uuid';

import { type TRedirectConfig, type TRouteConfig } from '@/features/routeConfig/types';
import onlyOnClient from '@/helpers/ssrSafe/onlyOnClient';
import { type TOptional } from '@/types';

import { type TRedirectDetector } from './types';

const SESSION_KEY = ':session-id:';

const isNewSession = onlyOnClient((): boolean => {
  const { sessionStorage } = window;
  const previous = sessionStorage.getItem(SESSION_KEY);
  if (!previous) sessionStorage.setItem(SESSION_KEY, uuid());
  return !previous;
});

const detectEnterByLink: TRedirectDetector = (routeConfig?: TRouteConfig): TOptional<TRedirectConfig> => {
  const isEnterByLink = isNewSession(); // perform always regardless of the conditions
  const { enterByLink } = routeConfig ?? {};
  if (isEnterByLink && enterByLink?.redirectTo) return enterByLink;
};

export default detectEnterByLink;
