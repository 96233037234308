import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { FadeIn, Slide } from '@/components/Animations';
import Spinner from '@/components/elements/Spinner';
import Close from '@/components/Icon/Close';
import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';
import { useModalStateFocus } from '@/ducks/a11y/hooks/useModalStateFocus';
import { clearFlyoutDataAction } from '@/ducks/flyout/actions';
import { getFlyoutData, isFlyoutLoading } from '@/ducks/flyout/selectors';
import { addBodyBackgroundClasses, clearBodyBackgroundClasses, getScrollbarWidth } from '@/helpers/util/misc';
import { useIsMobile } from '@/hooks/useIsDevice';
import { useAppDispatch, useAppSelector } from '@/store';

export const FLYOUT_ANIMATION_DELAY = 300;
let COUNT_OPENED_FLYOUTS = 0;

type FlyoutProps = React.PropsWithChildren<{
  afterFlyoutChildren?: React.ReactNode;
  alignButtonRight?: boolean;
  ariaLabel?: string;
  backdrop?: boolean;
  className?: string;
  closeIconId?: string;
  contentClassName?: string;
  directChildren?: React.ReactNode;
  direction?: 'left' | 'modal' | 'right' | 'bottom-right';
  disableScrollLock?: boolean;
  dismissButtonOutside?: boolean;
  focusOnCloseSelector?: string | HTMLElement;
  focusOnOpenSelector?: string | HTMLElement;
  fullWidth?: boolean;
  hideCrossButton?: boolean;
  invert?: boolean;
  isCloseable?: boolean;
  isDarkBackground?: boolean;
  isStickyCloseButton?: boolean;
  onDismiss: () => void;
  open?: boolean;
  stickyFooter?: boolean;
  tabIndex?: number;
}>;

const Flyout = ({
  afterFlyoutChildren,
  alignButtonRight,
  ariaLabel,
  backdrop = true,
  children,
  className,
  closeIconId = 'close',
  contentClassName,
  directChildren,
  direction = 'right',
  disableScrollLock,
  dismissButtonOutside,
  focusOnCloseSelector,
  focusOnOpenSelector,
  fullWidth,
  hideCrossButton,
  invert,
  isCloseable = true,
  isDarkBackground,
  isStickyCloseButton,
  onDismiss,
  open = false,
  stickyFooter,
  tabIndex = 0,
}: FlyoutProps) => {
  const [scrollCurrentPosition, setScrollCurrentPosition] = useState(0);

  const flyoutData = useAppSelector(getFlyoutData);
  const isLoading = useAppSelector(isFlyoutLoading);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const clearFlyoutData = () => {
    dispatch(clearFlyoutDataAction());
  };

  const releaseScroll = useCallback(() => {
    COUNT_OPENED_FLYOUTS--;
    if (COUNT_OPENED_FLYOUTS <= 0) {
      // let only last flyout to clear the classes
      const rootEl = document.body;
      rootEl.style.position = '';
      rootEl.style.overflow = '';
      clearBodyBackgroundClasses(direction, false);
      COUNT_OPENED_FLYOUTS = 0;
    }
  }, [direction]);

  const handleLockScroll = useCallback(() => {
    const state = open;
    if (state) {
      addBodyBackgroundClasses(direction, isMobile, getScrollbarWidth());
    } else {
      releaseScroll();
    }
  }, [direction, isMobile, releaseScroll, open]);

  const closePopup = () => {
    if (!isCloseable) return;
    const rootEl = document.body;
    if (isMobile) {
      rootEl.style.position = '';
      if (scrollCurrentPosition > 0) {
        window.scrollTo(0, scrollCurrentPosition);
      }
    }
    onDismiss();
    if (Object.keys(flyoutData).length) {
      clearFlyoutData();
    }
  };

  const [rootElement, setRootElement] = useState<HTMLElement | undefined>();
  const refCallback = useCallback(
    (node: HTMLElement | null) => {
      if (node && !rootElement) {
        setRootElement(node);
      }
    },
    [rootElement],
  );

  useFocusTrap({ element: rootElement, isOpened: open, onClose: closePopup, onlyVisible: true });

  // The onOpen or onClose elements may not always exist, but appear only after opening (for example, in children)
  useModalStateFocus({
    elementOnClose: focusOnCloseSelector,
    elementOnOpen: focusOnOpenSelector || !(hideCrossButton && !dismissButtonOutside),
    isOpen: open,
    rootElement,
  });

  useEffect(() => {
    if (!disableScrollLock) {
      COUNT_OPENED_FLYOUTS++;
      handleLockScroll();
    }
    return () => {
      releaseScroll();
    };
  }, [open, disableScrollLock, handleLockScroll, releaseScroll]);

  useEffect(() => {
    setScrollCurrentPosition(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
  }, [open]);

  const rootClasses = classNames(className, {
    Flyout: true,
  });

  const directionClassName = direction === 'bottom-right' ? 'right' : direction;
  const contentClasses = classNames(contentClassName, {
    '-hasCta': stickyFooter !== null,
    [`-${directionClassName}`]: true,
    darkBackground: isDarkBackground,
    Flyout__content: true,
    Flyout_width: fullWidth,
  });

  const backdropClasses = classNames({
    '-dark': backdrop,
    Flyout__backdrop: true,
  });

  const buttonClasses = classNames({
    '-alignButtonRight': alignButtonRight,
    '-dismissOutside': dismissButtonOutside,
    '-invert': invert,
    '-isStickyCloseButton': isStickyCloseButton,
    CloseBtn: true,
    Flyout__dismiss: true,
  });

  return (
    <div
      aria-hidden={!open}
      aria-label={ariaLabel}
      aria-modal="true"
      className={rootClasses}
      ref={refCallback}
      tabIndex={-1}
    >
      <FadeIn className={backdropClasses} in={open} mountOnEnter timeout={FLYOUT_ANIMATION_DELAY} unmountOnExit>
        <div aria-hidden onClick={closePopup} role="presentation">
          {dismissButtonOutside && (
            <button aria-label="Close" className={buttonClasses} id="close-button" type="button">
              <Close />
            </button>
          )}
        </div>
      </FadeIn>
      {directChildren && (
        <FadeIn in={open} mountOnEnter timeout={FLYOUT_ANIMATION_DELAY} unmountOnExit>
          {directChildren}
        </FadeIn>
      )}
      <Slide
        className={contentClasses}
        direction={direction}
        in={open}
        mountOnEnter
        timeout={FLYOUT_ANIMATION_DELAY}
        unmountOnExit
      >
        <div id="FlyoutParent">
          {isLoading && <Spinner />}
          {!dismissButtonOutside && !hideCrossButton && (
            <button
              aria-label="Close"
              className={buttonClasses}
              id="close-button"
              onClick={closePopup}
              tabIndex={tabIndex}
              type="button"
            >
              <Close id={closeIconId} />
            </button>
          )}
          {children}
          {stickyFooter !== null && <div className="Flyout__stickyFooter">{stickyFooter}</div>}
        </div>
      </Slide>
      {open && afterFlyoutChildren}
    </div>
  );
};

export default Flyout;
