import { getSessionStorageValue, getStoredValue } from '@/helpers/util';
import { type TOptional } from '@/types/common';

export type TStorageAuth = {
  accessToken: string;
  email: string;
  expiresIn: number;
  isSocial?: boolean;
  keepLoggedIn?: boolean;
  refreshToken: string;
  status: string;
  tokenType: string;
  userId: string;
  userType: string;
};

export const getAuthFromStorage = () =>
  (getStoredValue('Authorization') || getSessionStorageValue('Authorization')) as TOptional<TStorageAuth>;

export const getAccessTokenFromStorage = () => getAuthFromStorage()?.accessToken;
