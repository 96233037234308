import tagmanager from '@/tagmanager';

import type { BaseFilterProps } from './types';

const useGetBaseFilterProps = (props: BaseFilterProps) => {
  const toggleFilterSectionState = (filterName: string | string[]) => {
    const { isOpenFilterSection, onRenderChange, toggleFilterState } = props;

    const newState = !isOpenFilterSection;
    onRenderChange?.(false);
    toggleFilterState(newState);

    if (newState) {
      tagmanager.tracker.voyagesFilter.trackOpenSubFilter({ label: filterName });
    }
  };

  return { toggleFilterSectionState };
};

export default useGetBaseFilterProps;
