import constants from '../../constants';
import builders from '../builders';
import { trackButtonClick, trackIconClick } from './common';

const { EVENT_LABELS, FLYOUTS } = constants;

export const onAdvertCardClick = (title: string) => {
  const labels = [EVENT_LABELS.SEARCH, title, FLYOUTS.BLACK_FRIDAY];

  trackButtonClick({ labels });
};

export const onPromoBannerClick = (title: string) => {
  const labels = [EVENT_LABELS.BANNER, title, FLYOUTS.BLACK_FRIDAY];

  trackIconClick({ labels });
};

export const onLeaderboardClick = (title: string) => {
  const labels = [EVENT_LABELS.LEADERBOARD_BANNER, title, FLYOUTS.BLACK_FRIDAY];

  trackIconClick({ labels });
};

export const onExitModalClick = (title: string) => {
  const buttonText = builders.buildCtaLabel(title);
  const labels = [buttonText, FLYOUTS.BLACK_FRIDAY];

  trackButtonClick({ labels, module: FLYOUTS.NAVIGATION_HELPER_POPUP });
};
