'use client';

import { useApolloClient } from '@apollo/client';
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support/ssr';

import { makeSSRClient } from '@/helpers/api/graphql/apollo-client-ssr';

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeSSRClient}>{children}</ApolloNextAppProvider>;
}

/**
 * @deprecated
 * TODO: refactor dns call to make it as a custom hook instead of redux action
 */
export const withApolloClient = <T,>(Component: React.FC<T | { apolloClient: ReturnType<typeof useApolloClient> }>) => {
  const Wrapper = (props: T) => {
    const client = useApolloClient();
    return <Component {...props} apolloClient={client} />;
  };
  return Wrapper;
};
