import { CONFIRMATION_TODO_LIST_FAILURE, CONFIRMATION_TODO_LIST_SUCCESS } from '@/constants/actionTypes';

const confirmationTodoListReducer = (state = { data: {}, error: {} }, action) => {
  switch (action.type) {
    case CONFIRMATION_TODO_LIST_FAILURE:
      return { ...state, data: {}, error: {} };
    case CONFIRMATION_TODO_LIST_SUCCESS:
      return { ...state, data: action.payload, error: {} };
    default:
      return state;
  }
};

export default confirmationTodoListReducer;
