import AppAuthenticator from '../AppAuthenticator';
import { TokenType } from '../types';

export { TokenType };

export type TAuthHeaders = { Authorization: `bearer ${string}` };
export type TEndpointWithAuthHeader<A extends unknown[], R> = (authHeaders: TAuthHeaders, ...args: A) => Promise<R>;

export const withAuthHeader =
  <A extends unknown[], R>(tokenType: TokenType, endpoint: TEndpointWithAuthHeader<A, R>) =>
  (...args: A): Promise<R> =>
    AppAuthenticator.getInstance()
      .getFreshAccessToken({ tokenType })
      .then((token) => {
        if (token) return endpoint({ Authorization: `bearer ${token}` }, ...args);
        throw new TypeError('Empty token received');
      });

export type TEndpointWithToken<A extends unknown[], R> = (token: string, ...args: A) => Promise<R>;

const withToken =
  <A extends unknown[], R>(tokenType: TokenType, endpoint: TEndpointWithToken<A, R>) =>
  (...args: A): Promise<R> =>
    AppAuthenticator.getInstance()
      .getFreshAccessToken({ tokenType })
      .then((token) => {
        if (token) return endpoint(token, ...args);
        throw new TypeError('Empty token received');
      });

export default withToken;
