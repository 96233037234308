import type { KeyboardEvent as SyntheticKeyboardEvent } from 'react';

import type { TOptional } from '@/types/common';

import { type TKeyEventGroup } from '../types';
import getFocusableElements from './getFocusableElements';

const getNextIndex = (length: number, index: number, isBackward?: boolean): number => {
  if (index === -1) return isBackward ? length - 1 : 0;
  return (index + (isBackward ? -1 : 1) + length) % length;
};

const getNativeKeyboardSupport = (node: HTMLElement): TOptional<TKeyEventGroup[]> => {
  const { nodeName } = node;
  if (nodeName === 'INPUT') {
    if (['radio', 'text'].includes((node as HTMLInputElement).type)) return ['x-arrows'];
  }
  if (nodeName === 'TEXTAREA') return ['x-arrows'];
};

export type TMoveFocusArgs = {
  container: HTMLElement;
  keyGroups: TKeyEventGroup[];
  nodeSelector?: string;
  onlyVisible?: boolean;
};

const tryHandleMoveFocus = (
  event: KeyboardEvent | SyntheticKeyboardEvent,
  { container, keyGroups, nodeSelector, onlyVisible }: TMoveFocusArgs,
): void => {
  const { key, keyCode, shiftKey, target } = event;
  let isBackward: TOptional<boolean>, keyGroup: TOptional<TKeyEventGroup>;
  if (key === 'Tab' || keyCode === 9) {
    keyGroup = 'tab';
    isBackward = shiftKey;
  } else if (key === 'ArrowRight' || key === 'ArrowLeft') {
    keyGroup = 'x-arrows';
    isBackward = key === 'ArrowLeft';
  } else if (key === 'ArrowDown' || key === 'ArrowUp') {
    keyGroup = 'y-arrows';
    isBackward = key === 'ArrowUp';
  }

  if (keyGroup) {
    const preservedKeyHandlers = getNativeKeyboardSupport(target as HTMLElement);
    if (!keyGroups.includes(keyGroup) || preservedKeyHandlers?.includes(keyGroup)) return;

    const focusables = getFocusableElements(container, nodeSelector, onlyVisible);
    if (focusables.length) {
      const currentIndex = focusables.indexOf(document.activeElement! as HTMLElement);
      const nextIndex = getNextIndex(focusables.length, currentIndex, isBackward);
      event.preventDefault();
      focusables[nextIndex]?.focus();
    }
  }
};

export default tryHandleMoveFocus;
