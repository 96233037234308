import { type KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { TWhatsIncluded } from '@/components/WhatsIncluded/WhatsIncludedTypes';

import { Skeleton } from '@/components/Skeleton';
import { useUIResource } from '@/ducks/common/resources';
import { selectIsWhatsIncludedLoaded } from '@/ducks/pages/voyagePlanner/selectors';
import withSkeleton from '@/hocs/withSkeleton';

import Image from '../Image';

type WhatsIncludedContentProps = {
  onBannerClick: () => void;
  sortedFeatures: TWhatsIncluded[];
};

const WhatsIncludedContent = ({ onBannerClick, sortedFeatures }: WhatsIncludedContentProps) => {
  const rootClass = 'WhatsIncluded';
  const title = useUIResource('WhatsIncluded.title');
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);
  const whatsIncludedList = useRef<HTMLUListElement>(null);

  const onArrowKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
        e.preventDefault();
        setFocusedItemIndex((prevState) => (prevState - 1 + sortedFeatures.length) % sortedFeatures.length);
        return;
      }
      if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
        e.preventDefault();
        setFocusedItemIndex((prevState) => (prevState + 1) % sortedFeatures.length);
        return;
      }
      if ([' ', 'Enter'].includes(e.key)) {
        e.preventDefault();
        onBannerClick();
      }
    },
    [sortedFeatures, onBannerClick],
  );

  useEffect(() => {
    (whatsIncludedList.current?.children[focusedItemIndex] as HTMLLIElement)?.focus();
  }, [focusedItemIndex]);

  const includedItems = useMemo(
    () =>
      sortedFeatures !== null &&
      sortedFeatures.length > 0 &&
      sortedFeatures.map((feature, index) => {
        const ariaLabel = `${index + 1} of ${sortedFeatures.length} items, ${feature.text}`;
        return (
          <li
            aria-label={ariaLabel}
            className={`${rootClass}__list__item`}
            key={feature.text}
            tabIndex={index === focusedItemIndex ? 0 : -1}
          >
            <Image alt={feature.icon?.alt} className={`${rootClass}__list__item__image`} src={feature.icon?.src} />
            <span className={`${rootClass}__list__item__text`}>{feature.text}</span>
          </li>
        );
      }),
    [sortedFeatures, focusedItemIndex],
  );

  return (
    <div className={rootClass} onKeyDown={onArrowKeyDown}>
      <button
        aria-label={`Show more information about what’s Included in your cruise, ${title}`}
        aria-orientation="horizontal"
        className={`${rootClass}__focusableButtonContent`}
        onPointerUp={onBannerClick}
        role="presentation"
        tabIndex={0}
      />
      <div className={`${rootClass}__header`}>{title}</div>
      <ul className={`${rootClass}__list`} ref={whatsIncludedList}>
        {includedItems}
      </ul>
    </div>
  );
};

export default withSkeleton(WhatsIncludedContent, {
  selector: selectIsWhatsIncludedLoaded,
  skeleton: () => <Skeleton height="130px" />,
});
