'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import type { TISODateTime } from '@/infra/types/common';

import UIResource from '@/components/UIResource';

import formatDigits from './lib/formatDigits';
import getRemainTime from './lib/getRemainTime';
import getTimeValue from './lib/getTimeValue';
import makeDigits from './lib/makeDigits';
import { TimeDigitName } from './types';

import './style.scss';

export { TimeDigitName };

export const enum CountdownMode {
  COMPACT = 'compact',
  DEFAULT = 'default',
  SHORT = 'short',
  UNLABELED = 'unlabeled',
}

const CMS_TEMPLATE_IDS = {
  [CountdownMode.COMPACT]: {
    [TimeDigitName.DAYS]: 'Countdown.days:compact',
    [TimeDigitName.HOURS]: 'Countdown.hours:compact',
    [TimeDigitName.MINS]: 'Countdown.minutes:compact',
    [TimeDigitName.SECS]: 'Countdown.seconds:compact',
  },
  [CountdownMode.DEFAULT]: {
    [TimeDigitName.DAYS]: 'Countdown.days',
    [TimeDigitName.HOURS]: 'Countdown.hours',
    [TimeDigitName.MINS]: 'Countdown.minutes',
    [TimeDigitName.SECS]: 'Countdown.seconds',
  },
  [CountdownMode.SHORT]: {
    [TimeDigitName.DAYS]: 'Countdown.days:short',
    [TimeDigitName.HOURS]: 'Countdown.hours:short',
    [TimeDigitName.MINS]: 'Countdown.minutes:short',
    [TimeDigitName.SECS]: 'Countdown.seconds:short',
  },
} as const;

type TProp = {
  className?: string;
  frequency?: number;
  isHiddenWhenEmpty?: boolean;
  mode?: CountdownMode;
  onEnd?: () => void;
  preserveLeadingZeros?: TimeDigitName;
  targetTime: Date | number | TISODateTime;
};

const Countdown = ({
  className,
  frequency,
  isHiddenWhenEmpty,
  mode,
  onEnd,
  preserveLeadingZeros,
  targetTime,
}: TProp) => {
  const thread = useRef<NodeJS.Timeout>();
  const checkedMode =
    mode && [CountdownMode.COMPACT, CountdownMode.SHORT, CountdownMode.UNLABELED].includes(mode)
      ? mode
      : CountdownMode.DEFAULT;

  const endMoment = useMemo(() => getTimeValue(targetTime), [targetTime]);

  const calcRemain = useCallback(() => getRemainTime(endMoment), [endMoment]);

  const [remain, setRemain] = useState<number>(0);

  useEffect(() => {
    const stop = () => {
      if (thread.current) {
        clearInterval(thread.current);
        thread.current = undefined;
      }
    };

    const update = () => {
      const next = calcRemain();
      if (!next) {
        stop();
        onEnd?.();
      }
      setRemain(next);
    };

    stop();
    update();
    const timeout = 1000 / (frequency! > 0 ? frequency! : 1);
    thread.current = setInterval(update, timeout);
    return stop;
  }, [calcRemain, frequency, onEnd]);

  const values = useMemo(() => {
    const nextDigits = makeDigits(remain) || (!isHiddenWhenEmpty ? ({} as Record<TimeDigitName, number>) : undefined);
    if (nextDigits) return formatDigits(nextDigits, preserveLeadingZeros || TimeDigitName.DAYS);
  }, [isHiddenWhenEmpty, preserveLeadingZeros, remain]);

  const isLabeled = checkedMode !== CountdownMode.UNLABELED;
  return (
    !!values && (
      <div className={cn('Countdown', className, `_${checkedMode}`)}>
        {Object.keys(values || {}).map((digitName) => {
          const value = values?.[digitName as TimeDigitName];
          const key = isLabeled ? checkedMode : digitName === TimeDigitName.DAYS ? CountdownMode.SHORT : undefined;
          return (
            <div className="Countdown__digit" key={digitName}>
              <div className={cn('Countdown__value', { _long: +value >= 100 })}>{value}</div>
              <div className="Countdown__label">
                {!!key && (
                  <UIResource id={CMS_TEMPLATE_IDS[key][digitName as TimeDigitName]} values={{ N: +value! || 0 }} />
                )}
                {!key && digitName !== TimeDigitName.SECS && ':'}
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default Countdown;
