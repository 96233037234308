import Image from 'next/image';

import cn from 'classnames';

import FormCheck from '@/components/elements/FormCheck';
import ArrowLeft from '@/components/Icon/ArrowLeft';
import CaretRight from '@/components/Icon/CaretRight';
import { ListBox, type ListBoxProps, ListItem } from '@/components/ListBox';
import UIResource from '@/components/UIResource';
import { getSafeElementId } from '@/helpers/util';
import { createOnKeyDownHandler } from '@/hooks/useOnKeyDown';

import { type AllItemsIds, type DestinationRegion, DestinationType } from './types';
import { isWholeChecked as checkIfWholeChecked, getCheckedItemsIds } from './utils';

import styles from './RegionsListBox.module.scss';

type DestinationRegionsListBoxProps = {
  allItemsIds: AllItemsIds;
  'aria-controls': string;
  avoidRegionSelectOnCheck?: boolean;
  className?: string;
  destinationType: DestinationType;
  getRegions: () => DestinationRegion[];
  id?: string;
  listBoxNavigationDisabled?: boolean;
  onCheck: ListBoxProps<string>['onPressEnterItem'];
  onKeyDown?: ListBoxProps<string>['onKeyDown'];
  onSelectItem: ListBoxProps<string>['onSelectItem'];
  value?: string;
};

const DestinationRegionsListBox = ({
  allItemsIds,
  ['aria-controls']: ariaControls,
  className,
  destinationType,
  getRegions,
  id,
  listBoxNavigationDisabled,
  onCheck,
  onKeyDown,
  onSelectItem,
  value,
}: DestinationRegionsListBoxProps) => {
  return (
    <ListBox
      aria-controls={ariaControls}
      className={cn(styles.listBox, className)}
      id={id}
      navigationDisabled={listBoxNavigationDisabled}
      onKeyDown={onKeyDown}
      onPressEnterItem={onCheck}
      onSelectItem={onSelectItem}
      selectOnFocusChange
      value={value}
    >
      {getRegions().map((region) => {
        const itemId = `dest_region_${getSafeElementId(region.id)}_${destinationType}`;
        const isWholeChecked = checkIfWholeChecked(region, destinationType, allItemsIds);
        const checkedIds = getCheckedItemsIds(allItemsIds, destinationType, region.id);
        const isChecked = checkedIds.length > 0;
        const isSelected = value === region.id;
        const labelId = `${itemId}_label`;
        const announceLabel = `${region.label}, checkbox`;

        const onChange = (e: React.UIEvent) => {
          e.preventDefault();
          e.stopPropagation();
          onCheck?.(region.id);
        };

        return (
          <ListItem className={styles.listItem} key={`region ${region.id} ${destinationType}`} value={region.id}>
            <div aria-labelledby={listBoxNavigationDisabled ? undefined : labelId} className={styles.item}>
              <FormCheck
                aria-label={listBoxNavigationDisabled ? `Select all ${region.label} items` : undefined}
                checked={isWholeChecked}
                id={`select-all-${itemId}`}
                indeterminate={!isWholeChecked && isChecked}
                inputTabIndex={listBoxNavigationDisabled ? 0 : -1}
                labelProps={{ onClick: onChange, tabIndex: -1 }}
                onKeyDown={
                  listBoxNavigationDisabled ? createOnKeyDownHandler(onChange, { isDefaultPrevented: true }) : undefined
                }
                tabIndex={-1}
                type="checkbox"
                value={region.id}
              />

              <div className={styles.itemContent} id={`region-row-${id}`}>
                <div aria-hidden="true" className={styles.image}>
                  {region.image && <Image alt="" height="100" src={region.image.src} width="100" />}
                  <div className={cn(styles.imageLabel, { [styles.imageLabelChecked!]: isChecked })}>
                    <div className={styles.imageLabelText}>
                      {isWholeChecked ? (
                        <UIResource id="DestinationRefinement.allSelected" />
                      ) : isChecked ? (
                        <UIResource id="DestinationRefinement.selected" values={{ count: checkedIds.length }} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  aria-hidden="true"
                  className={cn(styles.description, {
                    [styles.descriptionSelected!]: isSelected,
                  })}
                  id={labelId}
                >
                  <div className={styles.descriptionName}>{region.label}</div>
                  <div className={styles.descriptionTitle}>{region.subLabel}</div>
                  {/* key prop forces recreation of node for safari Voiceover to properly read contents */}
                  <div className="sr-only" key={checkedIds.length}>
                    {', '}
                    {isWholeChecked && `All checked, ${announceLabel}`}
                    {!isWholeChecked &&
                      (isChecked ? `${checkedIds.length} checked, ${announceLabel}` : `Not checked, ${announceLabel}`)}
                  </div>
                </div>

                <button
                  aria-label={`Show ${region.label} items`}
                  className={styles.arrow}
                  id={`open-region-items-${region.id}`}
                  tabIndex={listBoxNavigationDisabled ? 0 : -1}
                >
                  <span className={styles.arrowDesktop}>
                    <CaretRight />
                  </span>
                  <span className={styles.arrowMobile}>
                    <ArrowLeft />
                  </span>
                </button>
              </div>
            </div>
          </ListItem>
        );
      })}
    </ListBox>
  );
};

export default DestinationRegionsListBox;
