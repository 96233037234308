import {
  CELEBRATION_EXIT_REFERRED_BOOKER_FLOW,
  CELEBRATION_FETCH_PRODUCT_FAILURE,
  CELEBRATION_FETCH_PRODUCT_SUCCESS,
  CELEBRATION_LINK_REFERRAL_CODE_FAILURE,
  CELEBRATION_LINK_REFERRAL_CODE_SUCCESS,
  CELEBRATIONS_ACTIVE_FAILURE,
  CELEBRATIONS_ACTIVE_SUCCESS,
  CELEBRATIONS_RESET,
  COMPLETE_CELEBRATION_PROGRAM,
} from './actionTypes';

const defaultState = {
  isCelebrations: false,
  isCelebrationsLink: false,
  isExitCelebrations: false,
  params: {
    newReferral: null,
    newReferralCode: null,
    packageCode: null,
    referralCode: null,
    referralUrl: null,
    reservationId: null,
    shipCode: null,
    voyageId: null,
  },
  products: {
    packages: [],
    sailings: [],
  },
  referralType: 'CELEBRATIONS',
};

export default function celebrationReducer(state = defaultState, action) {
  switch (action.type) {
    case CELEBRATION_EXIT_REFERRED_BOOKER_FLOW:
      return {
        ...state,
        isCelebrations: false,
        isExitCelebrations: true,
        params: defaultState.params,
      };
    case CELEBRATION_FETCH_PRODUCT_FAILURE:
      return { ...state, products: defaultState.products };
    case CELEBRATION_FETCH_PRODUCT_SUCCESS:
      return { ...state, products: action.payload };

    case CELEBRATION_LINK_REFERRAL_CODE_FAILURE:
      return { ...state, isCelebrationsLink: false };
    case CELEBRATION_LINK_REFERRAL_CODE_SUCCESS:
      return { ...state, isCelebrationsLink: true, params: action.payload };

    case CELEBRATIONS_ACTIVE_FAILURE:
      return { ...state, isCelebrations: false };

    case CELEBRATIONS_ACTIVE_SUCCESS:
      return {
        ...state,
        isCelebrations: true,
        isExitCelebrations: false,
        params: action.payload,
      };

    case CELEBRATIONS_RESET:
      return defaultState;
    case COMPLETE_CELEBRATION_PROGRAM:
      return {
        ...state,
        isCelebrations: false,
        isCelebrationsLink: false,
        isExitCelebrations: true,
        params: action.payload,
      };
    default:
      return state;
  }
}
