import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

import {
  selectDestinationsFilterData,
  selectDestinationType,
} from '@/components/ResultRefinements/DestinationRefinement/selectors';
import { DestinationType } from '@/components/ResultRefinements/DestinationRefinement/types';
import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';

import { FILTER_KEY } from '../types';
import { getSearchParamsValue, toStringArray } from '../utils';

const selectSelectedRegions = createSelector(
  selectDestinationsFilterData,
  selectDestinationType,
  (_: RootState, searchParams: URLSearchParams) => searchParams,
  (data, destinationType, searchParams) => {
    const selectedRegions = data[destinationType].filter((region) => region.selectedItemsIds.length > 0);
    const regionsIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPortsRegions, searchParams));

    return regionsIds.length > 0 && destinationType === DestinationType.PORTS_OF_CALL
      ? selectedRegions.reduce<typeof selectedRegions>(
          (rawSelectedRegions, region) =>
            regionsIds.includes(region.id) ? [...rawSelectedRegions, region] : rawSelectedRegions,
          [],
        )
      : selectedRegions;
  },
);

export const selectDestinationsLabel = createSelector(
  selectDestinationsFilterData,
  selectResources,
  selectDestinationType,
  (state: RootState, searchParams: URLSearchParams) => selectSelectedRegions(state, searchParams),
  (data, resources, destinationType, selectedRegions) => {
    const allRegions = selectedRegions.length === 0 || selectedRegions.length === data[destinationType].length;

    if (allRegions) {
      return combineUIResource(resources, 'ChooseVoyage.Results.AllDestinations');
    }

    let labels = selectedRegions.reduce<string[]>(
      (labels, region) => (region.label ? [...labels, region.label] : labels),
      [],
    );

    if (labels.length > 0) {
      labels = [labels.slice(0, -1).join(', '), labels[labels.length - 1]!].filter(Boolean);
    }

    return labels.join(' & ');
  },
);

export const selectDestinationsPillLabel = createSelector(
  selectResources,
  (state: RootState, searchParams: URLSearchParams) => selectSelectedRegions(state, searchParams),
  (resources, selectedRegions) => {
    if (!selectedRegions.length) {
      return combineUIResource(resources, 'Destination.filter.text');
    }

    return selectedRegions
      .slice(0, 2)
      .map(({ allSelected, label, selectedItemsIds }) =>
        allSelected ? label : `${label} (${selectedItemsIds.length})`,
      )
      .join(', ');
  },
);
