import { createSelector } from '@reduxjs/toolkit';

import { selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { type RootState } from '@/store';

import { getCacheKey } from './utils';

export const selectQuickSearchItemsData = (state: RootState) => state.quickSearchItems;

export const selectQuickSearchItemsCache = createSelector(
  selectQuickSearchItemsData,
  selectCurrencyCode,
  selectSailors,
  (data, currencyCode, sailors) => data?.items?.[getCacheKey(currencyCode!, sailors)],
);

export const selectQuickSearchItems = createSelector(selectQuickSearchItemsCache, (cache) => cache?.data);

export const selectQuickSearchItemsIsLoaded = createSelector(
  selectQuickSearchItemsCache,
  (cache) => (cache?.loadedAt || 0) > 0,
);

export const selectHeadlineBackground = (state: RootState) => selectQuickSearchItemsData(state).headlineBackground;
