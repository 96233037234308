import trackerConstants from './constants';
import * as containers from './containers';
import * as dataLayer from './dataLayer';
import eventBuilder from './event/builders';
import * as typedTrackers from './event/types';
import * as hocs from './hocs';
import * as hooks from './hooks';
import observer from './observer';
import * as defaultTrackers from './tracker';

const tracker = {
  ...defaultTrackers,
  ...typedTrackers,
};

export default {
  containers,
  eventBuilder,
  hocs,
  hooks,
  observer,
  tracker,
  trackerConstants,
  ...dataLayer,
};

export * as tagmanagerTypes from './types';
