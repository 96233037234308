import type { TISODateTime } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import getTimeValue from './getTimeValue';

const getRemainTime = (datetime: Date | number | TISODateTime, asIs?: TOptional<boolean>): number => {
  const value = getTimeValue(datetime) - Date.now();
  return value > 0 || asIs ? value : 0;
};

export default getRemainTime;
