import { addDays } from 'date-fns';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getSearchParams } from '@/ducks/routes/history';
import { clear, setIsEligible, setIsReady } from '@/ducks/uplift';
import { isServerSide } from '@/helpers/isServerSide';
import { callInSequence } from '@/helpers/util/callInSequence';
import { format, formatDate, parse } from '@/helpers/util/dateUtil';
import { getSessionStorageValue, removeSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';
import { rootStore } from '@/store';

const UPLIFT_ELIGIBLE = 'isUpliftEligible';

const upliftOnChangeCallback = (response) => {
  const { dispatch } = rootStore;
  const statusHandlers = {
    OFFER_AVAILABLE: () => {
      setSessionStorageValue(UPLIFT_ELIGIBLE, true);
      dispatch(setIsEligible(true));
    },
    OFFER_UNAVAILABLE: () => {
      setSessionStorageValue(UPLIFT_ELIGIBLE, false);
      dispatch(setIsEligible(false));
    },
    SERVICE_UNAVAILABLE: () => {
      removeSessionStorageValue(UPLIFT_ELIGIBLE);
      dispatch(setIsEligible(false));
    },
    TOKEN_AVAILABLE: () => {},
    TOKEN_RETRIEVED: () => {},
  };
  statusHandlers[response.status]();
};

export const loadUplift = (
  { config, currencyCode, isEnabled } /** : {
  isEnabled: boolean;
  config: TConfigServiceUplift;
  currencyCode: TCurrencyCode;
}*/,
) => {
  if (isEnabled && !isEmpty(config) && isCurrencySupported(currencyCode)) {
    const configsForCurrencyCode = config?.upCodes?.[currencyCode];
    const apiKey = config?.apiKey;
    if (configsForCurrencyCode && apiKey) {
      loadUpliftPaymentsJs(configsForCurrencyCode, () => {
        initUpliftPayments({
          apiKey,
          currency: currencyCode,
          onChange: upliftOnChangeCallback,
          upcode: configsForCurrencyCode,
        });
      });
    }
  }
};

/**
 * This code is lifted from https://developer.uplift.com/web/5.0/loading-payment-js
 * and does not need to be linted
 */

function load(u, p, l, i, f, t, b, j) {
  /* eslint-disable */
  u.UpLiftPlatformObject = f;
  (u[f] =
    u[f] ||
    function () {
      (u[f].q = u[f].q || []).push(arguments);
    }),
    (u[f].l = 1 * new Date());
  (b = p.createElement(l)), (j = p.getElementsByTagName(l)[0]);
  b.async = 1;
  b.src = `${i}?id=${t}`;
  j.parentNode.insertBefore(b, j);
  let o = window.location.host.match(/[w-]+.w{2,3}(:d+)?$/);
  if (o) {
    o = o[0];
  }
  u[f]('create', t, o);
  /* eslint-enable */
}

const prepareSailingObjectForUplift = (sailingData) => {
  if (isEmpty(sailingData)) {
    return null;
  }
  const ports = sailingData.itinerary || sailingData.ports;
  const sailing = {
    ports: ports.map((port) => ({
      day: port.itineraryDay || port.day,
      name: port.portName || port.name,
    })),
    startDate: sailingData.embarkDate || sailingData.startDate,
  };
  return sailing;
};

export const loadUpliftPaymentsJs = callInSequence(
  (upCode, callback) =>
    new Promise((resolve, reject) => {
      const { dispatch } = rootStore;
      try {
        const timeout = setTimeout(() => {
          reject(new Error('uflift init timeout'));
        }, 60000);

        if (!isServerSide() && (window.upReady || window.Uplift)) {
          dispatch(clear());
          if (window.Uplift?.Payments) {
            window.Uplift.Payments.clear();
            window.Uplift.Payments.exit();
          }
          delete window.upReady;
          delete window.Uplift;

          window._upliftFlag = undefined;
          const scriptList = document.getElementsByTagName('script');
          const convertedNodeList = Array.from(scriptList);
          convertedNodeList.forEach((script) => {
            if (script.src.includes('/up.js')) {
              script.parentNode.removeChild(script);
            }
          });
        }
        window.upReady = async () => {
          if (typeof callback === 'function') {
            try {
              await callback();
            } catch (e) {
              reject(e);
              dispatch(setIsReady(false));
              return;
            }
          }

          resolve();
          dispatch(setIsReady(true));
          clearTimeout(timeout);
        };
        load(window, document, 'script', '//cdn.uplift-platform.com/a/up.js', 'up', upCode);
      } catch (e) {
        reject(e);
      }
    }),
);

const locales = { CAD: 'en-CA', USD: 'en-US' };

export const isCurrencySupported = (currency) => Object.keys(locales).includes(currency);

export const initUpliftPayments = ({
  apiKey,
  checkout = false,
  container = null,
  currency = 'USD',
  onChange = null,
  upcode,
}) => {
  const locale = locales[currency] || 'en-US';
  window.Uplift.Payments.init({
    apiKey,
    checkout,
    container,
    currency,
    locale,
    onChange,
    upcode,
  });
};

const formatTripInfoDate = (date, dayNumber = 0) => format(addDays(parse(date), dayNumber - 1), 'yyyyMMdd');

const getTravelerInfo = (sailor, id) => {
  const { dob, firstname, lastname } = sailor;
  return {
    date_of_birth: formatDate(dob, 'MM/dd/yyyy') || null,
    first_name: firstname,
    id,
    last_name: lastname,
  };
};
export const buildTripInfo = (price, insurancePrice, sailing, sailorDetails, cabinName, additionalSailors) => {
  const upliftSailingObject = prepareSailingObjectForUplift(sailing);
  const travelers = [];
  let billing_contact = {};
  const ports = upliftSailingObject?.ports || [];
  const itinerary = ports.reduce((acc, port, index, src) => {
    if (index < src.length - 1) {
      const arrivalPort = src[index + 1];
      acc.push({
        arrival_date: formatTripInfoDate(upliftSailingObject.startDate, arrivalPort.day),
        arrival_port: arrivalPort.name,
        departure_date: formatTripInfoDate(upliftSailingObject.startDate, port.day),
        departure_port: port.name,
      });
    }
    return acc;
  }, []);
  const { cabinType, shipCode } = getSearchParams();
  const suiteName = getSessionStorageValue('suiteName');
  const cruiseInfo = {
    cruise_duration: get(sailing, 'duration', ''),
    cruise_line: 'Virgin Voyages',
    disembark_date: formatDate(get(sailing, 'debarkDate', ''), 'yyyyMMdd'),
    embark_date: formatDate(get(sailing, 'embarkDate', ''), 'yyyyMMdd'),
    itinerary,
    rooms: [{ cabin_type: suiteName, state_room_type: cabinName || cabinType }],
    ship_code: get(sailing, 'ship.code', shipCode),
  };
  if (sailorDetails) {
    const { city, contactnumber, country, dob, email, firstname, lastname, postalCode, stateCode, streetAddress } =
      sailorDetails;
    if (firstname && lastname) {
      travelers.push(getTravelerInfo({ dob, firstname, lastname }, 0));
    }
    if (additionalSailors && additionalSailors.length > 0) {
      additionalSailors.forEach((sailor, index) => {
        const { dob, firstname, lastname } = sailor;
        if (firstname && lastname) {
          travelers.push(getTravelerInfo({ dob, firstname, lastname }, index + 1));
        }
      });
    }
    billing_contact = {
      date_of_birth: formatDate(dob, 'MM/dd/yyyy') || null,
      email,
      first_name: firstname,
      last_name: lastname,
      phone: contactnumber,
      ...(sailorDetails.insurance && {
        city,
        country,
        postal_code: postalCode,
        region: stateCode,
        street_address: streetAddress,
      }),
    };
    cruiseInfo.insurance = [];
    if (sailorDetails.insurance) {
      cruiseInfo.insurance.push({
        id: '0',
        price: insurancePrice ?? 0,
        types: ['cancellation'],
      });
    }
  }
  return {
    billing_contact,
    cruise_reservations: [cruiseInfo],
    order_amount: Math.ceil(price),
    travelers,
  };
};

export const loadTripInfo = (tripInfo) => {
  try {
    if (window.Uplift) {
      window.Uplift.Payments.load(tripInfo);
    }
  } catch (e) {
    // Do nothing
  }
};

export const selectPaymentMethod = () => {
  try {
    window.Uplift.Payments.select();
  } catch (e) {
    // Do nothing
  }
};

export const deselectPaymentMethod = (option) => {
  try {
    window.Uplift.Payments.deselect(option);
  } catch (e) {
    // Do nothing
  }
};

const getPayOption = (option) => {
  switch (option) {
    case 'DEPOSIT':
    case 'FULL':
    case 'HOLD':
      return option.toLowerCase();
    case 'UPLIFT':
      return 'pay monthly';
    default:
      return 'other';
  }
};

export const setOrderResponse = (paymentOption, transactionId) => {
  const option = getPayOption(paymentOption);
  window.Uplift?.Analytics.orderResponse({
    mode_of_payment: option,
    order_id: transactionId,
  });
};

export const setOrderSubmit = (orderInfo, option) => {
  window.Uplift?.Analytics.orderSubmit(orderInfo, {
    mode_of_payment: option,
    payment_options: ['full', 'deposit', 'hold', 'pay-monthly'],
  });
};
