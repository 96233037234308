import { type PayloadAction } from '@reduxjs/toolkit';

import addToSearchParams from '@/helpers/url/addToSearchParams';
import { type TUrlParams } from '@/infra/types/common';
import { type TOptional } from '@/types/common';

import { type RootState } from '../';
import buildInitialState from '../buildInitialState';
import { FetchablePartName, type TFetchableData } from '../fetchableParts/types';

export const PREFILL_ALL = '@prefill/all' as const;

export type TPrefillInfo = { [name in FetchablePartName]: string };
export type TStorePrefillInfo = { prefillInfo: TPrefillInfo };

// Action

export type TPrefillPayload = {
  fetched: TFetchableData;
  prefillInfo: TPrefillInfo;
  urlParams?: TUrlParams;
};

export const prefillAll = (payload: TPrefillPayload, label?: string) => ({ label, payload, type: PREFILL_ALL });

// Reducers

export const wrapRootReducer =
  <S, A extends PayloadAction<unknown>>(rootReducer: (state: S, action: A) => S) =>
  (state: S, action: A) => {
    if (action.type === PREFILL_ALL) {
      const { fetched, prefillInfo, urlParams } = (action as PayloadAction<TPrefillPayload>).payload;
      const searchParams = urlParams ? addToSearchParams(new URLSearchParams(), urlParams) : new URLSearchParams();
      return {
        ...buildInitialState(fetched, searchParams, state as RootState),
        prefillInfo: { ...(state as TStorePrefillInfo).prefillInfo, ...prefillInfo },
      };
    }
    return rootReducer(state, action);
  };

export const prefillInfoReducer = (state: TPrefillInfo): TPrefillInfo => state ?? ({} as TPrefillInfo);

// Selector

export const selectPrefillInfo = <S extends TStorePrefillInfo>(state: S): TOptional<TPrefillInfo> => state.prefillInfo;
