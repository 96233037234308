import pick from 'lodash/pick';

import { FILTER_KEY, type FiltersKey } from '@/ducks/filters/types';
import { getSearchParamsKeys } from '@/ducks/filters/utils';
import { MNVVSearchParams } from '@/ducks/mnvv/constants';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';
import { ChooseSailingSearchParams } from '@/ducks/voyageInfo/sailing/searchParams';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';

export type PickAnyFromSearchParams = PickSearchParams &
  OuterSearchParams & {
    withCharter?: boolean;
    withChooseCabin?: boolean;
    withChooseSailing?: boolean;
    withFilters?: boolean;
    withMNVV?: boolean;
  };

export const pickAnyFromSearchParams = ({
  outerSearchParams,
  paramsToPick = [],
  withCharter = false,
  withChooseCabin = false,
  withChooseSailing = false,
  withFilters = false,
  withMNVV,
}: PickAnyFromSearchParams = {}) => {
  const filterParams = withFilters ? getAllFiltersKeys() : [];

  const chooseSailingParams = withChooseSailing ? Object.values(ChooseSailingSearchParams) : [];

  const chooseCabinParams = withChooseCabin ? Object.values(ChooseCategorySearchParams) : [];

  const charterParams = withCharter ? ['charterId'] : [];

  const mnvvParams = withMNVV ? Object.values(MNVVSearchParams) : [];

  const paramNames = [
    ...filterParams,
    ...chooseSailingParams,
    ...chooseCabinParams,
    ...charterParams,
    ...mnvvParams,
    ...paramsToPick,
  ];

  return pickSearchParams({ outerSearchParams, paramsToPick: paramNames });
};

type OuterSearchParams = {
  outerSearchParams?: URLSearchParams;
};

type PickSearchParams = { paramsToPick?: string[] };

const pickSearchParams = ({ outerSearchParams, paramsToPick = [] }: OuterSearchParams & PickSearchParams = {}) => {
  const searchParams = searchParamsAsObject(outerSearchParams || getCurrentSearchParams()) as Record<string, string>;

  return pick(searchParams, paramsToPick);
};

const getAllFiltersKeys = () => [
  ...Object.values(FILTER_KEY).reduce<FiltersKey[]>(
    (keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)],
    [],
  ),
];
