import { useCallback, useEffect, useRef, useState } from 'react';

import { env } from '@/environment';
import { isServerSide } from '@/helpers/isServerSide';

import ChatbotContext from './ChatbotContext';
import { CHATBOT_DEFAULT_BOTTOM_OFFSET, CHATBOT_DEFAULT_RIGHT_OFFSET, CHATBOT_SMALL_VIEW_MAX_WIDTH } from './constants';

import './Chatbot.scss';

const chatbotEnabled = env.CHATBOT_URL?.length > 0;

/**
 * Note: Current implementation relies on ChatBot 3rd party library markup,
 * specifically - ".embeddedServiceHelpButton" and ".helpButton"
 * Adjust following logic to new markup if it will be changed
 */
const WithChatbot = ({ children }: { children: React.ReactNode }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const chatbotIframeContainer = useRef<HTMLIFrameElement>();
  const prevWidth = useRef(isServerSide() ? 0 : window.innerWidth);

  useEffect(() => {
    if (!isLoaded && !chatbotIframeContainer.current && chatbotEnabled) {
      const iframe = document.createElement('iframe') as HTMLIFrameElement & Record<string, string>;
      iframe.src = `${env.CONTEXT}/chatbot.html`;
      iframe.allowtransparency = 'true';
      iframe.frameborder = '0';
      iframe.classList.add('isAltItineraryResultUI');
      iframe.classList.add('chatbotIframe');
      iframe.onload = () => {
        const initMessage = {
          messageData: {
            chatbotUrl: env.CHATBOT_URL,
            isAltItineraryResultUI: true,
            parentWindowWidth: window.innerWidth,
          },
          type: 'chatbotInit',
        };
        iframe.contentWindow?.postMessage(initMessage, '*');
      };
      iframe.onerror = (error) => {
        console.error('Chatbot iframe error:', error);
      };
      document.body.appendChild(iframe);
      chatbotIframeContainer.current = iframe;
    }
  }, [isLoaded]);

  useEffect(
    () => () => {
      if (chatbotIframeContainer.current) {
        document.body.removeChild(chatbotIframeContainer.current);
        chatbotIframeContainer.current = undefined;
      }
    },
    [],
  );

  useEffect(() => {
    // @ts-expect-error Hard to infer
    const messageHandler = (messageEvent) => {
      try {
        if (messageEvent.source.ID !== 'chatbotWindow') {
          return;
        }
        const { messageData, type } = messageEvent.data;
        if (type === 'chatbotInited') {
          setIsLoaded(true);
        }
        if (type === 'chatbotInitFailed') {
          console.error(`Chatbot loading failed with message: ${messageData.errorMessage}`);
          chatbotIframeContainer.current?.style.setProperty('opacity', '0');
        }

        if (type === 'chatbotResize' && chatbotIframeContainer.current) {
          chatbotIframeContainer.current.style.setProperty('height', `${messageData.frameHeight + 20}px`);
          chatbotIframeContainer.current.style.setProperty('width', `${messageData.frameWidth + 20}px`);

          const isVisible = messageData.frameWidth > 0 && messageData.frameHeight > 0;
          chatbotIframeContainer.current.classList.toggle('loaded', isVisible);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    const resizeHandler = () => {
      if (chatbotIframeContainer.current) {
        const resizeMessage = {
          messageData: {
            parentWindowWidth: window.innerWidth,
          },
          type: 'parentWindowResize',
        };
        chatbotIframeContainer.current?.contentWindow?.postMessage(resizeMessage, '*');
      }
    };

    if (chatbotEnabled) {
      window.addEventListener('message', messageHandler);
      window.addEventListener('resize', resizeHandler);
    }

    return () => {
      window.removeEventListener('message', messageHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
  const reposition = useCallback(
    // @ts-expect-error Hard to infer
    (event) => {
      const changes = event?.data || {};

      Object.keys(changes).forEach((property) => {
        const value = changes[property];
        chatbotIframeContainer.current?.style.setProperty(property, Number.isNaN(+value) ? value : `${value}px`);
      });
    },
    [isLoaded],
  );

  const setInitialPosition = useCallback(() => {
    reposition({
      data: {
        bottom: window.innerWidth > CHATBOT_SMALL_VIEW_MAX_WIDTH ? 0 : CHATBOT_DEFAULT_BOTTOM_OFFSET,
        right: CHATBOT_DEFAULT_RIGHT_OFFSET,
      },
    });
  }, [reposition]);

  const onResize = useCallback(() => {
    if (chatbotIframeContainer.current) {
      let bottom = null;
      const isFullViewCurrent = window.innerWidth > CHATBOT_SMALL_VIEW_MAX_WIDTH;
      const isFullViewPrev = prevWidth.current > CHATBOT_SMALL_VIEW_MAX_WIDTH;
      if (isFullViewCurrent && !isFullViewPrev) {
        bottom = 0;
      } else if (!isFullViewCurrent && isFullViewPrev) {
        bottom = CHATBOT_DEFAULT_BOTTOM_OFFSET;
      }
      if (bottom !== null) {
        reposition({
          data: {
            bottom,
            right: CHATBOT_DEFAULT_RIGHT_OFFSET,
          },
        });
      }
    }
    prevWidth.current = window.innerWidth;
  }, []);

  useEffect(() => {
    if (isLoaded && chatbotEnabled) {
      window.addEventListener('Chatbot.repositioning', reposition);
      window.addEventListener('Chatbot.restorePositioning', setInitialPosition);
      window.addEventListener('resize', onResize);
      setInitialPosition();
      setIsReady(true);
    }
    return () => {
      if (isLoaded && chatbotEnabled) {
        window.removeEventListener('Chatbot.repositioning', reposition);
        window.removeEventListener('Chatbot.restorePositioning', setInitialPosition);
        window.removeEventListener('resize', onResize);
      }
    };
  }, [reposition]);

  return <ChatbotContext.Provider value={isReady}>{children}</ChatbotContext.Provider>;
};

export default WithChatbot;
