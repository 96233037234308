import dynamic from 'next/dynamic';
import { memo, useEffect } from 'react';

import ilm from '@publicImages/animations/Luggage/large-medium.json';
import ils from '@publicImages/animations/Luggage/large-small.json';
import iml from '@publicImages/animations/Luggage/medium-large.json';
import ims from '@publicImages/animations/Luggage/medium-small.json';
import isl from '@publicImages/animations/Luggage/small-large.json';
import ism from '@publicImages/animations/Luggage/small-medium.json';

import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';

const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

const animations: Record<string, unknown> = {
  ilm,
  ils,
  iml,
  ims,
  isl,
  ism,
};

const getAnimation = (previous: string, next: string) => {
  if (previous === next) {
    previous = previous !== 'large' ? 'large' : 'medium';
  }
  return animations[`i${previous[0]}${next[0]}`];
};

const STORAGE_KEY = 'PREVIOUS_VALUE_LUGGAGE_ANIMATION';

const LuggageAnimation = ({
  defaultDuration = 'large',
  selectedDuration,
}: {
  defaultDuration: string;
  selectedDuration: string;
}) => {
  const previous = getSessionStorageValue(STORAGE_KEY) || defaultDuration;
  useEffect(() => {
    setSessionStorageValue(STORAGE_KEY, selectedDuration);
  }, [selectedDuration]);

  return (
    <Lottie
      animationData={getAnimation(previous, selectedDuration)}
      aria-hidden
      autoplay
      loop={false}
      style={{
        height: 86,
        margin: '0 auto',
        width: 287,
      }}
    />
  );
};

export default memo(LuggageAnimation);
