import { useCallback, useEffect, useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type { NullablePartial } from '@/types/common';

import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY, type FiltersTypes } from '@/ducks/filters/types';
import uniq from '@/helpers/uniq';
import { setTrackingDestinationFilter, trackResetFilters } from '@/helpers/util/tracking';
import { useAppDispatch, useAppSelector } from '@/store';

import { selectDestinationsFilterData, selectDestinationType } from './selectors';
import { type AllItemsIds, DestinationType } from './types';
import {
  addItemsIds,
  type DestinationRefinementProps,
  getAppliedItemsIds,
  getCheckedItemsIds,
  getEmptyItemsIds,
  getItemLabel,
  isItemIdSelected,
  isWholeChecked,
  removeItemsIds,
} from './utils';

const useDestinationRefinementData = (props: DestinationRefinementProps) => {
  const dispatch = useAppDispatch();

  const {
    popoverProps,
    popoverProps: { closePopover, isOpen },
  } = props;

  const [ariaLiveMessageItems, setItemsAriaLiveMessage] = useState('');
  const [ariaLiveMessageRegions, setRegionsAriaLiveMessage] = useState('');

  const data = useAppSelector(selectDestinationsFilterData);
  const appliedDestinationType = useAppSelector(selectDestinationType);

  const getAppliedData = useCallback(() => {
    return {
      appliedDestinationType,
      appliedItemsIds: getAppliedItemsIds(data),
      appliedRegionId:
        data[appliedDestinationType].find((region) => region.selected)?.id || data[appliedDestinationType][0]?.id,
    };
  }, [data, appliedDestinationType]);

  const { appliedItemsIds, appliedRegionId } = useMemo(() => getAppliedData(), [getAppliedData]);

  const [destinationType, setDestinationType] = useState(appliedDestinationType);
  const [selectedRegionId, setSelectedRegionId] = useState(appliedRegionId);
  const [allItemsIds, setAllItemsIds] = useState<AllItemsIds>(appliedItemsIds);

  const getRegions = useCallback((type?: DestinationType) => data[type || destinationType], [data, destinationType]);
  const getRegion = useCallback(
    (id?: string, type?: DestinationType) => {
      const regions = getRegions(type);
      return id ? regions.find((region) => region.id === id)! : regions[0]!;
    },
    [getRegions],
  );
  const selectedRegion = useMemo(() => getRegion(selectedRegionId), [getRegion, selectedRegionId]);

  const resetToAppliedData = useCallback(() => {
    const { appliedDestinationType, appliedItemsIds, appliedRegionId } = getAppliedData();
    setDestinationType(appliedDestinationType);
    setSelectedRegionId(appliedRegionId);
    setAllItemsIds(appliedItemsIds);
  }, [getAppliedData]);

  useEffect(() => {
    resetToAppliedData();
  }, [resetToAppliedData, isOpen]);

  const processRegionsAriaLiveMessage = useCallback(
    (newItemsIds: AllItemsIds, regionId?: string) => {
      if (regionId) {
        const region = getRegion(regionId);
        const announceLabel = `${region.label}, checkbox`;
        const isAllChecked = isWholeChecked(region, destinationType, newItemsIds);
        const checkedIds = getCheckedItemsIds(newItemsIds, destinationType, region.id);
        if (isAllChecked) {
          setRegionsAriaLiveMessage(`All checked, ${announceLabel}`);
        } else {
          setRegionsAriaLiveMessage(
            checkedIds.length > 0 ? `${checkedIds.length} checked, ${announceLabel}` : `Not checked, ${announceLabel}`,
          );
        }
      }
    },
    [getRegion, destinationType],
  );

  const processItemsAriaLiveMessage = useCallback(
    (itemId: string, newItemsIds: AllItemsIds) => {
      const label = getItemLabel(itemId, selectedRegion.items, destinationType);
      const announceLabel = `${label}, checkbox`;
      const checked = isItemIdSelected(itemId, newItemsIds, destinationType, selectedRegion.id);
      setItemsAriaLiveMessage(checked ? `Checked, ${announceLabel}` : `Not checked, ${announceLabel}`);
    },
    [destinationType, selectedRegion],
  );

  const onRegionCheck = useCallback(
    (regionId?: string) => {
      const region = getRegion(regionId);
      let newIds;
      if (regionId && !isEmpty(allItemsIds[destinationType]?.[regionId])) {
        newIds = removeItemsIds(allItemsIds, destinationType, allItemsIds[destinationType]![regionId]!);
        setAllItemsIds(newIds);
      } else {
        newIds = addItemsIds(data, allItemsIds, destinationType, region.allItemsIds);
        setAllItemsIds(newIds);
      }
      processRegionsAriaLiveMessage(newIds, regionId);
    },
    [getRegion, allItemsIds, destinationType, processRegionsAriaLiveMessage, data],
  );

  const onRegionSelect = useCallback((regionId: string, e: React.UIEvent) => {
    e.preventDefault();
    setSelectedRegionId(regionId);
  }, []);

  const onDestinationTypeChange = useCallback((value: string) => {
    setDestinationType(value as DestinationType);
  }, []);

  const onItemCheck = useCallback(
    (itemId: string) => {
      let newIds;
      if (isItemIdSelected(itemId, allItemsIds, destinationType, selectedRegionId)) {
        newIds = removeItemsIds(allItemsIds, destinationType, [itemId]);
        setAllItemsIds(newIds);
      } else {
        newIds = addItemsIds(data, allItemsIds, destinationType, [itemId]);
        setAllItemsIds(newIds);
      }
      processItemsAriaLiveMessage(itemId, newIds);
    },
    [allItemsIds, destinationType, selectedRegionId, data, processItemsAriaLiveMessage],
  );

  const isWholeCheckedById = useCallback(
    (regionId: string, destinationType: DestinationType) => {
      const region = getRegion(regionId, destinationType);
      return isWholeChecked(region, destinationType, allItemsIds);
    },
    [getRegion, allItemsIds],
  );

  const onSubmit = useCallback(
    (event: React.UIEvent) => {
      event.stopPropagation();

      const regionsIds = Object.keys(allItemsIds[destinationType] || {}).filter(
        (id) => !isEmpty(allItemsIds[destinationType]?.[id] || []),
      );

      const itemsIds = uniq(
        regionsIds.reduce<string[]>((ids: string[], id: string) => {
          const itemsIds = allItemsIds[destinationType]?.[id] || [];

          if (getRegion(id)?.allItemsIds === itemsIds) {
            // when region is "all selected" not necessary to add it's items to destPackages/destPorts
            // adding region id just to destPackagesRegions/destPortsRegions is enough
            return ids;
          }

          return [...ids, ...itemsIds];
        }, []),
      );

      let filters: NullablePartial<FiltersTypes> = {
        [FILTER_KEY.destPackages]: destinationType === DestinationType.ITINERARIES ? itemsIds : [],
        [FILTER_KEY.destPackagesRegions]: destinationType === DestinationType.ITINERARIES ? regionsIds : [],
        [FILTER_KEY.destPorts]: destinationType === DestinationType.PORTS_OF_CALL ? itemsIds : [],
        [FILTER_KEY.destPortsRegions]: destinationType === DestinationType.PORTS_OF_CALL ? regionsIds : [],
      };

      if (!allItemsIds[destinationType] || isEmpty(allItemsIds[destinationType])) {
        filters = { ...filters, [FILTER_KEY.voyageIds]: null };
      }

      setFilters(filters);

      closePopover?.({ event });
      dispatch(setTrackingDestinationFilter(isWholeCheckedById));
    },
    [isWholeCheckedById, destinationType, getRegion, closePopover, dispatch, allItemsIds],
  );

  const onReset = useCallback((data?: { resetAll?: boolean }) => {
    if (!isEmpty(data) && !data.resetAll) {
      trackResetFilters('destination');
    }

    setAllItemsIds(getEmptyItemsIds());
  }, []);

  const isDataApplied = useMemo(() => !isEqual(getAppliedItemsIds(data), getEmptyItemsIds()), [data]);
  const isDataChanged = useMemo(() => !isEqual(getAppliedItemsIds(data), allItemsIds), [data, allItemsIds]);

  return {
    allItemsIds,
    ariaLiveMessageItems,
    ariaLiveMessageRegions,
    destinationType,
    getRegions,
    isDataApplied,
    isDataChanged,
    isWholeChecked,
    onDestinationTypeChange,
    onItemCheck,
    onRegionCheck,
    onRegionSelect,
    onReset,
    onSubmit,
    popoverProps,
    resetToAppliedData,
    selectedRegion,
    selectedRegionId,
  };
};

export default useDestinationRefinementData;
