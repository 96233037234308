import { useCallback, useState } from 'react';

import cn from 'classnames';

import type { TOptional } from '@/types';

import Countdown, { CountdownMode } from '@/components/Countdown';
import { EyebrowAppearance, type TPromoBannerEyebrow } from '@/features/promoBanners/types';

import verifyValue from '../helpers/verifyValue';

import styles from './PromoBannerEyebrow.module.scss';

const THEMES = ['ink', 'seaSpray', 'urgency', 'cyber'];

export const enum AppearanceType {
  COUNTDOWN = 'countdown',
  TAG = 'tag',
  TEXT = 'text',
}

export const APPEARANCE_TYPES = {
  [EyebrowAppearance.COUNTDOWN_ABBREVIATED]: AppearanceType.COUNTDOWN,
  [EyebrowAppearance.COUNTDOWN_FULL]: AppearanceType.COUNTDOWN,
  [EyebrowAppearance.COUNTDOWN_MINIMAL]: AppearanceType.COUNTDOWN,
  [EyebrowAppearance.DEFAULT]: AppearanceType.TEXT,
  [EyebrowAppearance.TAG]: AppearanceType.TAG,
} as const;

export const COUNTDOWN_MODES: Partial<Record<EyebrowAppearance, CountdownMode>> = {
  [EyebrowAppearance.COUNTDOWN_ABBREVIATED]: CountdownMode.SHORT,
  [EyebrowAppearance.COUNTDOWN_FULL]: CountdownMode.COMPACT,
  [EyebrowAppearance.COUNTDOWN_MINIMAL]: CountdownMode.UNLABELED,
} as const;

export const DEFAULT_EMOJI: Partial<Record<AppearanceType, string>> = {
  [AppearanceType.COUNTDOWN]: '⏳',
  [AppearanceType.TAG]: '📣',
} as const;

type TProps = {
  className?: TOptional<string>;
  isOmitPastCountdown?: TOptional<boolean>;
} & TPromoBannerEyebrow;

const PromoBannerEyebrow = ({
  appearance,
  className,
  countdown,
  emoji,
  isOmitPastCountdown,
  tag,
  text,
  theme,
}: TProps) => {
  const appearanceType =
    APPEARANCE_TYPES[appearance as EyebrowAppearance] || APPEARANCE_TYPES[EyebrowAppearance.DEFAULT];

  const [isValidCountdown, setIsValidCountdown] = useState<boolean>(
    appearanceType === AppearanceType.COUNTDOWN && !!countdown,
  );
  const isValidTag = appearanceType === AppearanceType.TAG && !!tag;
  const isValidText = appearanceType === AppearanceType.TEXT && !!text;

  const onCountdownEnd = useCallback(() => setIsValidCountdown(false), []);

  return (
    (isValidCountdown || isValidTag || isValidText) && (
      <div
        className={cn(styles.root, className, `_appearance-${appearanceType}`, `_theme-${verifyValue(theme, THEMES)}`)}
      >
        {isValidCountdown && (
          <>
            <span aria-hidden="true" className={styles.icon}>
              {emoji || DEFAULT_EMOJI[appearanceType]}
            </span>
            <Countdown
              className={styles.countdown}
              frequency={3}
              isHiddenWhenEmpty
              mode={COUNTDOWN_MODES[appearance as EyebrowAppearance]}
              onEnd={isOmitPastCountdown ? onCountdownEnd : undefined}
              targetTime={countdown!}
            />
          </>
        )}
        {isValidTag && (
          <>
            <span aria-hidden="true" className={styles.icon}>
              {emoji || DEFAULT_EMOJI[appearanceType]}
            </span>
            <span className={styles.tag}>{tag}</span>
          </>
        )}
        {isValidText && text}
      </div>
    )
  );
};

export default PromoBannerEyebrow;
