import { forwardRef } from 'react';

import * as Primitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import classes from './Dialog.module.scss';

export { Close, Description, type DialogProps, Root, Title } from '@radix-ui/react-dialog';

export const Trigger = forwardRef<
  React.ElementRef<typeof Primitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof Primitive.Trigger>
>((props, ref) => <Primitive.Trigger aria-expanded={undefined} ref={ref} {...props} />);

Trigger.displayName = 'DialogTrigger';

export const Isolator = ({ children }: { children: React.ReactNode }) => (
  <div
    data-prevent-nprogress={true}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {children}
  </div>
);

const focusVisibleTrigger = (e: Event) => {
  e.preventDefault();
  const elem = e.currentTarget as Element | null;
  const triggers = document.querySelectorAll<HTMLElement>(`[aria-controls="${elem?.id}"`);
  for (const node of triggers) {
    if (node.checkVisibility() && node.tabIndex !== -1) {
      node.focus();
      break;
    }
  }
};

export const Content = forwardRef<
  React.ElementRef<typeof Primitive.Content>,
  React.ComponentPropsWithoutRef<typeof Primitive.Content> & {
    closeClassName?: string;
    closeSlot?: React.ReactNode;
    containerClassName?: string;
    multipleTriggers?: boolean;
    overlayClassName?: string;
  }
>(
  (
    {
      children,
      className,
      closeClassName,
      closeSlot,
      containerClassName,
      multipleTriggers,
      overlayClassName,
      title,
      ...props
    },
    ref,
  ) => {
    return (
      <Primitive.Portal>
        <Primitive.Overlay className={cn(classes.overlay, overlayClassName)} />
        <Primitive.Content
          className={cn(classes.fixedContainer, containerClassName)}
          onCloseAutoFocus={multipleTriggers ? focusVisibleTrigger : undefined}
          {...props}
          aria-modal
          ref={ref}
        >
          {closeSlot !== undefined ? (
            closeSlot
          ) : (
            <Primitive.Close
              aria-label={`Close ${title ?? ''} dialog`}
              className={cn(classes.close, closeClassName)}
              tabIndex={0}
            >
              <CrossIcon />
            </Primitive.Close>
          )}
          <div aria-label="Dialog content" className={cn(classes.content, className)}>
            {children}
          </div>
        </Primitive.Content>
      </Primitive.Portal>
    );
  },
);
Content.displayName = 'DialogContent';
