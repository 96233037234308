import { type TUrlParams } from '@/infra/types/common';

export type CoreRequestConfig = RequestInit & {
  baseURL?: string;
  language?: string;
  logShortResponseData?: boolean;
  noAuth?: boolean;
  params?: TUrlParams;
  token?: string;
  url?: string;
};

export class HttpError extends Error {
  readonly status: number;
  readonly response: Response;
  readonly request: Request;
  readonly config: CoreRequestConfig;
  readonly responseBody: unknown;

  constructor(response: Response, request: Request, config: CoreRequestConfig, responseBody: unknown) {
    super(`HttpError(status: ${response.status}, statusText: ${response.statusText})`);
    this.status = response.status;
    this.response = response;
    this.request = request;
    this.config = config;
    this.responseBody = responseBody;
  }
}

export class InterruptionError extends Error {
  constructor(message?: string, stack?: string) {
    super(message || 'Request interrupted');
    if (stack) this.stack = stack;
  }

  static recognize(error: Error): Error {
    const { message, name } = error || {};
    const isInterruption =
      (name === 'TypeError' && message === 'Failed to fetch') || // Chrome
      name === 'NetworkError' || // Firefox
      (name === 'TypeError' && message === 'Load failed'); // Safari
    return isInterruption ? new InterruptionError(error.message, error.stack) : error;
  }
}
