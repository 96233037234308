import React, { useCallback } from 'react';

import cn from 'classnames';

import FilterDestination from '@/components/Icon/filterDestination';
import RefinementPopover from '@/components/ResultRefinements/RefinementPopover';
import RefinementPopoverHeader from '@/components/ResultRefinements/RefinementPopover/Header';
import { Tabs, TabsContent } from '@/components/Tabs';
import UIResource from '@/components/UIResource';

import RefinementButtons from '../RefinementButtons';
import DestinationItemsListBox from './ItemsListBox';
import DestinationRegionsListBox from './RegionsListBox';
import DestinationToggle from './Toggle';
import { DestinationType } from './types';
import useDestinationRefinementData from './useDestinationRefinementData';
import { type DestinationRefinementProps } from './utils';

import styles from './PopupDesktop.module.scss';

const itinerariesListId = 'itineraries-list';
const portsOfCallListId = 'ports-of-call-list';

const DestinationRefinementPopupDesktop = (props: DestinationRefinementProps) => {
  const {
    allItemsIds,
    ariaLiveMessageItems,
    ariaLiveMessageRegions,
    destinationType,
    getRegions,
    isDataApplied,
    isDataChanged,
    onDestinationTypeChange,
    onItemCheck,
    onRegionCheck,
    onRegionSelect,
    onReset,
    onSubmit,
    popoverProps,
    selectedRegion,
    selectedRegionId,
  } = useDestinationRefinementData(props);

  const onRegionsKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        document
          .getElementById(destinationType === DestinationType.ITINERARIES ? itinerariesListId : portsOfCallListId)
          ?.focus();
      }
    },
    [destinationType],
  );

  const onItemsKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      document.getElementById('regions-list')?.focus();
    }
  }, []);

  return (
    <RefinementPopover
      {...popoverProps}
      className="filter-popup"
      dialogLabel="Destination Options"
      id="DestinationRefinement"
    >
      <RefinementPopoverHeader className={styles.header} icon={<FilterDestination />}>
        <UIResource id="DestinationRefinement.Book" />
      </RefinementPopoverHeader>

      <Tabs className={styles.details} onValueChange={onDestinationTypeChange} value={destinationType}>
        <DestinationToggle className={styles.toggle} destinationType={destinationType} />

        <div className={styles.regionsList}>
          <div className={styles.detailsTitle}>
            <UIResource id="Book CTA" />
          </div>

          <div aria-live="polite" className="sr-only" role="status">
            {ariaLiveMessageRegions}
          </div>
          <div aria-live="polite" className="sr-only" role="status">
            {ariaLiveMessageItems}
          </div>

          <DestinationRegionsListBox
            allItemsIds={allItemsIds}
            aria-controls={`${itinerariesListId} ${portsOfCallListId}`}
            className={styles.regionsListBox}
            destinationType={destinationType}
            getRegions={getRegions}
            id="regions-list"
            onCheck={onRegionCheck}
            onKeyDown={onRegionsKeyDown}
            onSelectItem={onRegionSelect}
            value={selectedRegionId}
          />
        </div>

        <TabsContent
          className={styles.itemsList}
          preserveNativeAriaLabelledby
          role="tabpanel"
          tabIndex={-1}
          value={DestinationType.ITINERARIES}
        >
          <div className={cn(styles.detailsTitle, styles.itemsDetailsTitle)} id={`${itinerariesListId}-label`}>
            {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.itineraries" />
          </div>

          <DestinationItemsListBox
            allItemsIds={allItemsIds}
            aria-labelledby={`${itinerariesListId}-label`}
            className={styles.itemsListBox}
            destinationType={destinationType}
            id={itinerariesListId}
            onCheck={onItemCheck}
            onKeyDown={onItemsKeyDown}
            selectedRegion={selectedRegion}
          />
        </TabsContent>

        <TabsContent
          className={styles.itemsList}
          preserveNativeAriaLabelledby
          role="tabpanel"
          tabIndex={-1}
          value={DestinationType.PORTS_OF_CALL}
        >
          <div className={cn(styles.detailsTitle, styles.itemsDetailsTitle)} id={`${portsOfCallListId}-label`}>
            {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.ports" />
          </div>

          <DestinationItemsListBox
            allItemsIds={allItemsIds}
            aria-labelledby={`${portsOfCallListId}-label`}
            className={styles.itemsListBox}
            destinationType={destinationType}
            id={portsOfCallListId}
            onCheck={onItemCheck}
            onKeyDown={onItemsKeyDown}
            selectedRegion={selectedRegion}
          />
        </TabsContent>
      </Tabs>
      <RefinementButtons
        isDataApplied={isDataApplied}
        isDataChanged={isDataChanged}
        onApply={onSubmit}
        onReset={onReset}
        withGlow
      />
    </RefinementPopover>
  );
};

export default DestinationRefinementPopupDesktop;
