import React, { useRef } from 'react';

import cn from 'classnames';

import Arrow from '@/components/Icon/Arrow';
import breakpoints from '@/constants/breakpoints';
import { type TUIResourceId, useUIResource } from '@/ducks/common/resources';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import useOnWindowResize from '@/hooks/useOnWindowResize';

import Chevron from '../Icon/Chevron';
import AdvancedFilterMobileHOC from './AdvancedFilterMobileHOC';

type AdvancedFilterHOCProps = React.PropsWithChildren<{
  className: string;
  closeFilterModal?: (e: React.UIEvent) => void;
  defaultValue?: string;
  filterName: string;
  isOpen: boolean;
  toggleFilterSectionState?: (filterName: string) => void;
}>;

const AdvancedFilterHOC = ({
  children,
  className,
  closeFilterModal,
  defaultValue,
  filterName: filterNameResourceId,
  isOpen,
  toggleFilterSectionState,
}: AdvancedFilterHOCProps) => {
  const filterName = useUIResource(filterNameResourceId as TUIResourceId) || filterNameResourceId;
  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const isMobile = useOnWindowResize(() => window.innerWidth < breakpoints.md) ?? false;
  const toggleSection = (e: React.UIEvent) => {
    e.preventDefault();
    toggleFilterSectionState?.(filterName);
  };

  const onToggleSectionKeyDown = useOnKeyDown(toggleSection);

  return (
    <div className={cn('AF__row', className)}>
      <div className="row">
        <div className="col col1" onClick={toggleSection} onKeyDown={onToggleSectionKeyDown}>
          {filterName}
          <div className="border" />
        </div>
        <div className="col col2">
          <div
            aria-expanded={isOpen}
            className="header"
            onClick={toggleSection}
            onKeyDown={onToggleSectionKeyDown}
            ref={toggleButtonRef}
            role="button"
            tabIndex={0}
          >
            <p className="value">
              <span>{defaultValue}</span>
            </p>
            <button
              aria-expanded={isOpen}
              aria-label={Array.isArray(filterName) ? filterName.join(' ') : filterName}
              className="action"
              onClick={toggleSection}
              onKeyDown={onToggleSectionKeyDown}
              tabIndex={-1}
            >
              {isMobile ? <Arrow /> : <Chevron position={isOpen ? 'up' : 'down'} />}
            </button>
          </div>
          {isMobile ? (
            <AdvancedFilterMobileHOC
              className={className}
              closeFilterModal={closeFilterModal}
              defaultValue={defaultValue}
              isOpen={isOpen}
              onTraverseBack={toggleSection}
              title={filterName}
              toggleButtonRef={toggleButtonRef}
            >
              {children}
            </AdvancedFilterMobileHOC>
          ) : (
            isOpen && <div className="content">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilterHOC;
