import type { TCurrencyCode } from '@/infra/types/common';

import { VWOFlag } from '@/types/vwo';

export const CURRENCY_CODE_COOKIE = 'currencyCode';
export const DEFAULT_CURRENCY_CODE = 'USD' as TCurrencyCode;
export const DEFAULT_COUNTRY_CODE = 'US';

// Please use VWOFlag enum instead VWO_FLAGS

// use this object to test VWO features locally
export const VWO_DEFAULTS: Record<VWOFlag, boolean> = {
  [VWOFlag.BOOK_NOW]: false,
  [VWOFlag.CHOOSE_CABIN_30]: false,
  [VWOFlag.EMAIL_OPT_IN_RADIO]: true,
  [VWOFlag.MNVV_OFFER_REVISION]: false,
  [VWOFlag.MOBILE_OPTIN]: false,
  [VWOFlag.PBC_PAYMENT]: true,
  [VWOFlag.SIZZLE_REELS]: false,
  [VWOFlag.TAXES_AND_FEES]: true,
  [VWOFlag.VPS_PAYMENT]: true,
  [VWOFlag.WHATS_INCLUDED]: true,
} as const;

export const SESSION_SKIPPED_KEYS = ['consoleErrCount', 'qm_last_page', 'qm_last_period', 'up_o'];

export const enum BookingChannelTypes {
  fmLink = 'FMLINK',
  openTravel = 'OPENTRAVEL',
}
