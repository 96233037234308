import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

import { selectLookup } from '@/ducks/common/selectors';
import { selectCruisesCommonVoyagesDataRegions } from '@/ducks/cruisesCommon/selectors';
import { selectMulticurrencies } from '@/ducks/filtersOptions/selectors';

export const selectLookupData = (state: RootState) => selectLookup(state);

export const selectServerISOtime = (state: RootState) => selectLookupData(state)?.serverISOtime;

export const selectLookupShips = (state: RootState) => selectLookupData(state)?.ships;

export const selectLookupStates = (state: RootState) => selectLookupData(state)?.states;

export const selectSupportedCurrencies = (state: RootState) =>
  selectLookupData(state)?.currenciesData?.currencies ?? [];

export const selectSupportedCurrenciesList = (state: RootState) =>
  selectSupportedCurrencies(state)?.map(({ code }) => code) ?? [];

export const selectDefaultPackageCodes = (state: RootState) => selectLookupData(state)?.defaultPackageCodes;

export const selectLookupPromotionData = (state: RootState) => selectLookupData(state)?.promotionBanner;

export const selectMultiCurrencyOptions = createSelector(
  selectSupportedCurrencies,
  selectMulticurrencies,
  (currencies, multiCurrencies) => {
    return currencies?.filter(({ code }) => multiCurrencies.includes(code));
  },
);

export const selectRegionCodePriority = createSelector(selectLookup, ({ regionCodePriority }) => regionCodePriority);

export const selectRegionsSortedByPriority = createSelector(
  selectCruisesCommonVoyagesDataRegions,
  selectRegionCodePriority,
  (regions, regionCodePriority) => {
    return regions
      .map((region) => ({
        ...region,
        priority: regionCodePriority?.find(({ code }) => code === region.id)?.priority || 1000,
      }))
      .sort((a, b) => a.priority - b.priority);
  },
);
