import { FILTER_KEY, type FiltersConfigValue, type FiltersTypes } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions';

import { toInt } from '../utils';

const cabins: FiltersConfigValue<FiltersTypes[FILTER_KEY.cabins]> = {
  getValue: ({ state, value }) => {
    const { defaultFilters: { cabins } = {}, maxCabinCount, minCabinCount } = getFiltersOptions(state) ?? {};
    const count = toInt(value);
    return count && count >= minCabinCount && count <= maxCabinCount ? count : cabins;
  },
};

export default cabins;
