import { combineReducers } from 'redux';

import { chosenCategoryReducer } from '@/ducks/voyageInfo/category';
import { chosenSubCategoryReducer } from '@/ducks/voyageInfo/subCategory';

const voyageInfo = combineReducers({
  chosenCategory: chosenCategoryReducer,
  chosenSubCategory: chosenSubCategoryReducer,
});

export default voyageInfo;
