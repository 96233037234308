import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { useAppSelector } from '@/store';

import { type MerchandisingComponents, useIsPromoActive } from './lib/useIsPromoActive';
import { PromotionTimer } from './Timer';

const COMPONENTS = {
  countdownClock: PromotionTimer,
} satisfies Partial<Record<MerchandisingComponents, unknown>>;

type BannerTypes = keyof typeof COMPONENTS;
type ComponentPropsMap = {
  [Type in BannerTypes]: React.ComponentProps<(typeof COMPONENTS)[Type]>;
};

type MerchandisingBannerProps = {
  [Type in BannerTypes]: {
    type: Type;
  } & ComponentPropsMap[Type];
}[BannerTypes];

export const MerchandisingBanner = (props: MerchandisingBannerProps) => {
  const { type, ...restProps } = props;

  const sailingData = useAppSelector(selectSailingData);
  const isPromoActive = useIsPromoActive(type, sailingData);

  if (!isPromoActive) {
    return null;
  }

  const Component = COMPONENTS[type];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Component {...(restProps as any)} />;
};
