import { useCallback, useEffect, useRef } from 'react';

const usePermanentFunc = <A extends unknown[], R>(func: (...args: A) => R): ((...args: A) => R) => {
  const ref = useRef<(...args: A) => R>(func);

  useEffect(() => {
    ref.current = func;
  }, [func]);

  return useCallback((...args: A) => {
    return ref.current(...args);
  }, []);
};

export default usePermanentFunc;
