import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { TCabinCategorySubmeta } from '@/infra/types/cabin';

type State = {
  errorMessage: string | null;
  id: TCabinCategorySubmeta['code'] | null;
};

const initialState: State = {
  errorMessage: null,
  id: null,
};

const chosenSubCategorySlice = createSlice({
  initialState,
  name: 'voyageInfo/chosen-sub-category',
  reducers: {
    clearChosenSubCategory: () => {
      return initialState;
    },
    setChosenSubCategory: (state, { payload: code }: PayloadAction<TCabinCategorySubmeta['code']>) => {
      state.id = code;
    },
    setChosenSubCategoryError: (state, { payload: errorMessage }: PayloadAction<string>) => {
      state.errorMessage = errorMessage;
    },
  },
});

export const { clearChosenSubCategory, setChosenSubCategory, setChosenSubCategoryError } =
  chosenSubCategorySlice.actions;
export const chosenSubCategoryReducer = chosenSubCategorySlice.reducer;
