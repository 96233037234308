import isEmpty from 'lodash/isEmpty';

import { selectFmSearchParams } from '@/ducks/fm/selectors';
import { env } from '@/environment';
import { useAppSelector } from '@/store';

export const usePrepareShareLink = (link: string) => {
  const additionalSearchParams = useAppSelector(selectFmSearchParams) as Record<string, string>;

  if (link) {
    const url = new URL(link);
    if (url.host === env.ORIGIN && !isEmpty(additionalSearchParams)) {
      new URLSearchParams(additionalSearchParams).forEach((value, key) => {
        if (url.searchParams.has(key)) {
          url.searchParams.delete(key);
        }

        url.searchParams.append(key, value);
      });
      return url.href;
    }
  }

  return link;
};
