import { useDeferredValue, useMemo } from 'react';

import cn from 'classnames';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { getSailingsToDisplay } from '@/components/ChooseVoyage/PackageCardDetails/PackageCardTextContent/helpers';
import { DesktopOnly, MobileOnly } from '@/components/elements/Helpers';
import UIResource from '@/components/UIResource';
import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import { MAX_COUNT_DATES_IN_PACKAGE_CARD } from '../constants';
import PackagePrice from '../PackagePrice/PackagePrice';
import SailingCard from '../SailingCard/SailingCard';

import './PackageCardTextContent.scss';

type Props = {
  children: React.ReactNode;
  onSailingClick?: (sailing: SailingFromPackages) => void;
  packageData: FilteredPackage;
  strikethroughValue?: number;
};

const PackageCardTextContent = ({ children, onSailingClick, packageData, strikethroughValue }: Props) => {
  const deferredPackageData = useDeferredValue(packageData);
  const sortedSailings = deferredPackageData.sortedSailings ?? [];
  const { bestPriceSailing, sailingsToDisplay, shouldDisplayBestPriceSailing } = useMemo(
    () => getSailingsToDisplay(deferredPackageData),
    [deferredPackageData],
  );

  const isByKeyboard = useKeyboardFocus();

  return (
    <div className={cn('PackageCardTextContent__textContent', { keyboardFocus: isByKeyboard })}>
      <DesktopOnly>
        <PackagePrice packageData={deferredPackageData} strikethroughValue={strikethroughValue} />
      </DesktopOnly>
      <MobileOnly className="PackageCardTextContent__heading">
        <UIResource id="PackageCard.DepartingOnText" />
      </MobileOnly>
      <div className="sailingCards">
        {sailingsToDisplay.map((sailingData) => (
          <SailingCard
            isBestPriceSailing={shouldDisplayBestPriceSailing && bestPriceSailing?.id === sailingData.id}
            key={sailingData.id}
            onSailingClick={onSailingClick}
            sailing={sailingData}
            strikethroughEnabled={Boolean(strikethroughValue)}
          />
        ))}
      </div>

      {sortedSailings.length > MAX_COUNT_DATES_IN_PACKAGE_CARD && children}
    </div>
  );
};

export default PackageCardTextContent;
