import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type TWarning, WarningTarget } from './types';

export type TStoreWarnings = Record<string, TWarning>;

export const initialState = {} as TStoreWarnings;

export type TSetWarning = {
  target: WarningTarget;
  warning: TWarning;
};

const setWarningCase: CaseReducer<TStoreWarnings, PayloadAction<TSetWarning>> = (stateDraft, { payload }) => {
  const { target, warning } = payload || {};
  if (target && warning) stateDraft[target] = warning;
};

const clearWarningCase: CaseReducer<TStoreWarnings, PayloadAction<string>> = (stateDraft, { payload: target }) => {
  delete stateDraft[target];
};

const clearAllCase: CaseReducer<TStoreWarnings> = () => ({ ...initialState });

const { actions, reducer } = createSlice({
  initialState,
  name: 'warnings',
  reducers: {
    clearAll: clearAllCase,
    clearWarning: clearWarningCase,
    setWarning: setWarningCase,
  },
});

export const { clearAll, clearWarning, setWarning } = actions;

export default reducer;
