import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';

import { getFilterAgent } from '@/ducks/fm/getters';
import { FormattedMessage } from '@/helpers/formatted-message';

import './Heading.scss';

const propTypes = {
  /** Only used for static text */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Optional css classes */
  className: PropTypes.string,
  /** React-Intl intl object. */
  translate: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.shape({}),
  }),
};

const defaultProps = {
  children: null,
  className: null,
  translate: null,
};

const Heading = ({ children, className, translate }) => {
  const agentProfile = getFilterAgent();
  const agentDetails = get(agentProfile, 'data', { isValid: false });
  const isFMBanner = agentDetails.isValid;
  const cssClasses = classnames({
    [className]: className,
    FMBanner: isFMBanner,
    screenTitle: true,
  });

  return (
    <h1 className={cssClasses}>
      {children}
      {translate && !children && (
        <FormattedMessage defaultMessage={translate.defaultMessage} id={translate.id} values={translate.values} />
      )}
    </h1>
  );
};

Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;

export default Heading;
