'use client';

import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import React, { useCallback } from 'react';

import cn from 'classnames';

import Grabbable from '@/components/Grabbable';
import { useUIResourcePool } from '@/ducks/common/resources';
import { env } from '@/environment';
import formatMoney from '@/helpers/util/formatMoney';
import useSsrInitialValues from '@/hooks/useSsrInitialValues';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import { useIsPromoActive } from '../lib/useIsPromoActive';
import { QuickSearchCard } from './Card';
import { UI_RESOURCE_TITLES } from './constants';
import { useQuickSearchItems } from './model/hooks';
import { selectQuickSearchItems, selectQuickSearchItemsIsLoaded } from './model/selectors';
import { type QuickSearchItems } from './model/types';
import { QuickSearchCardsSkeleton } from './skeleton';

import classes from './QuickSearchCards.module.scss';

const mergeURLWithPreviousData = (pathname: string, url: string, params: null | ReadonlyURLSearchParams) => {
  const newParams = new URLSearchParams(url.split('?')[1]);
  const paramsToPreserve = [
    'reservationNumber',
    'channelID',
    'agencyId',
    'agentId',
    'selectedPackages',
    'selectedRegionsIds',
    'currencyCode',
  ];

  paramsToPreserve.forEach((key) => {
    params?.getAll(key).forEach((value) => newParams.append(key, value));
  });

  return `${env.CONTEXT}${pathname}?${newParams}`;
};

type TProps = {
  ssrInitial?: {
    isLoaded: boolean;
    quickSearchItems: QuickSearchItems;
    titles: Record<string, string>;
  };
};

const QuickSearchCardsComponent = ({ ssrInitial }: TProps) => {
  const values = {
    isLoaded: useAppSelector(selectQuickSearchItemsIsLoaded),
    quickSearchItems: useAppSelector(selectQuickSearchItems),
    titles: useUIResourcePool(UI_RESOURCE_TITLES),
  };
  const { isLoaded, quickSearchItems, titles } = useSsrInitialValues(values, ssrInitial) ?? {};

  useQuickSearchItems();

  const pathname = usePathname();
  const params = useSearchParams();

  const onCardClick = useCallback((region: string) => {
    // TODO: should we change tracker topic?
    tagmanager.tracker.blackFriday.onAdvertCardClick(region);
  }, []);

  return (
    <div className={cn(classes.draggable, 'QuickSearchCards')}>
      <Grabbable className={classes.row} sliding="horizontal">
        <div className={classes.container}>
          {!isLoaded ? (
            <QuickSearchCardsSkeleton />
          ) : (
            quickSearchItems?.map(({ currencyCode, name, position, price, priceOld, url }) => {
              const formattedPrice = formatMoney(price, currencyCode);
              const formattedOldPrice = priceOld ? formatMoney(priceOld, currencyCode) : '';

              return (
                <QuickSearchCard
                  key={name}
                  onClick={onCardClick}
                  price={formattedPrice!}
                  priceOld={formattedOldPrice}
                  priceType="per cabin"
                  title={titles![position]}
                  url={mergeURLWithPreviousData(pathname!, url, params)}
                />
              );
            })
          )}
        </div>
      </Grabbable>
    </div>
  );
};

export const QuickSearchCards = ({ ssrInitial }: TProps) => {
  const isActive = useIsPromoActive('quickSearch');
  return isActive && <QuickSearchCardsComponent ssrInitial={ssrInitial} />;
};
