import { createContext } from 'react';

const ReCaptchaContext = createContext({
  isLoaded: false,
  siteKey: '',
});

ReCaptchaContext.displayName = 'ReCaptchaContext';

export default ReCaptchaContext;
