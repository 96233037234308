import { useRef } from 'react';

import { isServerSide } from '@/helpers/isServerSide';

const useOnceOnClient = (fn: () => void): boolean => {
  const ref = useRef<boolean>(false);
  if (!ref.current && !isServerSide()) {
    ref.current = true;
    try {
      fn();
    } catch {
      /* nothing */
    }
  }
  return ref.current;
};

export default useOnceOnClient;
