import { gql } from '@apollo/client';

import { type TLookupCurrency } from '@/ducks/common/lookup/types';

export const FETCH_CLIENT_INFO = gql`
  query CLIENT_INFO($clientRegion: String!, $clientSubRegion: String) {
    lookup {
      regionCodePriority(clientRegion: $clientRegion) {
        code
        priority
      }
      currenciesData(clientRegion: $clientRegion, clientSubRegion: $clientSubRegion) {
        currencies {
          code
          flagIcon
          label
          symbol
        }
        defaultCountryCode
        defaultCurrencyCode
        ipCountryCode
        ipStateCode
      }
    }
  }
`;

export type GQLClientInfo = {
  currencies: TLookupCurrency[];
  defaultCountryCode: string;
  defaultCurrencyCode: string;
  ipCountryCode: string;
  ipStateCode: string;
};

export type GQLClientInfoLookup = {
  currenciesData: GQLClientInfo;
  regionCodePriority: {
    code: string;
    priority: number;
  }[];
};

export type GQLClientInfoResponse = {
  lookup: GQLClientInfoLookup;
};
