import { makeUIResourcePoolSelector, type TUIResourceId } from '@/ducks/common/resources';
import { type AppDispatch, type AppGetState } from '@/store';

import { setWarning } from './slice';
import { WarningTarget, WarningType } from './types';

export { clearAll, clearWarning, setWarning } from './slice';

export const setWarningCabinCategoryExhausted = (asError?: boolean) =>
  setExhaustedWarning(WarningTarget.chooseCabinMeta, asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'error.ChooseCabinRedirect.body',
    title: 'error.ChooseCabinRedirect.heading',
  } as const);

export const setWarningAnyCabinsExhausted = (asError?: boolean) =>
  setExhaustedWarning(WarningTarget.chooseVoyage, asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'error.ChooseVoyageRedirect.body',
    title: 'error.ChooseVoyageRedirect.heading',
  } as const);

export const setEmptySailingDataWarningExhausted = (asError?: boolean) =>
  setExhaustedWarning(WarningTarget.chooseVoyage, asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'Error.emptyDetails.heading',
    title: 'Error.emptyDetails.subheading',
  } as const);

export const setExhaustedWarning =
  (target: WarningTarget, type: WarningType, uiResourcePool: Record<'text' | 'title', TUIResourceId>) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const selector = makeUIResourcePoolSelector(uiResourcePool);
    const { text, title } = selector(getState());
    dispatch(setWarning({ target, warning: { text, title, type } }));
  };
