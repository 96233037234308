import { useCallback } from 'react';

import PriceTypeIcon from '@publicImages/svg/price-type.svg?icon';

import type { FiltersPriceType } from '@/infra/types/common/filters';

import Select from '@/components/Select';
import { selectPriceType } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectDefaultFilterPriceType } from '@/ducks/filtersOptions/selectors';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { useAppDispatch, useAppSelector } from '@/store';

import { usePriceTypeOptions } from './hooks';

import classes from './SortPriceType.module.scss';

const SortPriceType = () => {
  const dispatch = useAppDispatch();

  const priceType = useAppSelector(selectPriceType);
  const defaultPriceType = useAppSelector(selectDefaultFilterPriceType);

  const PriceTypeOptions = usePriceTypeOptions();

  const onApply = useCallback(
    (id: FiltersPriceType) => {
      setFilter.priceType(id);
      dispatch(tracking.trackPriceType({ priceType: id }));
    },
    [dispatch],
  );

  return (
    <form className={classes.root}>
      <span aria-hidden={true} className={classes.title}>
        Price:
      </span>
      <Select
        aria-label="Sort price type"
        className={classes.select}
        defaultValue={defaultPriceType}
        icon={<PriceTypeIcon />}
        onValueChange={onApply}
        options={PriceTypeOptions}
        value={priceType}
      />
    </form>
  );
};

export default SortPriceType;
