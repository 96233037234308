import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type TRedirectTarget } from './types';

export type TStoreForceRedirect = {
  redirectTarget?: TRedirectTarget;
};

const initialState: TStoreForceRedirect = {
  redirectTarget: undefined,
};

const setForceRedirectCase: CaseReducer<TStoreForceRedirect, PayloadAction<TRedirectTarget>> = (
  stateDraft,
  { payload },
) => {
  stateDraft.redirectTarget = payload;
};

const clearForceRedirectCase: CaseReducer<TStoreForceRedirect, PayloadAction<undefined>> = () => ({ ...initialState });

const { actions, reducer } = createSlice({
  initialState,
  name: 'forceRedirect',
  reducers: {
    clearForceRedirect: clearForceRedirectCase,
    setForceRedirect: setForceRedirectCase,
  },
});

export const { clearForceRedirect, setForceRedirect } = actions;

export default reducer;
