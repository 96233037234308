import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { routes } from '@/ducks/routes';
import { updateSearch } from '@/ducks/routes/history';
import { fetchAccessKeyDetailsData, validateAccessKey } from '@/helpers/api/app';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';

import {
  ACCESS_KEY_CLEAR,
  ACCESS_KEY_DETAILS_ERROR,
  ACCESS_KEY_DETAILS_SUCCESS,
  ACCESS_KEY_INITED,
  ACCESS_KEY_SOURCE,
  ACCESS_KEY_VALIDATE_ERROR,
  ACCESS_KEY_VALIDATE_SUCCESS,
} from './accessKeys';
import { mapAccessKeysErrorCodes } from './errorCodes';

export const fetchAccessKeyDetailsAction = (accessKey, config) => async (dispatch) => {
  try {
    const data = await fetchAccessKeyDetailsData(accessKey, config);
    dispatch({ payload: data, type: ACCESS_KEY_DETAILS_SUCCESS });
  } catch (err) {
    dispatch({ payload: err, type: ACCESS_KEY_DETAILS_ERROR });
  }
};

export const setAccessKeyErrorAction =
  ({ code, message }) =>
  (dispatch) => {
    dispatch({
      payload: code ? mapAccessKeysErrorCodes(code).defaultMessage : message,
      type: ACCESS_KEY_VALIDATE_ERROR,
    });
  };

export const validateAccessKeyAction = (accessKey, config) => async (dispatch, getState) => {
  const state = getState();
  const { accessKeyEnabled } = selectSettings(state);
  if (!accessKeyEnabled || !accessKey) {
    console.warn('access key feature not enabled');
    return {};
  }

  try {
    dispatch({ payload: accessKey, type: ACCESS_KEY_INITED });

    const data = await validateAccessKey(accessKey, config);
    dispatch({ payload: data, source: ACCESS_KEY_SOURCE.URL, type: ACCESS_KEY_VALIDATE_SUCCESS });

    await dispatch(fetchAccessKeyDetailsAction(data.campaignKey, config));

    return data;
  } catch (err) {
    dispatch(setAccessKeyErrorAction({ code: err?.response ? err.response.status : null }));
    return { error: 'invalid access key' };
  }
};

export const checkAccessKeyAndSwapToPromoCodeAction =
  (config = {}) =>
  async (dispatch) => {
    const searchParams = getCurrentSearchParams();
    const data = await dispatch(checkAccessKeyAction(config));

    if (data.accessKey === searchParams.get(ACCESS_KEY_PARAM) && data.accessKey !== data.promoCode) {
      updateSearch({ ...searchParams, [ACCESS_KEY_PARAM]: data.promoCode });
    }

    return data;
  };

export const checkAccessKeyAction =
  (config = {}) =>
  async (dispatch) => {
    const searchParams = getCurrentSearchParams();
    const accessKey = searchParams.get(ACCESS_KEY_PARAM);
    if (!accessKey) {
      return {};
    }

    const data = await dispatch(validateAccessKeyAction(accessKey, config));
    return data;
  };

export const removeActiveAccessKeyAction =
  (removeFromSearchParams = true) =>
  (dispatch) => {
    dispatch({ type: ACCESS_KEY_CLEAR });
    if (removeFromSearchParams) {
      routes.searchWithout([ACCESS_KEY_PARAM]);
    }
  };

export const removeActiveAccessKeyErrorAction = () => (dispatch) => {
  dispatch({
    payload: null,
    type: ACCESS_KEY_DETAILS_ERROR,
  });
};
