export const MNVV_OFFER_VALIDITY_IN_YEARS = 2;
export const MNVV_OFFER_LAUNCH_DATE = '12/06/2023';
export const OFFER_REDEMPTION_DAYS = 60;

export const enum MNVVSearchParams {
  agentId = 'agentId',
  agencyId = 'agencyId',
  bookedDate = 'bookedDate',
  channelID = 'channelID',
  maxDate = 'maxDate',
  reservationNumber = 'reservationNumber',
}

export const MNVV_AGENT_ID_KEY = 'agentId';
export const MNVV_AGENCY_ID_KEY = 'agencyId';
export const MNVV_CHANNEL_ID_KEY = 'channelID';
export const MNVV_RESERVATION_NUMBER_KEY = 'reservationNumber';
