import React, { useEffect } from 'react';

import { IconButton } from '@/components/elements';
import Trash from '@/components/Icon/Trash';
import { getAccessKey, getIsAccessKeyStateValid, removeActiveAccessKeyAction } from '@/ducks/accessKeys';
import { resetAKFlowFully } from '@/ducks/accessKeys/utils';
import { type TUIResourceId, useUIResourcePool } from '@/ducks/common/resources';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import styles from './AccessKey.module.scss';

const MARQUEE_UI_RESOURCE_POOL: Record<string, TUIResourceId> = {
  clearCTA: 'AccessKey.clearCTA',
  defaultDescription: 'AccessKey.bannerMessage',
  defaultTitle: 'AccessKey.eyebrow.label',
  mobileClearText: 'AccessKey.clearCTA.short',
  promoIcon: 'AccessKey.emoji.key',
  promoText: 'AccessKey.bookShareToast',
  title: 'AccessKey.bannerTitle',
};

function AccessKeyBanner() {
  const accessKeyDetails = useAppSelector(getAccessKey);
  const { accessKey, error, shortDescription, state, title } = accessKeyDetails;
  const dispatch = useAppDispatch();

  const formatMessages = useUIResourcePool(MARQUEE_UI_RESOURCE_POOL);

  useEffect(() => {
    if (!!error && accessKey == null) {
      dispatch(removeActiveAccessKeyAction());
    }
  }, [error, accessKey, dispatch]);

  const clearAccessKey = () => {
    const buttonText = formatMessages.clearCTA;
    tagmanager.tracker.common.trackIconClick({
      labels: [buttonText],
    });
    dispatch(resetAKFlowFully());
  };

  if (!getIsAccessKeyStateValid(state)) {
    return null;
  }

  return (
    <div className={`${styles.accessKeyBanner} akBanner`}>
      <div className={styles.description}>
        <span className={styles.icon}>{formatMessages.promoIcon}</span>
        <div>
          <span className={styles.title}>{title ?? formatMessages.defaultTitle} </span>
          &nbsp;
          <span>{shortDescription ?? formatMessages.defaultDescription}</span>
        </div>
      </div>
      <div className={styles.dividedLine}>
        <div className={styles.cta}>
          <IconButton aria-label={formatMessages.clearCTA} className={styles.clearButton} onClick={clearAccessKey}>
            <Trash />
            <span className={styles.label}>{formatMessages.clearCTA}</span>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default AccessKeyBanner;
