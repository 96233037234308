import type { TCabinCategorySubmeta } from '@/infra/types/cabin';

import { pickAnyFromSearchParams, type PickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { routes } from '@/ducks/routes';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';

export const onCategoriesChoosingCompleted = ({
  isUpsellApplied,
  subCategory,
}: {
  isUpsellApplied?: boolean;
  subCategory: TCabinCategorySubmeta;
}) => {
  routes.planner.summary.go({
    keepSearch: true,

    [ChooseCategorySearchParams.chosenSubCategoryCode]: subCategory?.code,
    ...(isUpsellApplied !== undefined ? { [ChooseCategorySearchParams.isUpsell]: isUpsellApplied } : {}),
  });
};

export const backToMainPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseVoyage.go(
    pickAnyFromSearchParams({ withCharter: true, withFilters: true, withMNVV: true, ...args }),
  );
};

export const toSubCategoryPage = (
  chosenCategoryCode: TCabinCategorySubmeta['code'],
  args: PickAnyFromSearchParams = {},
) => {
  if (!chosenCategoryCode) {
    toCategoryPage();
    return;
  }

  routes.planner.chooseCabin.category.go(
    pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true, ...args }),
    '',
    {
      cabinCategoryCode: chosenCategoryCode,
    },
  );
};

export const toCategoryPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseCabin.go(
    pickAnyFromSearchParams({ withCharter: true, withChooseSailing: true, withFilters: true, ...args }),
  );
};
