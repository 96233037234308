export const ACCESS_KEY_STATES = {
  DETAIL: 'DETAIL',
  EMPTY: 'EMPTY',
  INIT: 'INIT',
  INVALID: 'INVALID',
  VALID: 'VALID',
};

// for v1 and v2 versions
export const getIsAccessKeyStateValid = (state) => state === ACCESS_KEY_STATES.DETAIL;

export const ACCESS_KEY_SOURCE = {
  MANUAL_ENTERING: 'MANUAL_ENTERING',
  URL: 'URL',
};

export const ACCESS_KEY_INITED = 'ACCESS_KEY_INITED';

export const ACCESS_KEY_VALIDATE_SUCCESS = 'ACCESS_KEY_VALIDATE_SUCCESS';
export const ACCESS_KEY_VALIDATE_ERROR = 'ACCESS_KEY_VALIDATE_ERROR';

export const ACCESS_KEY_DETAILS_SUCCESS = 'ACCESS_KEY_DETAILS_SUCCESS';
export const ACCESS_KEY_DETAILS_ERROR = 'ACCESS_KEY_DETAILS_ERROR';

export const ACCESS_KEY_CLEAR = 'ACCESS_KEY_CLEAR';

const defaultState = {
  accessKey: null,
  error: null,
  filterSailing: false,
  promoCode: null,
  source: null,
  state: ACCESS_KEY_STATES.EMPTY,
};

export const getAccessKey = (state) => state.accessKeys;
export const selectAccessKey = (state) => getAccessKey(state)?.accessKey;
export const selectAccessKeyPromoCode = (state) =>
  getIsAccessKeyStateValid(getAccessKey(state).state) ? getAccessKey(state)?.promoCode : null;
export const selectAccessKeyState = (state) => getAccessKey(state)?.state;

export default function accessKeys(state = defaultState, action) {
  switch (action.type) {
    case ACCESS_KEY_CLEAR:
      return defaultState;
    case ACCESS_KEY_DETAILS_ERROR:
    case ACCESS_KEY_VALIDATE_ERROR: {
      return { ...state, error: action.payload, state: ACCESS_KEY_STATES.INVALID };
    }
    case ACCESS_KEY_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: null,
        state: ACCESS_KEY_STATES.DETAIL,
      };
    }
    case ACCESS_KEY_INITED: {
      return {
        ...defaultState,
        accessKey: action.payload,
        state: ACCESS_KEY_STATES.INIT,
      };
    }
    case ACCESS_KEY_VALIDATE_SUCCESS: {
      return {
        ...action.payload,
        error: null,
        source: action.source,
        state: ACCESS_KEY_STATES.VALID,
      };
    }
    default:
      return state;
  }
}
