'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { BookingChannelTypes } from '@/constants/settings';
import { selectLookup } from '@/ducks/common/selectors';
import { useFiltersValuesInit } from '@/ducks/filters/hooks';
import { selectAgentData } from '@/ducks/fm/selectors';
import { removeAgencyData, removeAgencySearchParams, setAgencyData } from '@/ducks/fm/setters';
import { AgentDataActionType } from '@/ducks/fm/types';
import useStorePersistentAligners from '@/ducks/persistent/useStorePersistentAligners';
import { checkAllProgramKeys } from '@/ducks/programs/all/actions';
import useRouteChangeObserver from '@/ducks/routeChange/useRouteChangeObserver';
import ApplyPromoColors from '@/features/promo/ApplyPromoColors';
import usePromoBannerUpdater from '@/features/promoBanners/usePromoBannerUpdater';
import useRouteConfig from '@/features/routeConfig/useRouteConfig';
import useStrikethroughUpdater from '@/features/strikethrough/useStrikethroughUpdater';
import { type TCampaignAndLegal } from '@/helpers/api/resources';
import CMSContextProvider from '@/helpers/cms/CMSContextProvider';
import { FormattedMessageProvider } from '@/helpers/formatted-message';
import { googleTagManagerCode } from '@/helpers/inlineScripts';
import useInitAccountDropdownEvents from '@/helpers/managers/account-dropdown/useInitAccountDropdownEvents';
import { toBlobPolyfill } from '@/helpers/util';
import { initLogger } from '@/helpers/util/logger';
import { useCatchAsyncError } from '@/hooks/useAsyncError';
import { useIosTextFieldFocusZoomPatch } from '@/hooks/useIosTextFieldFocusZoomPatch';
import useOnceOnClient from '@/hooks/useOnceOnClient';
import usePrevPathPreservation from '@/hooks/usePrevPathPreservation';
import { useSetExplicitSearchParams } from '@/hooks/useSetExplicitSearchParams';
import { useInitUplift } from '@/infra/uplift';
import WithChatbot from '@/layouts/Wrappers/Chatbot';
import { type RootState, useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import { type TLayoutProps } from './types';

const PROMO_BANNERS_UPDATE_INTERVAL = 900; // In seconds
const STRIKETHROUGH_UPDATE_INTERVAL = 900; // In seconds

const Initializers = ({ children }: TLayoutProps) => {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const routeConfig = useRouteConfig();

  useFiltersValuesInit();
  useSetExplicitSearchParams();

  useRouteChangeObserver();
  useOnceOnClient(initLogger);
  useCatchAsyncError();

  useEffect(() => {
    tagmanager.tracker.anyBookFunnel.trackPageOpen(pathname!);
  }, [pathname]);

  useEffect(() => {
    googleTagManagerCode();
    toBlobPolyfill();

    dispatch(checkAllProgramKeys());
  }, []);

  useStorePersistentAligners();
  useInitUplift();
  usePrevPathPreservation();
  useInitAccountDropdownEvents();
  usePromoBannerUpdater({
    isEnabled: !!routeConfig?.bannerPlaces?.length,
    repeatInterval: PROMO_BANNERS_UPDATE_INTERVAL,
  });
  useStrikethroughUpdater({
    isEnabled: true,
    repeatInterval: STRIKETHROUGH_UPDATE_INTERVAL,
  });

  return children;
};

const useUpdateAgentDetails = (action?: string) => {
  const agentData = useAppSelector(selectAgentData);
  const lookup = useAppSelector(selectLookup);
  useEffect(() => {
    const { agentDetails } = agentData || {};
    if (action === AgentDataActionType.remove) {
      removeAgencyData();
      removeAgencySearchParams();
    } else if (action !== AgentDataActionType.exist && !isEmpty(agentDetails)) {
      removeAgencyData();
      const { bookingChannel, isValid } = agentDetails || {};
      if (isValid && bookingChannel === BookingChannelTypes.fmLink) {
        setAgencyData({ agentData, common: { lookup } } as RootState);
      }
    }
  }, []);
};

type TProps = TLayoutProps & {
  agentAction?: string;
  campaignAndLegal: TCampaignAndLegal;
};

const LateProviders = ({ agentAction, campaignAndLegal, children }: TProps) => {
  useIosTextFieldFocusZoomPatch();

  useUpdateAgentDetails(agentAction);

  return (
    <>
      <ApplyPromoColors />
      <FormattedMessageProvider>
        <CMSContextProvider {...campaignAndLegal}>
          <WithChatbot>
            <Initializers>{children}</Initializers>
          </WithChatbot>
        </CMSContextProvider>
      </FormattedMessageProvider>
    </>
  );
};

export default LateProviders;
