const removeSearchParamsNative = (params: string[]) => {
  const url = new URL(window.location);
  const { searchParams } = url;
  let updated = false;
  params.forEach((key) => {
    if (searchParams.has(key)) {
      updated = true;
      searchParams.delete(key);
    }
  });
  if (updated) history.replaceState(null, '', url);
};

export default removeSearchParamsNative;
