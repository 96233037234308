import { useCallback, useMemo, useRef, useState } from 'react';

import {
  autoUpdate,
  offset,
  type Placement,
  type ReferenceType,
  useClick,
  useDismiss,
  useFloating,
  type UseFloatingOptions,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react';

import type { TKeyEventGroup } from '@/ducks/a11y/types';

import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';

import type { OnChangeStatusHandler, OnChangeStatusHandlerOptions } from './types';

type UseRefinementPopoverProps = {
  buttonRef?: React.RefObject<HTMLElement>;
  dismissEnabled?: boolean;
  focusTrapDisableEvents?: TKeyEventGroup[];
  isInline?: boolean;
  isMobile?: boolean;
  onChangeStatus?: OnChangeStatusHandler;
  outsidePress?: boolean;
  placement?: Placement;
};

const changeClasses = (isOpen: boolean) => {
  document.body.classList.toggle('-refinementOpen', isOpen);
  const primaryContainer = document.getElementById('primaryContainer');
  primaryContainer?.classList.toggle('lightBorder', isOpen);
};

const useGetRefinementPopoverProps = (props: UseRefinementPopoverProps) => {
  const closePopoverPayloadRef = useRef<null | OnChangeStatusHandlerOptions>(null);
  const {
    buttonRef,
    dismissEnabled = true,
    focusTrapDisableEvents,
    isInline,
    isMobile,
    onChangeStatus,
    outsidePress,
    placement,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      changeClasses(isOpen);

      if (!isOpen && buttonRef?.current) {
        (buttonRef.current as HTMLElement).focus();
        scrollIntoViewAsync('.filter-restore-anchor', buttonRef, { block: 'nearest' }, 300);
      } else if (isOpen && !isMobile) {
        scrollIntoViewAsync('.filter-popup', undefined, { block: 'nearest' }, 300);
      }

      onChangeStatus?.(isOpen, {
        closedByBackButton: !isOpen && closePopoverPayloadRef.current?.closedByBackButton === true,
      });

      closePopoverPayloadRef.current = null;
    },
    [isMobile, onChangeStatus, buttonRef],
  );

  const floatingProps: UseFloatingOptions<ReferenceType> = useMemo(() => {
    return isMobile
      ? {
          onOpenChange,
          open: isOpen,
          strategy: 'fixed',
          transform: false,
        }
      : {
          elements: {
            reference: buttonRef?.current,
          },
          middleware: [offset(7)],
          onOpenChange,
          open: isOpen,
          placement,
          whileElementsMounted: autoUpdate,
        };
  }, [placement, isMobile, isOpen, buttonRef, onOpenChange]);

  const { context, floatingStyles, refs } = useFloating(floatingProps);

  const click = useClick(context);
  const dismiss = useDismiss(context, { enabled: dismissEnabled, outsidePress });
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);
  const { status } = useTransitionStatus(context);

  const customFloatingStyles = useMemo(
    () => (isMobile ? { ...floatingStyles, left: '0', top: '0' } : floatingStyles),
    [isMobile, floatingStyles],
  );

  return {
    buttonRef,
    closePopover: ({ event, payload }: { event?: React.UIEvent; payload?: OnChangeStatusHandlerOptions } = {}) => {
      closePopoverPayloadRef.current = payload || null;
      context.onOpenChange(false, event as unknown as Event, 'click');
    },
    context,
    disabled: false,
    floatingStyles: customFloatingStyles,
    focusTrapDisableEvents,
    getFloatingProps,
    getReferenceProps,
    isInline,
    isOpen,
    openPopover: ({ event }: { event?: React.UIEvent; payload?: OnChangeStatusHandlerOptions } = {}) => {
      context.onOpenChange(true, event as unknown as Event, 'click');
    },
    refs,
    setIsOpen,
    status,
  };
};

export type RefinementPopoverProps = ReturnType<typeof useGetRefinementPopoverProps>;

export default useGetRefinementPopoverProps;
