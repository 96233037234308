import { PAYMENT_SET_TRANSACTION_DATA } from '@/constants/actionTypes';
import { routes } from '@/ducks/routes';
import { setCompletedFlow } from '@/ducks/travelParty/slice';

export const onContinueToConfirmationAction = (isLoggedIn, transactionData) => (dispatch) => {
  dispatch({
    payload: transactionData,
    type: PAYMENT_SET_TRANSACTION_DATA,
  });

  dispatch(setCompletedFlow());

  if (isLoggedIn) {
    routes.planner.confirmation.replace({ keepSearch: true });
  } else {
    routes.planner.payment.connect.go({ keepSearch: true });
  }
};

export default onContinueToConfirmationAction;
