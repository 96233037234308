// correlates with _variables.scss
export const SKELETON_COLOR_SCHEMES = {
  ['gray-216-gray-100']: {
    baseColor: '#f8f9fa',
    highlightColor: '#d8d8d8',
  },
  ['gray-216-gray-410']: {
    baseColor: '#d8d8d8',
    highlightColor: '#b7b7b7',
  },
};
