/*
 * Duck for storing filters options: min/max dates,
 * regions, classifications, packages etc.
 */
import { isValid, lastDayOfMonth, max, min } from 'date-fns';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import {
  CLEAR_MULTICURRENCIES,
  CONFIGURATION_SERVICE_SUCCESS,
  GET_VOYAGE_SEARCH_DATA_SUCCESS,
  SET_DEFAULT_PRICE_RANGE,
} from '@/constants/actionTypes';
import { format, parse } from '@/helpers/util/dateUtil';
import { getSessionStorageValue } from '@/helpers/util/storage';
import { FiltersPriceType, FiltersSortType } from '@/infra/types/common/filters';

// Constants

export const defaultState = {
  cabinType: null,
  cabinTypes: [],
  classifications: [],
  currencyCode: 'USD',
  defaultDurationAnimation: 'large',
  defaultEndDate: null,
  defaultFilters: {
    accessible: false,
    agencyId: null,
    agentId: null,
    cabins: 1,
    cabinType: null,
    chooseCabinSort: 'price-asc',
    currencyCode: 'USD',
    durations: [],
    fromDate: undefined,
    homePorts: [],
    initialized: false,
    maxPrice: null,
    minPrice: null,
    nodes: [],
    packages: [],
    ports: [],
    priceType: FiltersPriceType.perCabin,
    promotionalVoyages: [],
    resetAll: false,
    sailors: 2,
    selectedRegions: [
      {
        name: 'Caribbean',
        packages: [],
        ports: [],
        regionId: 'CARIBBEAN',
      },
      {
        name: 'EUROPE',
        packages: [],
        ports: [],
        regionId: 'EUROPE',
      },
      {
        name: 'South Pacific',
        packages: [],
        ports: [],
        regionId: 'SOUTH PACIFIC',
      },
      {
        name: 'Transatlantic and More',
        packages: [],
        ports: [],
        regionId: 'TRANSATLANTIC',
      },
    ],
    selectedRegionsIds: [],
    shipList: [],
    sortType: FiltersSortType.date,
    toDate: undefined,
    weekend: false,
  },
  defaultStartDate: null,
  durationOptions: [],
  durations: [],
  homePorts: [],
  loaded: false,
  maxCabinCount: 1,
  maxCabinOccupancy: 4,
  maxDate: null,
  // configService properties
  minCabinCount: 1,
  minCabinOccupancy: 1,
  minDate: null,
  multiCurrencies: [],
  packages: {},
  regions: [],
  shipList: [],
  tacticalRates: [],
};

// Helpers
export const supplementDateFilters = (data) => {
  // Choose min of server's minDate and now()
  let minDate = max([parse(data.minDate, true), new Date()].filter(Boolean));
  const maxDate = parse(data.maxDate, true);

  if (!isValid(minDate)) {
    minDate = getSessionStorageValue('defaultStartDate');
  }

  // Choose between server's defaultEndDate and maxDate
  let defaultEndDate = min([lastDayOfMonth(parse(data.defaultEndDate, true)), maxDate].filter(Boolean));

  if (!isValid(defaultEndDate)) {
    defaultEndDate = getSessionStorageValue('defaultEndDate');
  }

  return {
    fromDate: format(minDate),
    minDate: format(minDate),
    toDate: format(defaultEndDate),
  };
};

export const supplementRegionPorts = (regions) =>
  regions.map((region) => ({
    ...region,
    ports: region.ports.map((port) => ({ ...port, id: port.code })),
  }));

// Reducers
export default function filtersOptionsReducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_MULTICURRENCIES:
      return {
        ...state,
        multiCurrencies: [],
      };
    // settings API request; pick certain properties meaningful for filters
    case CONFIGURATION_SERVICE_SUCCESS: {
      const urlParams = new URLSearchParams(window.location.search);
      const isMNVV = !!(urlParams.get('reservationNumber') && urlParams.get('channelID'));
      return {
        ...state,
        allowedCurrencies: action.payload.allowedCurrencies,
        liveChatOptions: action.payload.liveChat,
        ...mapValues(
          // Cast API numeric values to numbers
          pick(action.payload, ['maxCabinCount', 'maxCabinOccupancy', 'minCabinCount', 'minCabinOccupancy']),
          Number.parseInt,
        ),
        ...(isMNVV && { minCabinOccupancy: 2 }),
        defaultDurationAnimation: action.payload.filters.defaultDurationAnimation,
        defaultFilters: {
          ...state.defaultFilters,
          accessible: action.payload.isCabinAccessible,
          cabins: Number.parseInt(action.payload.defaultCabinCount, 10),
          currencyCode: action.payload.defaultCurrencyCode,
          priceType: action.payload.pricingType,
          sailors: Number.parseInt(action.payload.defaultSailorCount, 10),
          supplementDurations: action.payload.filters.duration,
        },
      };
    }
    // voyageSearch API request
    case GET_VOYAGE_SEARCH_DATA_SUCCESS: {
      const dates = supplementDateFilters(action.payload);
      return {
        ...state,
        ...action.payload,
        defaultFilters: {
          ...state.defaultFilters,
          fromDate: dates.fromDate,
          toDate: dates.toDate,
        },
        durationOptions: state.defaultFilters.supplementDurations,
        loaded: true,
        minDate: dates.minDate,
        packages: keyBy(action.payload.packages, 'packageCode'),
        regions: supplementRegionPorts(action.payload.regions),
      };
    }
    case SET_DEFAULT_PRICE_RANGE:
      return {
        ...state,
        defaultFilters: {
          ...state.defaultFilters,
          maxPrice: action.payload?.maxPrice,
          minPrice: action.payload?.minPrice,
        },
      };
    default: {
      return state;
    }
  }
}
