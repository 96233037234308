import type { TrackingEvent } from '@/tagmanager/types';

export const getDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

export const getLastTrackingEvent = () => {
  const dataLayer = getDataLayer();

  return dataLayer[dataLayer.length - 1];
};

export const trackEvent = (data: TrackingEvent) => {
  if (!data?.event) {
    console.warn('Tracked data does not contain event field');
  }
  getDataLayer().push(data);
};

export const trackData = (data: TrackingEvent) => {
  getDataLayer().push(data);
};

export const clearDataLayer = () => {
  window.dataLayer = [];
};
