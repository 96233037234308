import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

import {
  SUMMARY_CALCULATE_INVOICE_CLEAR,
  SUMMARY_CALCULATE_INVOICE_ERROR,
  SUMMARY_CALCULATE_INVOICE_START,
  SUMMARY_CALCULATE_INVOICE_SUCCESS,
} from '@/constants/actionTypes';
import { throwError } from '@/ducks/common';
import { selectConfigServiceFeatureCelebrations } from '@/ducks/common/settings';
import { selectSailors } from '@/ducks/filters/selectors';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { routes } from '@/ducks/routes';
import { getPathname } from '@/ducks/routes/history';
import { clearChoseCategories } from '@/ducks/voyageInfo/category/actions';
import { toSubCategoryPage } from '@/ducks/voyageInfo/category/navigation';
import { selectChosenCategory } from '@/ducks/voyageInfo/category/selectors';
import { fetchUserProfile } from '@/features/profile/actions';
import { fetchCaluclateInvoiceData } from '@/helpers/api/app';
import { HttpError } from '@/helpers/api/utils';
import { extractErrorMessage } from '@/helpers/extractErrorMessage';
import { getCabinInvoiceNumber } from '@/helpers/selectors';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util';
import { CalculateInvoiceFailureCode } from '@/infra/types/api/common';
import { selectCabinCategoryBySubCategoryCode } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/selectors';

export const clearInvoiceDataAction = () => (dispatch) => {
  dispatch({ type: SUMMARY_CALCULATE_INVOICE_CLEAR });
};

export const wrapMessage = (message /* ?:string */) /* :({ message: string } | undefined) */ =>
  message ? { message } : undefined;

export const calculateInvoiceErrorAction = (err) => async (dispatch) => {
  dispatch({
    payload: isPlainObject(err) ? err : wrapMessage(extractErrorMessage(err)) || {},
    type: SUMMARY_CALCULATE_INVOICE_ERROR,
  });
};

export const fetchCalculateInvoiceDataAction = (urlParams) => async (dispatch, getState) => {
  let data = {};
  const state = getState();

  try {
    dispatch(clearInvoiceDataAction());
    dispatch({
      type: SUMMARY_CALCULATE_INVOICE_START,
    });

    urlParams.sailors = selectSailors(state);

    const promoCode = urlParams?.accessKey;

    const pathname = urlParams?.pathname;
    let externalRefId = urlParams?.externalRefId;
    if (!externalRefId) {
      const userDetails = localStorage.getItem('Authorization');
      if (!isEmpty(userDetails)) {
        const details = await dispatch(fetchUserProfile());
        externalRefId = details?.externalRefId;
      }
    }
    const isRouteFetch = [routes.planner.confirmation.path, routes.planner.payment.path].includes(pathname);
    const sessionInvoice = getSessionStorageValue('invoiceData');
    if (isRouteFetch && !isEmpty(sessionInvoice)) {
      data = sessionInvoice;
    } else if (!isEmpty(urlParams) && urlParams?.voyageId) {
      setSessionStorageValue('invoiceData', {});
      setSessionStorageValue('cabinNumber', '');
      data = await fetchCaluclateInvoiceData({ ...urlParams, externalRefId, promoCode });
    }

    if (data.isSellLimitExceeded || data.isSoldOut) {
      const subCategoryCode = data.cabinInvoices?.[0].cabinTypeCode;
      const categoryCode =
        selectChosenCategory(state)?.code || selectCabinCategoryBySubCategoryCode(state, subCategoryCode)?.code;

      dispatch(
        calculateInvoiceErrorAction({
          categoryCode,
          isSellLimitExceeded: data.isSellLimitExceeded,
          isSoldOut: data.isSoldOut,
          message: '',
          subCategoryCode,
        }),
      );

      // If referred booker flow, have to stay on summary page and display modal window
      // instead of silent redirect

      if (selectConfigServiceFeatureCelebrations(state) && state.celebrations) {
        const { params } = state.celebrations;
        if (params.referralCode !== null) {
          return data;
        }
      }
      const { isReferredBooker } = selectMgm(state);
      if (isReferredBooker) {
        return data;
      }

      dispatch(clearChoseCategories());
      toSubCategoryPage(categoryCode);
      return;
    }

    dispatch({
      payload: data,
      type: SUMMARY_CALCULATE_INVOICE_SUCCESS,
    });
    setSessionStorageValue('invoiceData', data);
    const cabinNumber = getCabinInvoiceNumber(data);
    setSessionStorageValue('cabinNumber', cabinNumber);
  } catch (err) {
    const { code } = err.responseBody || {};

    // The logic of page navigation in case of error is ONLY if we are on these pages
    const canNavigate = [routes.planner.payment.path, routes.planner.summary.path].includes(getPathname());
    if (err instanceof HttpError) {
      if (canNavigate && code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_NOT_PERMITTED) {
        routes.planner.dnsError.go();
        return;
      }
      if (
        code !== CalculateInvoiceFailureCode.SEAWEAR_SAILOR_WRONG_CAPACITY &&
        ([500, 502].includes(err.status) || code === 'INTERNAL_SERVER_ERROR')
      ) {
        dispatch(throwError(err));
        return;
      }
    }

    dispatch(calculateInvoiceErrorAction(err));

    if (code === CalculateInvoiceFailureCode.SEAWEAR_SAILOR_WRONG_CAPACITY) {
      toSubCategoryPage();
      return;
    }

    if (canNavigate) {
      const chosenCategoryCode = selectChosenCategory(state)?.code;
      dispatch(clearChoseCategories());
      toSubCategoryPage(chosenCategoryCode);
    }
  }
  return data;
};

export default fetchCalculateInvoiceDataAction;
