import isEmpty from 'lodash/isEmpty';

import type { LookupSlice } from '@/ducks/common/lookup/types';
import type { SettingsSlice } from '@/ducks/common/settings';
import type { MnvvData } from '@/ducks/mnvv/types';
import type { GQLClientInfoLookup } from '@/helpers/api/graphql';
import type { CruiseCommonSlice, TStoreVoyageSearch, VoyagesDataResponse } from '@/infra/types/voyageInfo/package';
import type { RootState } from '@/store';

import { initialCruisesCommonState } from '@/ducks/cruisesCommon/slice';
import { calculateAdditonalVoyagesData } from '@/ducks/cruisesCommon/utils';
import { getFilterValues } from '@/ducks/filters/utils';
import { populateDefaultFilter } from '@/ducks/filtersOptions/helpers';
import { type TPackagesAndSailings } from '@/ducks/pages/chooseVoyage/actions/fetchPackages';
import { defaultState as chooseVoyageDefaultState } from '@/ducks/pages/chooseVoyage/chooseVoyageNew';
import { groupPackagesWithSameName } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';
import { initialState as promoBannersInitialState, type TStorePromoBanners } from '@/features/promoBanners/slice';
import { initialState as strikethroughInitialState, type TStoreStrikethrough } from '@/features/strikethrough/slice';
import { type TFetchAgentDetails } from '@/helpers/api/resources';
import { getCookie } from '@/helpers/ssr/cookies';
import { type TFiltersOptions } from '@/infra/types/common/filters';
import { type TAgencyAgentResponse } from '@/infra/types/fmlink/agent';
import { type TFetchableData } from '@/store/fetchableParts/types';
import { type TOptional } from '@/types/common';

const buildInitialState = (args: TFetchableData, searchParams: URLSearchParams, prevState?: RootState): RootState => {
  const initialState = {
    ...prevState,
    agentData: mergeAgentData(args.agentAgencyDetails, prevState),
    chooseVoyageNew: mergeChooseVoyageNew(args.packagesAndSailings, prevState),
    common: {
      ...prevState?.common,
      clientIp: args.clientIp ?? prevState?.common?.clientIp,
      geoLocation: args.geoLocation ?? prevState?.common?.geoLocation,
      lookup: mergeLookup(args.lookup, args.clientInfo, prevState) ?? ({} as LookupSlice),
      resources: args.resources ?? prevState?.common?.resources,
      settings: mergeSettings(args.settings, args.mnvvData, prevState) ?? ({} as SettingsSlice),
    },
    cruisesCommon:
      mergeCruisesCommon(args.voyagesData, prevState) ?? ({ ...initialCruisesCommonState } as CruiseCommonSlice),
    filtersOptions: mergeFiltersOptions(args, searchParams, prevState),
    mgm: {
      ...prevState?.mgm,
      isMgm: mergeIsMgm(args.settings, prevState),
    },
    mnvvData: args.mnvvData ?? prevState?.mnvvData,
    promoBanners: mergePromoBanners(args.promoBanners, prevState),
    quickSearchItems: args.quickSearch ?? prevState?.quickSearchItems,
    strikethrough: mergeStrikethrough(args.strikethrough, prevState),
    voyagePlanner: {
      ...prevState?.voyagePlanner,
      itineraryResults: {
        ...prevState?.voyagePlanner?.itineraryResults,
        voyageSearch: mergeVoyageSearch(args.voyagesData, prevState),
      },
    },
  } as unknown as RootState;

  return {
    ...initialState,
    filters: getFilterValues({ getCookie, searchParams, state: initialState }, true),
  };
};

const mergeAgentData = (
  agentAgencyDetails: TOptional<TFetchAgentDetails>,
  prevState: TOptional<RootState>,
): TOptional<TAgencyAgentResponse> => agentAgencyDetails?.response ?? prevState?.agentData;

const mergeChooseVoyageNew = (
  packagesAndSailings: TOptional<TPackagesAndSailings>,
  prevState: TOptional<RootState>,
): TOptional<TPackagesAndSailings> => {
  if (packagesAndSailings) {
    const { filteredPackages, mainPackages, ...genericCategories } = packagesAndSailings;
    return {
      ...(prevState?.chooseVoyageNew ?? chooseVoyageDefaultState),
      ...genericCategories,
      filteredPackages: { ...filteredPackages, isLoaded: true },
      isLoading: false,
      mainPackages: {
        ...mainPackages,
        extendedPackages: groupPackagesWithSameName(mainPackages?.packages),
        isLoaded: true,
      },
    };
  }
  return prevState?.chooseVoyageNew;
};

const mergeCruisesCommon = (
  voyagesData: TOptional<VoyagesDataResponse>,
  prevState: TOptional<RootState>,
): TOptional<CruiseCommonSlice> =>
  voyagesData
    ? {
        ...initialCruisesCommonState,
        voyagesData,
        ...calculateAdditonalVoyagesData(voyagesData),
      }
    : prevState?.cruisesCommon;

const mergeFiltersOptions = (
  args: TFetchableData,
  searchParams: URLSearchParams,
  prevState: TOptional<RootState>,
): TOptional<TFiltersOptions> =>
  args.lookup || args.settings || args.voyagesData || !prevState?.filtersOptions
    ? {
        ...prevState?.filtersOptions,
        ...populateDefaultFilter({
          lookup: mergeLookup(args.lookup, args.clientInfo, prevState) ?? ({} as LookupSlice),
          searchParams,
          settings: mergeSettings(args.settings, args.mnvvData, prevState) ?? ({} as SettingsSlice),
          voyagesData:
            args.voyagesData ??
            prevState?.voyagePlanner?.itineraryResults?.voyageSearch?.data ??
            ({} as VoyagesDataResponse),
        }),
        multiCurrencies:
          (args.agentAgencyDetails?.response ?? prevState?.agentData)?.agencyDetails?.multiCurrencies ??
          prevState?.filtersOptions?.multiCurrencies ??
          [],
      }
    : prevState?.filtersOptions;

const mergeIsMgm = (settings: TOptional<SettingsSlice>, prevState: TOptional<RootState>): TOptional<boolean> =>
  settings ? settings?.memberGetMember : prevState?.mgm?.isMgm;

const mergeLookup = (
  lookup: TOptional<LookupSlice>,
  clientInfo: TOptional<GQLClientInfoLookup>,
  prevState: TOptional<RootState>,
): TOptional<LookupSlice> =>
  clientInfo ? { ...(lookup ?? prevState?.common?.lookup)!, ...clientInfo } : (lookup ?? prevState?.common?.lookup);

const mergePromoBanners = (
  promoBanners: TOptional<TStorePromoBanners>,
  prevState: TOptional<RootState>,
): TOptional<TStorePromoBanners> =>
  promoBanners ? { ...promoBannersInitialState, isLoaded: true, ...promoBanners } : prevState?.promoBanners;

const mergeSettings = (
  settings: TOptional<SettingsSlice>,
  mnvvData: TOptional<MnvvData>,
  prevState: TOptional<RootState>,
): TOptional<SettingsSlice> =>
  settings
    ? { ...settings, ...(!isEmpty(mnvvData ?? prevState?.mnvvData) ? { minCabinOccupancy: 2 } : undefined) }
    : prevState?.common?.settings;

const mergeStrikethrough = (
  strikethrough: TOptional<TStoreStrikethrough>,
  prevState: TOptional<RootState>,
): TOptional<TStoreStrikethrough> =>
  strikethrough ? { ...strikethroughInitialState, isLoaded: true, ...strikethrough } : prevState?.strikethrough;

const mergeVoyageSearch = (
  voyagesData: TOptional<VoyagesDataResponse>,
  prevState: TOptional<RootState>,
): TOptional<TStoreVoyageSearch> =>
  voyagesData
    ? { data: voyagesData, error: {}, loading: false }
    : (prevState?.voyagePlanner?.itineraryResults?.voyageSearch as TStoreVoyageSearch);

export default buildInitialState;
