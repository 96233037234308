import React from 'react';

import { selectShipsLabel } from '@/ducks/filters/labels/ships';
import { selectShipList } from '@/ducks/filters/selectors';
import { FILTER_KEY } from '@/ducks/filters/types';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const PortFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const ships = useAppSelector(selectShipList);
  const label = useAppSelector(selectShipsLabel);

  return ships?.length > 0 ? (
    <SelectedFilterChip filterKeys={FILTER_KEY.ships} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default PortFilterChip;
