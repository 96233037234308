import React, { useCallback, useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import type { NullablePartial } from '@/types';

import { Skeleton } from '@/components/Skeleton';
import UIResource from '@/components/UIResource';
import { type TUIResourceId, useUIResource, useUIResourcePool } from '@/ducks/common/resources';
import { selectDefaultPriceRange } from '@/ducks/filters/defaultValuesSelectors';
import { useOnUpdateFiltersValues } from '@/ducks/filters/hooks';
import { getPricesForLabel, selectPriceLabel, selectPricesPreparedForLabel } from '@/ducks/filters/labels/price';
import { selectCurrencyCode, selectPriceType } from '@/ducks/filters/selectors';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY, type FiltersTypes } from '@/ducks/filters/types';
import { selectFilteredPackages } from '@/ducks/pages/chooseVoyage/selectors';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { checkIfMobileOS } from '@/helpers/userAgent/getOSName';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import type { BaseFilterProps } from '../types';

import { BoxCheckbox, TextField } from '../../elements';
import AdvancedFilterHOC from '../AdvancedFilterHOC';
import useGetBaseFilterProps from '../useGetBaseFilterProps';

export const INPUT_NUM_REGEXP = /^[0-9]+$/;

const resousrsesIds: Record<string, TUIResourceId> = {
  pricePerCabinLabel: 'AdvancedFilter.priceType.label.cabin',
  pricePerSailorLabel: 'AdvancedFilter.priceType.label.sailor',
  sailorPerNightLabel: 'AdvancedFilter.priceType.label.sailorPerNight',
};

const skeletonLabel = <Skeleton width={100} />;

type PriceRangeFilterProps = BaseFilterProps;

const PriceRangeFilter = (props: PriceRangeFilterProps) => {
  const priceMinInput = useRef<HTMLInputElement | null>(null);
  const priceMaxInput = useRef<HTMLInputElement | null>(null);
  const [isMobileOS, setIsMobileOS] = useState(false);

  useEffect(() => {
    if (checkIfMobileOS()) {
      setIsMobileOS(true);
    }
  }, []);

  const { closeFilterModal, isOpenFilterSection } = props;
  const dispatch = useAppDispatch();

  const { toggleFilterSectionState } = useGetBaseFilterProps(props);

  const appliedCurrency = useAppSelector(selectCurrencyCode);
  const defaultPriceRange = useAppSelector(selectDefaultPriceRange);
  const filteredPackages = useAppSelector(selectFilteredPackages);
  const isPackagesLoading = useAppSelector(selectIsFilteringLoading);
  const label = useAppSelector(selectPriceLabel);
  const prices = useAppSelector(selectPricesPreparedForLabel);

  const [priceType, setPriceType] = useState(useAppSelector(selectPriceType));
  const [priceMinFocused, setPriceMinFocused] = useState(false);
  const [priceMaxFocused, setPriceMaxFocused] = useState(false);

  const updateValues = useCallback(
    (values: NullablePartial<FiltersTypes>) => {
      const { priceMax, priceMin } = getPricesForLabel(defaultPriceRange, values.priceMax!, values.priceMin!);

      if (priceMinInput?.current && !priceMinFocused) {
        priceMinInput.current.value = priceMin.toString();
      }

      if (priceMaxInput?.current && !priceMaxFocused) {
        priceMaxInput.current.value = priceMax.toString();
      }

      setPriceType(values.priceType!);
    },
    [defaultPriceRange, priceMinFocused, priceMaxFocused],
  );

  useOnUpdateFiltersValues([FILTER_KEY.priceType], updateValues);

  const strings = useUIResourcePool(resousrsesIds);
  const filterName = useUIResource('AdvancedFilter.PriceRange', {
    n: getSymbolFromCurrenciesData(appliedCurrency),
  });

  const priceTypeChangeHandler = (newPriceType: FiltersPriceType) => {
    if (newPriceType !== priceType || filteredPackages.length === 0) {
      setFilters({
        [FILTER_KEY.priceMax]: null,
        [FILTER_KEY.priceMin]: null,
        [FILTER_KEY.priceType]: newPriceType,
      });
      setPriceType(newPriceType);
      dispatch(
        tracking.trackPriceType({
          module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS,
          priceType: newPriceType,
        }),
      );
    }
  };

  const onPriceChange = debounce(
    (type: 'max' | 'min', value: string) => {
      if (value && !INPUT_NUM_REGEXP.test(value)) {
        return;
      }

      const numericValue = parseInt(value, 10);

      const maxPrice = parseInt(priceMaxInput.current?.value || '0', 10);
      const minPrice = parseInt(priceMinInput.current?.value || '0', 10);

      const newPriceRange =
        type === 'min'
          ? {
              [FILTER_KEY.priceMax]: maxPrice || defaultPriceRange.maxPrice,
              [FILTER_KEY.priceMin]: numericValue || 0,
            }
          : {
              [FILTER_KEY.priceMax]: numericValue || 0,
              [FILTER_KEY.priceMin]: minPrice || defaultPriceRange.minPrice,
            };

      setFilters(newPriceRange);
      dispatch(tracking.trackPriceRange(newPriceRange));
    },
    // Increase debounce time to improve UX for mobile devices. Keyboard on mobiles are not so convenient as on desktop
    isMobileOS ? 600 : 300,
  );

  const onMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPriceChange('max', event.target.value);
  };

  const onMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPriceChange('min', event.target.value);
  };

  const onBlurPriceMin = (event: React.FocusEvent<HTMLInputElement>) => {
    setPriceMinFocused(false);
    if (event.target.value.trim() === '') {
      event.target.value = '0';
    }
  };
  const onBlurPriceMax = (event: React.FocusEvent<HTMLInputElement>) => {
    setPriceMaxFocused(false);
    if (event.target.value.trim() === '') {
      event.target.value = '0';
    }
  };
  const onFocusPriceMin = () => setPriceMinFocused(true);
  const onFocusPriceMax = () => setPriceMaxFocused(true);

  const filterLabel = isPackagesLoading ? skeletonLabel : label;

  return (
    <AdvancedFilterHOC
      className="PriceRangeFilter__main"
      closeFilterModal={closeFilterModal}
      defaultValue={filterLabel as string}
      filterName={filterName}
      isOpen={isOpenFilterSection}
      toggleFilterSectionState={toggleFilterSectionState}
    >
      <div className="action priceRange">
        <div className="row">
          <div className="minPrice">
            <TextField
              defaultValue={prices.priceMin}
              getRef={(ref) => (priceMinInput.current = ref)}
              id="minPrice"
              label={<UIResource id="AdvancedFilter.PriceRange.min.title" />}
              labelClassname={'minPrice__label'}
              name="minPrice"
              onBlur={onBlurPriceMin}
              onChange={onMinPriceChange}
              onFocus={onFocusPriceMin}
              pattern={INPUT_NUM_REGEXP.source}
              tabIndex={0}
            />
          </div>
          <p className="to">
            <UIResource id="AdvancedFilter.PriceRange.to" />
          </p>
          <div className="maxPrice">
            <TextField
              defaultValue={prices.priceMax}
              getRef={(ref) => (priceMaxInput.current = ref)}
              id="maxPrice"
              label={<UIResource id="AdvancedFilter.PriceRange.max.title" />}
              labelClassname={'maxPrice__label'}
              name="maxPrice"
              onBlur={onBlurPriceMax}
              onChange={onMaxPriceChange}
              onFocus={onFocusPriceMax}
              pattern={INPUT_NUM_REGEXP.source}
              tabIndex={0}
            />
          </div>
          <form hidden id="priceRangeForm">
            <button
              onClick={(e) => {
                e.preventDefault();
                toggleFilterSectionState(filterName);
              }}
              tabIndex={-1}
              type="submit"
            />
          </form>
        </div>
        <div className="priceperDiv">
          <UIResource id="AdvancedFilter.priceType.Priceper" />
        </div>
        <div className="perSailorDiv">
          <BoxCheckbox
            checked={priceType === FiltersPriceType.perCabin}
            name={strings.pricePerCabinLabel}
            onBoxClick={() => priceTypeChangeHandler(FiltersPriceType.perCabin)}
          />
          <BoxCheckbox
            checked={priceType === FiltersPriceType.perSailor}
            name={strings.pricePerSailorLabel}
            onBoxClick={() => priceTypeChangeHandler(FiltersPriceType.perSailor)}
          />
          <BoxCheckbox
            checked={priceType === FiltersPriceType.sailorPerNight}
            name={strings.sailorPerNightLabel}
            onBoxClick={() => priceTypeChangeHandler(FiltersPriceType.sailorPerNight)}
          />
        </div>
      </div>
    </AdvancedFilterHOC>
  );
};

export default PriceRangeFilter;
