'use client';

import { usePathname } from 'next/navigation';

import FMAgencyDetail from '@/components/FMAgencyDetail';
import { BookingChannelTypes } from '@/constants/settings';
import { useUIResourcePool } from '@/ducks/common/resources';
import { selectAgentDetails } from '@/ducks/fm/selectors';
import { routes } from '@/ducks/routes';
import useSsrInitialValues from '@/hooks/useSsrInitialValues';
import { type TAgentDetails } from '@/infra/types/fmlink/agent';
import { useAppSelector } from '@/store';

export const UI_RESOURCE_POOL = {
  agentLabel: 'FMLinking.Banner.Title',
  emailSubject: 'FMLinking.Banner.email.subject',
} as const;

type TProps = {
  ssrInitial?: {
    agentDetails: TAgentDetails;
    pathname: string;
    uiResourcePool: Record<keyof typeof UI_RESOURCE_POOL, string>;
  };
};

const FMBanner = ({ ssrInitial }: TProps) => {
  const { agentDetails, pathname, uiResourcePool } =
    useSsrInitialValues(
      {
        agentDetails: useAppSelector(selectAgentDetails),
        pathname: usePathname()!,
        uiResourcePool: useUIResourcePool(UI_RESOURCE_POOL),
      },
      ssrInitial,
    ) ?? {};
  const { agentLabel, emailSubject } = uiResourcePool ?? {};
  const { agentName, bookingChannel, code, email, isValid, phoneNumber } = agentDetails ?? {};

  const isEnable =
    isValid &&
    bookingChannel === BookingChannelTypes.fmLink &&
    !!agentName &&
    pathname !== routes.planner.dnsError.path;

  return (
    isEnable && (
      <FMAgencyDetail {...{ agentLabel, agentName: agentName!, email, emailSubject, phoneCode: code, phoneNumber }} />
    )
  );
};

export default FMBanner;
