import React from 'react';

import cn from 'classnames';

export type RefinementPopoverHeaderProps = React.PropsWithChildren<{
  className?: string;
  icon?: React.ReactElement;
}>;

import styles from './Header.module.scss';

const RefinementPopoverHeader = ({ children, className, icon }: RefinementPopoverHeaderProps) => {
  return (
    <div className={cn(styles.root, className)}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={cn(styles.title, { [styles.withIcon]: Boolean(icon) })}>{children}</span>
    </div>
  );
};

export default RefinementPopoverHeader;
