import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { type TUrlParams } from '@/infra/types/common';

import { useAppDispatch } from '../';
import { FetchablePartName, type TFetchableResult } from '../fetchableParts/types';
import chooseRelevant from './chooseRelevant';
import { prefillAll, type TPrefillInfo } from './stuff';

const prefillInfo = {} as TPrefillInfo;

const usePrefill = (fetched: TFetchableResult, name: string, urlParams?: TUrlParams): void => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  const _ = useMemo(() => {
    const { relevant, versions } = chooseRelevant(fetched, prefillInfo) ?? {};
    if (relevant) {
      dispatch(
        prefillAll({ fetched: relevant!, prefillInfo: versions!, urlParams: searchParamsAsObject(searchParams) }, name),
      );
      Object.entries(versions!).forEach(([key, value]) => {
        prefillInfo[key as FetchablePartName] = value;
      });
    }
    return undefined;
  }, [urlParams]);
};

export default usePrefill;
