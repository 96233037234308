import { cookies } from '@/ducks/cookies';
import { isServerSide } from '@/helpers/isServerSide';

export const getCookie = <T = string>(name: string) => {
  let value: unknown;
  if (isServerSide()) {
    const { cookies } = require('next/headers');
    value = cookies().get(name)?.value;
  } else {
    value = cookies.get(name, { doNotParse: true });
  }
  try {
    if (value) {
      return JSON.parse(value) as T;
    }
  } catch (e) {
    //
  }

  return value as T;
};
