import { CONFIGURATION_SERVICE_SUCCESS } from '@/constants/actionTypes';

import {
  MGM_CLEAR_DATA,
  MGM_CLEBERATION_CASE_NOERROR,
  MGM_CLEBERATION_CASE_SHOWERROR,
  MGM_EXIT_REFERRED_BOOKER_FLOW,
  MGM_GENERATE_REFERRAL_URL_FAILURE,
  MGM_GENERATE_REFERRAL_URL_START,
  MGM_GENERATE_REFERRAL_URL_SUCCESS,
  MGM_LINK_REFERRAL_CODE_ERROR,
  MGM_LINK_REFERRAL_CODE_SUCCESS,
  MGM_VALIDATE_REFERRAL_CODE_FAILURE,
  MGM_VALIDATE_REFERRAL_CODE_SUCCESS,
} from './actionTypes';

// Default state
const defaultState = {
  // MGM feature flag
  isMgm: false,
  // Set to true if API request for linking between referred and original bookers has failed
  isReferralLinkError: false,
  // true if MGM flag is on and there is validated referralCode and voyageCode
  isReferredBooker: false,
  isShowNotificationError: false,
  // Own (Original Bookings) referral codes and urls info;
  // mapping reservationNumber -> { referralCode, referralUrl }
  referralData: {},
  // Referred Booker flow params
  referredParams: {
    pkgCode: null,
    // present if there is a referralCode in query string, and validation was performed (regardless of its results)
    referralCode: null,
    // ditto; needed on choose voyage page to find referred sailing
    voyageCode: null,
  },
};

// Get basic MGM flags

// Reducers
export default function mgmReducer(state = defaultState, action) {
  switch (action.type) {
    // Store isMgm flag when it's available
    case CONFIGURATION_SERVICE_SUCCESS:
      return { ...state, isMgm: action.payload.memberGetMember };

    case MGM_CLEAR_DATA:
      return {
        ...state,
        referredParams: {},
      };

    case MGM_CLEBERATION_CASE_NOERROR:
      return {
        ...state,
        isShowNotificationError: action.payload,
      };

    case MGM_CLEBERATION_CASE_SHOWERROR:
      return {
        ...state,
        isShowNotificationError: action.payload,
      };
    case MGM_EXIT_REFERRED_BOOKER_FLOW:
      return { ...state, isReferredBooker: false };
    case MGM_GENERATE_REFERRAL_URL_FAILURE:
      return {
        ...state,
        referralData: {
          ...state.referralData,
          [action.payload.reservationNumber]: { loading: false },
        },
      };
    case MGM_GENERATE_REFERRAL_URL_START:
      return {
        ...state,
        referralData: {
          ...state.referralData,
          [action.payload.reservationNumber]: { loading: true },
        },
      };
    case MGM_GENERATE_REFERRAL_URL_SUCCESS:
      return {
        ...state,
        referralData: {
          ...state.referralData,
          [action.payload.reservationNumber]: {
            loading: false,
            referralCode: action.payload.referralCode,
            referralUrl: action.payload.referralUrl,
          },
        },
      };
    case MGM_LINK_REFERRAL_CODE_ERROR:
      return {
        ...state,
        isReferralLinkError: true,
      };
    case MGM_LINK_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        isReferralLinkError: false,
      };
    case MGM_VALIDATE_REFERRAL_CODE_FAILURE:
      return { ...state, isReferredBooker: false, referredParams: action.payload };
    case MGM_VALIDATE_REFERRAL_CODE_SUCCESS:
      return { ...state, isReferredBooker: true, referredParams: action.payload };
    default:
      return state;
  }
}
