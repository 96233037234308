import ShareEmail from '@publicImages/svg/share-email.svg?static';

import FMAgencyTooltip from './Tooltip';

import './FMAgencyDetail.scss';

type TProps = {
  agentLabel?: string;
  agentName: string;
  email?: string;
  emailSubject?: string;
  phoneCode?: string;
  phoneNumber?: string;
};

const FMAgencyDetail = ({ agentLabel, agentName, email, emailSubject, phoneCode, phoneNumber }: TProps) => {
  const mailtoUrl = email ? `mailto:${email}?subject=${emailSubject}` : undefined;
  const phoneUrl = `tel:+${phoneCode} ${phoneNumber}`;
  return (
    <section className="FM__Main">
      <div className="FM__agencyDetail">
        <div className="title">
          <span className="titleWrapper">{`${agentLabel} ${agentName || ''}`}</span>
          <FMAgencyTooltip agentName={agentName} />
        </div>
      </div>
      <div className="FM__agentDetail">
        <div className="personalDetail">
          <p className="email">
            <a
              aria-label="Email"
              data-sharetype="FMEmail"
              href={mailtoUrl}
              rel="noopener noreferrer"
              tabIndex={-1}
              target="_blank"
            >
              <ShareEmail />
            </a>
          </p>
          <p className="name">
            <a aria-label="Email" href={mailtoUrl} rel="noopener noreferrer" tabIndex={-1} target="_blank">
              {` ${agentName || ''}`}
            </a>
          </p>
        </div>
        <p className="phone">
          <a
            aria-label="Email"
            className="phoneLink"
            data-sharetype="FMEmail"
            href={phoneUrl}
            rel="noopener noreferrer"
            tabIndex={-1}
            target="_blank"
          >
            {` +${phoneCode || ''}${phoneNumber || ''}`}
          </a>
        </p>
      </div>
    </section>
  );
};

export default FMAgencyDetail;
