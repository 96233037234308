import { useSearchParams } from 'next/navigation';
import React, { useCallback } from 'react';

import type { RefinementFiltersData } from '@/components/ResultRefinements/RefinementPopover/useRefinementFiltersData';

import UIResource from '@/components/UIResource';
import { selectCruisesCommonVoyagesDataSailingsCount } from '@/ducks/cruisesCommon/selectors';
import { selectTravelDatesLabel } from '@/ducks/filters/labels/dates';
import { selectDestinationsLabel } from '@/ducks/filters/labels/destinations';
import { useAppSelector } from '@/store';

import MobileFilterButton from './MobileFilterButton';

type FilterRefinementMobileViewProps = {
  refinementFiltersData: RefinementFiltersData;
  renderMainFilter: () => void;
  toggleFilterState: (filterName: 'date' | 'destination') => () => void;
};

const FilterRefinementMobileView = ({ refinementFiltersData }: FilterRefinementMobileViewProps) => {
  const searchParams = useSearchParams();
  const sailingsCount = useAppSelector(selectCruisesCommonVoyagesDataSailingsCount);

  const travelDatesLabel = useAppSelector(selectTravelDatesLabel);
  const destinationsLabel = useAppSelector(
    useCallback((state) => selectDestinationsLabel(state, searchParams), [searchParams]),
  );

  const { destinations, travelDates } = refinementFiltersData;

  return (
    <section className="filterRefinementMobileView">
      <div className="filterRefinementMobileView__title">
        <UIResource id="ChooseVoyage.Results.heading" values={{ N: sailingsCount }} />
      </div>
      <div className="filterRefinementMobileView__mainFilter">
        <MobileFilterButton
          label={destinationsLabel}
          onClick={(event) => destinations.openPopover({ event })}
          titleId="DestinationRefinement.Book"
        />
        <MobileFilterButton
          disabled={travelDates.disabled}
          label={travelDatesLabel}
          onClick={(event) => travelDates.openPopover({ event })}
          titleId="DateRefinement.heading"
        />
      </div>
    </section>
  );
};

export default FilterRefinementMobileView;
