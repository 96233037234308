'use client';

import { type ComponentProps } from 'react';

import { PromoRotunda } from '@/features/promo/Rotunda';

import useInitChooseVoyage from '../ChooseVoyage/hooks/useInitChooseVoyage';
import PackageItineraryResults from './PackageItineraryResults';

type TProps = {
  ssrInitial?: ComponentProps<typeof PackageItineraryResults>['ssrInitial'];
};

const ItineraryResults = ({ ssrInitial }: TProps) => {
  useInitChooseVoyage();

  return (
    <>
      <PackageItineraryResults ssrInitial={ssrInitial} />
      <PromoRotunda />
    </>
  );
};

export default ItineraryResults;
