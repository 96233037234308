import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import { connect } from 'react-redux';

import DurationFilter from '@/components/AdvancedFilterRefinement/DurationFilter';
import { selectDurationsLabel } from '@/ducks/filters/labels/durations';
import { selectDurations, selectWeekend } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import {
  getPresentDurationsForPackages,
  selectIsWeekendAvailableForPackages,
} from '@/ducks/pages/chooseVoyage/selectors';
import { getDurationLabel } from '@/helpers/util/sailing';

import './DurationRefinement.scss';

class DurationRefinement extends React.Component {
  onDurationChange = (selectedDuration) => {
    const { appliedDurations, setDurations } = this.props;
    const selected = appliedDurations?.find((duration) => duration.min === selectedDuration.min);

    let durations;
    if (selected) {
      durations = appliedDurations?.filter((duration) => duration.min !== selectedDuration.min);
    } else {
      durations = [...(appliedDurations || []), selectedDuration];
    }

    setDurations(durations);
  };

  onWeekendChange = (event) => {
    const { setWeekend } = this.props;
    setWeekend(event.target.checked || null);
  };

  render() {
    const {
      appliedDurations,
      appliedWeekend,
      closeFilterModal,
      defaultAnimation,
      filteredDurationOptions,
      isOpenFilterSection,
      isWeekendDisabled,
      label,
      toggleFilterState,
    } = this.props;

    return (
      <DurationFilter
        closeFilterModal={closeFilterModal}
        defaultAnimation={defaultAnimation}
        durations={appliedDurations}
        filteredDurationOptions={filteredDurationOptions}
        getDurationLabel={getDurationLabel}
        isOpenFilterSection={isOpenFilterSection}
        isWeekendDisabled={isWeekendDisabled}
        label={label}
        onDurationChange={this.onDurationChange}
        onRenderChange={this.onRenderChange}
        onWeekendChange={this.onWeekendChange}
        toggleFilterState={toggleFilterState}
        weekend={appliedWeekend}
      />
    );
  }
}

DurationRefinement.propTypes = {
  appliedDurations: PropTypes.arrayOf(PropTypes.number).isRequired,
  appliedWeekend: PropTypes.bool.isRequired,
  closeFilterModal: PropTypes.func,
  defaultAnimation: PropTypes.string,
  filteredDurationOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  isWeekendDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labels: PropTypes.objectOf(PropTypes.shape({})),
  setDurations: PropTypes.func.isRequired,
  setWeekend: PropTypes.func.isRequired,
};
DurationRefinement.defaultProps = {
  closeFilterModal: () => {},
  defaultAnimation: 'large',
  isOpenFilterSection: false,
};

const mapStateToProps = (state) => ({
  appliedDurations: selectDurations(state),
  appliedWeekend: selectWeekend(state),
  defaultAnimation: get(state, 'filtersOptions.defaultDurationAnimation', 'large'),
  filteredDurationOptions: getPresentDurationsForPackages(state),
  isWeekendDisabled: !selectIsWeekendAvailableForPackages(state),
  label: selectDurationsLabel(state),
  setDurations: setFilter.durations,
  setWeekend: setFilter.weekend,
});

export default connect(mapStateToProps)(DurationRefinement);
