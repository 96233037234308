'use client';

import { useMemo } from 'react';

import { AppAuthenticator, type AppTokens } from '@/helpers/api/tokens';
import { type TUrlParams } from '@/infra/types/common';
import { type TFetchableResult } from '@/store/fetchableParts/types';
import usePrefill from '@/store/prefill/usePrefill';
import { type TOptional } from '@/types';

export type TTransferToStoreProps = {
  fetched: TFetchableResult;
  name: string;
  urlParams: TOptional<TUrlParams>;
};

export const TransferToStoreClient = ({ fetched, name, urlParams }: TTransferToStoreProps) => {
  usePrefill(fetched, name, urlParams);
  return null;
};

export type TTransferTokensProps = {
  tokens: AppTokens;
};

export const TransferTokens = ({ tokens }: TTransferTokensProps) => {
  const _ = useMemo(() => {
    AppAuthenticator.getInstance().setRawTokens(tokens);
    return undefined;
  }, []);
  return null;
};
