export enum ACCESS_KEY_INTERNAL_ERRORS {
  AK_NOT_FOUND = `AK not found`,
  AK_SF_INVALID_BUT_SW_VALID = `AK invalid for SalesForce but valid for Seaware`,
  AK_SF_VALID_BUT_SW_INVALID = `AK valid for SalesForce but invalid for Seaware`,
  AK_VALIDATION_FAILED = `AK validation failed`,
  AK_RECAPTCHA_FAIL = 'Forbidden resource',
}

export const MAP_ACCESS_KEY_INTERNAL_ERRORS_TO_UI = {
  [ACCESS_KEY_INTERNAL_ERRORS.AK_NOT_FOUND]: 'Validation.notFound.error',
  [ACCESS_KEY_INTERNAL_ERRORS.AK_RECAPTCHA_FAIL]: 'Validation.reCaptcha.error',
  [ACCESS_KEY_INTERNAL_ERRORS.AK_SF_INVALID_BUT_SW_VALID]: 'Validation.notValid.error',
  [ACCESS_KEY_INTERNAL_ERRORS.AK_SF_VALID_BUT_SW_INVALID]: 'Validation.notApplicable.error',
  [ACCESS_KEY_INTERNAL_ERRORS.AK_VALIDATION_FAILED]: 'Validation.notValid.error',
};

export const ACCESS_KEY_PARAM = 'accessKey';
