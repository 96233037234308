import get from 'lodash/get';
import parseInt from 'lodash/parseInt';

export default (voyageId, chosenSubCategoryCode, sailors, cabins, shipCode, currencyCode, isCabinAccessible, zone) => {
  const cabinsArray = [];
  const guestDetails = [];
  const cabinCount = parseInt(cabins);

  for (let i = 1; i <= parseInt(sailors); i += 1) {
    guestDetails.push({
      specialServices: {
        hasOptedForContactForFlights: true,
        hasOptedForTripInsurance: true,
      },
    });
  }
  for (let i = 1; i <= cabinCount; i += 1) {
    cabinsArray.push({
      accessible: isCabinAccessible ? JSON.parse(isCabinAccessible) : false,
      guestDetails,
    });
  }

  return {
    accessible: isCabinAccessible ? JSON.parse(isCabinAccessible) : false,
    cabinAttributes: [`${get(zone, 'zoneId', '')}`],
    guestCount: {
      ageCategory: 'ADULT', // For virgin voyage only adults are allowed
      count: parseInt(sailors),
    },
    selectedSailing: {
      cabinsArray,
      categoryCode: chosenSubCategoryCode,
      currencyCode,
      shipCode,
      voyageId,
    },
  };
};
