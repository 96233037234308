export const LOCK_IT_IN_CABINS = [
  'GTY', // Guarantee
];

export const ADDON_TYPES = {
  VOYAGE_PROTECTION: 'VOYAGE_PROTECTION',
  VOYAGE_PROTECTION_SINGLE_SAILOR_INFO_WARNING: 'VOYAGE_PROTECTION_SINGLE_SAILOR_INFO_WARNING',
} as const;

export const ADDON_STATE_CHANGE_SOURCE = {
  CARD: 'CARD',
  FLYOUT: 'FLYOUT',
} as const;

export const STICKY_LOCK_BLOCK_HEIGHT = 100;
