import { redirect as nextRedirect, RedirectType } from 'next/navigation';

import { isServerSide } from '@/helpers/isServerSide';
import makeAbsUrlByRouteUrl from '@/helpers/url/makeAbsUrlByRouteUrl';
import { type TUrlParams } from '@/infra/types/common';

export type TRedirectOptions = {
  isReplace?: boolean;
  params?: TUrlParams | URLSearchParams;
};

const redirect = (routePath: string, options?: TRedirectOptions) => {
  const { isReplace, params } = options ?? {};
  const url = makeAbsUrlByRouteUrl(routePath, params);
  if (url) {
    if (isServerSide()) nextRedirect(url.toString(), isReplace ? RedirectType.replace : RedirectType.push);
    else if (isReplace) window.location.replace(url);
    else window.location.assign(url);
  }
};

export default redirect;
