import { getRouterKey } from '@/ducks/routes/helpers';

import currencySymbolsList from '../../data/currencySymbolsList';
const getSymbolFromCurrenciesData = (
  currencyCode?: string,
  options?: { fullSymbol?: boolean; onlySymbol?: boolean },
) => {
  const { fullSymbol, onlySymbol } = options || {};

  if (typeof currencyCode !== 'string') {
    return undefined;
  }
  const code = currencyCode.toUpperCase();
  if (!currencySymbolsList[code]) {
    return undefined;
  }

  const currencySymbol = currencySymbolsList[code];

  if (typeof currencySymbol === 'string') {
    return currencySymbol;
  }

  if (onlySymbol) {
    return currencySymbol?.onlySymbol || currencySymbol?.default;
  }

  if (fullSymbol) {
    return currencySymbol?.fullSymbol || currencySymbol?.default;
  }

  return !currencySymbol?.[getRouterKey()] ? currencySymbol?.default : currencySymbol?.[getRouterKey()];
};

export default getSymbolFromCurrenciesData;
