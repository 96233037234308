import cn from 'classnames';
import ReactPlayer from 'react-player';

import classes from './Video.module.scss';

type VideoProps = React.ComponentPropsWithoutRef<typeof ReactPlayer> & {
  fillsParentContainer?: boolean;
  isHidden?: boolean;
  /** Poster for the video */
  poster?: string;
  /** URL for the video */
  video: string;
  videoRef?: React.Ref<ReactPlayer>;
};

const Video = ({ fillsParentContainer, isHidden, poster, video, videoRef, ...props }: VideoProps) => (
  <div
    className={cn(classes.root, {
      [classes.fillsParent]: fillsParentContainer,
      [classes.hidden]: isHidden,
    })}
  >
    <ReactPlayer
      className={classes.video}
      config={{ file: { attributes: { poster } } }}
      height="100%"
      ref={videoRef}
      url={video}
      width="100%"
      {...props}
    />
  </div>
);

export default Video;
