import { useCallback } from 'react';

import castArray from 'lodash/castArray';
import { useIntersectionObserver } from 'usehooks-ts';

import { useAppDispatch } from '@/store';

type Props = {
  componentPropToTrack?: string;
  intersectionThreshold?: number;
  isOmitPropToTrack?: boolean;
  // It's hard to type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAppearanceAction: (event?: unknown[]) => any;
  oncePerPage?: boolean;
};

const withTrackAppearance = <T extends Element | null | undefined, P extends Record<string, unknown>>({
  Component,
  componentPropToTrack = '',
  intersectionThreshold,
  isOmitPropToTrack = true,
  onAppearanceAction,
  oncePerPage,
}: Props & {
  Component: React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & { ref: React.Ref<T> }>;
}) => {
  const WrappedComponent = ({
    [componentPropToTrack]: dataToTrack,
    intersectionThreshold: intersectionThresholdDynamic,
    ...restProps
  }: React.PropsWithoutRef<P> & { intersectionThreshold?: number }) => {
    const dispatch = useAppDispatch();

    const trackEvent = useCallback(() => {
      if (dataToTrack == undefined) {
        if (onAppearanceAction) {
          dispatch(onAppearanceAction());
        }
        return;
      }

      const transformedToArray = castArray(dataToTrack);
      dispatch(onAppearanceAction(transformedToArray));
    }, [dataToTrack, dispatch]);

    const { ref } = useIntersectionObserver({
      freezeOnceVisible: oncePerPage,
      onChange: (isVisible) => isVisible && trackEvent?.(),
      threshold: intersectionThreshold ?? intersectionThresholdDynamic,
    });

    const props = isOmitPropToTrack ? restProps : { [componentPropToTrack]: dataToTrack, ...restProps };

    return <Component ref={ref} {...(props as React.PropsWithoutRef<P>)} />;
  };

  WrappedComponent.displayName = `withTrackAppearance(${Component.displayName || Component.name})`;
  return WrappedComponent;
};

export default withTrackAppearance;
