import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { addMonths, getDate, getDaysInMonth, getMonth, getYear, startOfYear, subYears } from 'date-fns';
import delay from 'lodash/delay';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { selectLookup } from '@/ducks/common/selectors';
import { getMinAgeDate, getMinSignupDate } from '@/helpers/data/mappers/SailorDetails';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { currentNYTime, format, parse } from '@/helpers/util/dateUtil';

import TextField from '../TextField';

import './DateOfBirth.scss';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isSignUp: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lookup: PropTypes.shape({}).isRequired,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  subText: PropTypes.node,
  tabIndex: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  className: null,
  disabled: false,
  error: null,
  id: null,
  isSignUp: false,
  label: null,
  name: '',
  onBlur: () => {},
  onChange: null,
  required: null,
  subText: undefined,
  tabIndex: '0',
  value: '',
};

const hasValue = (selection) =>
  Boolean(selection) &&
  ['dd', 'mmm', 'yyyy'].every((template) => !selection.toString().toLowerCase().includes(template));

const formatNumber = (number) => (number < 10 ? `0${number}` : number);

const yearOrDefault = (year) => (hasValue(year) ? year : 1970);

const isFullDate = (values) => !['day', 'month', 'year'].some((name) => isNaN(+values[name]));

const isValidDate = (values) => {
  const { day, month, year } = values || {};
  const date = new Date(year, month, day);
  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
};

const stringifyDate = (values) => {
  const { day, month, year } = values || {};
  return [year, month + 1, day].map(formatNumber).join('-');
};

/**
 * Date of Birth Component
 * @section Forms
 */

class DateOfBirth extends React.PureComponent {
  onBlur = () => {
    this.isActive = false;
    delay(this.onSubFieldBlur, 100); // ensure next focus event triggered
  };

  onFocus = () => {
    this.isActive = true;
  };

  onSubFieldBlur = () => {
    const { onBlur, value } = this.props;
    if (!this.isActive) {
      onBlur({ target: { value } });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      day: 'DD',
      month: 'MMM',
      year: 'YYYY',
    };
    this.isActive = false;
    this.onChange = this.onChange.bind(this);
  }

  static getMonthOptions(formatMessage, currentDate) {
    let year = startOfYear(currentDate);
    const months = [
      <option disabled key="monthUnselected" value="MMM">
        {formatMessage({ defaultMessage: 'MMM', id: 'DateOfBirth.monthLabel' })}
      </option>,
    ];
    for (let x = 0; x < 12; x += 1) {
      months.push(
        <option key={`month-${x}`} value={x}>
          {format(year, 'MMM').toUpperCase()}
        </option>,
      );
      year = addMonths(year, 1);
    }

    return months;
  }

  static getYearOptions(formatMessage, currentDate, isSignUp = false) {
    let year = isSignUp ? startOfYear(getMinSignupDate(currentDate)) : startOfYear(getMinAgeDate(currentDate));
    const years = [
      <option disabled key="yearUnselected" value="YYYY">
        {formatMessage({ defaultMessage: 'YYYY', id: 'DateOfBirth.yearLabel' })}
      </option>,
    ];
    for (let x = 1; x <= 100; x += 1) {
      years.push(
        <option key={`year-${x}`} value={getYear(new Date(year))}>
          {format(year, 'yyyy')}
        </option>,
      );
      year = subYears(year, 1);
    }
    return years;
  }

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      this.setDob(value);
    }
  }

  componentDidUpdate() {
    const { value } = this.props;

    if (value && value !== stringifyDate(this.state)) {
      this.setDob(value);
    }
  }

  getDayOptions() {
    const { month, year } = this.state;
    const { formatMessage } = this.context;
    const days = [
      <option disabled key="dayUnselected" value="DD">
        {formatMessage({ defaultMessage: 'DD', id: 'DateOfBirth.dayLabel' })}
      </option>,
    ];
    if (hasValue(month)) {
      const daysInMonth = getDaysInMonth(new Date(yearOrDefault(year), month));
      for (let x = 1; x <= daysInMonth; x += 1) {
        days.push(
          <option key={`day-${x}`} value={x}>
            {x && `0${x}`.slice(-2)}
          </option>,
        );
      }
    } else {
      for (let x = 1; x <= 31; x += 1) {
        days.push(
          <option key={`day-${x}`} value={x}>
            {x && `0${x}`.slice(-2)}
          </option>,
        );
      }
    }
    return days;
  }

  getDob(state) {
    const { name } = this.props;
    return {
      target: { name, value: stringifyDate(state) },
    };
  }

  onChange(type) {
    return (event) => {
      const { onChange } = this.props;
      const { value } = event.target;
      const newState = {
        ...this.state,
        [type]: parseInt(value, 10),
      };
      if (!isFullDate(newState) || isValidDate(newState)) {
        this.setState(newState, () => {
          if (onChange) {
            onChange(this.getDob(newState));
          }
        });
      }
    };
  }

  render() {
    const { isLoading, isSignUp, lookup, value, ...restProps } = this.props;
    const { day, month, year } = this.state;
    const { className, disabled, error, id, label, name, required, subText, tabIndex } = restProps;

    const classes = classNames({
      [className]: !!className,
      DateOfBirth: true,
    });
    const currentDate = currentNYTime(get(lookup, 'serverISOtime'));
    const { formatMessage } = this.context;

    return (
      <div className={classes} id={id}>
        <input tabIndex="-1" type="hidden" value={this.getDob(this.state)} />
        <TextField className="DateOfBirth__container" error={error} label={label} required={required} subText={subText}>
          <TextField
            aria-label={formatMessage({
              defaultMessage: 'Select Month',
              id: 'DateOfBirth.month',
            })}
            disabled={disabled}
            isLoading={isLoading}
            key="DateOfBirth.month"
            name={name}
            onBlur={this.onBlur}
            onChange={this.onChange('month')}
            onFocus={this.onFocus}
            required={required}
            tabIndex={tabIndex}
            value={month}
          >
            {DateOfBirth.getMonthOptions(formatMessage, currentDate)}
          </TextField>
          <TextField
            aria-label={formatMessage({
              defaultMessage: 'Select Day',
              id: 'DateOfBirth.day',
            })}
            disabled={disabled}
            isLoading={isLoading}
            key="DateOfBirth.day"
            name={name}
            onBlur={this.onBlur}
            onChange={this.onChange('day')}
            onFocus={this.onFocus}
            required={required}
            tabIndex={tabIndex}
            value={day}
          >
            {this.getDayOptions()}
          </TextField>
          <TextField
            aria-label={formatMessage({
              defaultMessage: 'Select Year',
              id: 'DateOfBirth.year',
            })}
            disabled={disabled}
            isLoading={isLoading}
            key="DateOfBirth.year"
            name={name}
            onBlur={this.onBlur}
            onChange={this.onChange('year')}
            onFocus={this.onFocus}
            required={required}
            tabIndex={tabIndex}
            value={year}
          >
            {DateOfBirth.getYearOptions(formatMessage, currentDate, isSignUp)}
          </TextField>
        </TextField>
      </div>
    );
  }

  setDob(date) {
    if (hasValue(date)) {
      const value = parse(date, true);
      if (value) {
        const day = getDate(value);
        const month = getMonth(value);
        const year = getYear(value);
        const { day: prevDay, month: prevMonth, year: prevYear } = this.state;
        if (day !== prevDay || month !== prevMonth || year !== prevYear) this.setState({ day, month, year });
      }
    }
  }
}

DateOfBirth.propTypes = propTypes;
DateOfBirth.defaultProps = defaultProps;
DateOfBirth.contextType = FormattedMessageContext;

function mapStateToProps(state) {
  return {
    lookup: selectLookup(state),
  };
}

export default connect(mapStateToProps)(DateOfBirth);
