import cn from 'classnames';

import classes from './Helpers.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'> & { children: React.ReactNode };

export const desktopOnly = classes.isDesktop;
export const mobileOnly = classes.isMobile;

export enum Screen {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export const DesktopOnly = ({ children, className, ...props }: Props) => (
  <div className={cn(classes.isDesktop, className)} {...props}>
    {children}
  </div>
);

export const MobileOnly = ({ children, className, ...props }: Props) => (
  <div className={cn(classes.isMobile, className)} {...props}>
    {children}
  </div>
);

type WithLoaderProps = {
  children: React.ReactNode;
  fallback: React.ReactNode;
  isLoading: boolean;
};

export const WithLoader = ({ children, fallback, isLoading }: WithLoaderProps) => {
  if (isLoading) {
    return fallback;
  }
  return children;
};
