import React from 'react';

import cn from 'classnames';

import Button from '@/components/elements/Button';
import UIResource from '@/components/UIResource';
import Status from '@/ducks/a11y/components/Status';
import useActionStatus from '@/ducks/a11y/hooks/useActionStatus';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './index.scss';

type Props = {
  applyNewFilterUI?: boolean;
  ariaLabel?: string;
  isApplyDisabled?: boolean;
  isDataApplied?: boolean;
  isDataChanged?: boolean;
  onApply: (event: React.UIEvent) => void;
  onReset?: () => void;
  withGlow?: boolean;
};

const RESET_STATUS_TEXT = 'Reset button clicked';

const RefinementButtons = ({
  applyNewFilterUI,
  ariaLabel,
  isDataApplied,
  isDataChanged,
  onApply,
  onReset,
  withGlow,
}: Props) => {
  const { actionWithStatus: resetWithStatus, closeStatus, isStatusShown } = useActionStatus(onReset);

  const onKeyDownClear = useOnKeyDown(resetWithStatus, { isDefaultPrevented: true });
  const onKeyDownApply = useOnKeyDown(onApply, { isDefaultPrevented: true });

  const showReset = isDataApplied || isDataChanged;

  return (
    <div
      className={cn('RefinementButtons', {
        '-newFilter': applyNewFilterUI,
        '-with-glow': withGlow,
        '-with-reset': showReset,
      })}
    >
      {showReset && (
        <Button onClick={resetWithStatus} onKeyDown={onKeyDownClear} variant="text">
          <UIResource id="ResultRefinements.clearButton.newText" />
        </Button>
      )}

      <Button
        aria-disabled={!isDataChanged}
        aria-label={ariaLabel}
        className="RefinementButtons__button -apply"
        disabled={!isDataChanged}
        onClick={onApply}
        onKeyDown={onKeyDownApply}
        type="submit"
        variant="primary"
      >
        <UIResource id="ResultRefinements.applyButton" />
      </Button>

      <Status hideAfter={1000} isShown={isStatusShown} onHide={closeStatus} srOnly>
        {RESET_STATUS_TEXT}
      </Status>
    </div>
  );
};

export default RefinementButtons;
