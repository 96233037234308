import Image from 'next/image';
import { useMemo } from 'react';

import cn from 'classnames';

import logo from '@publicImages/logo.png';

import type { TOptional } from '@/types';

import * as Dialog from '@/components/elements/Dialog';
import UIResource from '@/components/UIResource';
import { type TUIResourceImage, useUIResource } from '@/ducks/common/resources';
import { useGetSpecialSailings } from '@/ducks/lookup/hooks/useGetSpecialSailings';

import classes from './SpecialSailingModal.module.scss';

export const SpecialSailingModal = ({ classificationCode }: { classificationCode: TOptional<string> }) => {
  const specialSailings = useGetSpecialSailings();
  const logoAlt = useUIResource<TUIResourceImage>('BestPriceGuarantee.popup.logo')?.alt;

  const specialSailing = useMemo(
    () => specialSailings?.find((sailing) => sailing.id === classificationCode),
    [classificationCode, specialSailings],
  );

  if (specialSailing == null) return;

  const { mobileModalHero, modalDescription, name: title, portraitCarousel, subtitle } = specialSailing;

  const modalDescriptionClasses = cn({
    [classes.description]: true,
    [classes.textCenter]: modalDescription && !modalDescription.includes('<br />'),
  });
  return (
    <div className={classes.root}>
      <div className={cn(classes.textContent, classes.containerWithGaps)}>
        <div className={classes.logo}>
          <Image alt={logoAlt} height={50} src={logo} width={50} />
        </div>
        <Dialog.Title asChild>
          <div className={classes.containerWithGaps}>
            <h1 className={classes.title}>
              <UIResource id="SpecialSailingModal.VirginVoyages.name" />
              <br />
              {title}
            </h1>
            <h2 className={classes.subTitle}>{subtitle}</h2>
          </div>
        </Dialog.Title>
        <Dialog.Description asChild>
          {modalDescription && (
            <div className={modalDescriptionClasses} dangerouslySetInnerHTML={{ __html: modalDescription }} />
          )}
        </Dialog.Description>
      </div>
      <div
        className={classes.backgroundImage}
        style={{
          '--desktop-image': `url(${portraitCarousel[0]?.src})`,
          '--mobile-image': `url(${mobileModalHero?.src})`,
        }}
      />
    </div>
  );
};
