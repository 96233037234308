import { ReadonlyURLSearchParams } from 'next/navigation';

import isEmpty from 'lodash/isEmpty';

import { type TUrlParams } from '@/infra/types/common';

const addToSearchParams = (target: URLSearchParams, addition: TUrlParams | URLSearchParams): URLSearchParams => {
  if (target && addition) {
    (addition instanceof URLSearchParams || addition instanceof ReadonlyURLSearchParams
      ? [...addition.entries()]
      : !isEmpty(addition)
        ? Object.entries(addition)
        : []
    ).forEach(([name, value]) => {
      if (target.has(name)) target.delete(name);
      if (Array.isArray(value)) value.forEach((item) => target.append(name, item));
      else target.append(name, value);
    });
  }
  return target;
};

export default addToSearchParams;
