import { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import HtmlContent from '@/components/elements/HtmlContent';
import IFrame from '@/components/IFrame';

type Props = {
  className?: string;
  fetcher: (url: string, noAuth?: boolean) => Promise<string>;
  isInline?: boolean;
  noAuth: boolean;
  url: string;
};

export const useEmbeddedContent = ({ className, fetcher, isInline, noAuth = false, url }: Props) => {
  const [content, setContent] = useState<string>();

  useEffect(() => {
    fetcher(url, noAuth)
      .then(setContent)
      .catch(() => console.error(`Unable to get EmbeddedContent from ${url}`));
  }, [fetcher, url]);

  return useMemo(() => {
    const Node = isInline ? HtmlContent : IFrame;
    if (content) {
      return <Node className={cn('Embed', className)} content={content} />;
    }
  }, [className, content, isInline]);
};
