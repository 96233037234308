import { env } from '@/environment';
import { TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import sharedCall from '@/helpers/sharedCall';

import { api } from './core';
import buildInvoiceDetailBody from './mappers/req/InvoiceDetail';
import buildNewCabinByAttributesBody from './mappers/req/NewCabinByAttributes';
import isValidCalculateInvoice from './validators/calculateInvoice';

export const fetchCMSComponent = (url, noAuth = false) =>
  api.get(url, {
    baseURL: env.CMS_COMPONENT_BASE_URL,
    noAuth,
  });

export const fetchCMSComponentForBook = (url) => api.get(url, { baseURL: env.CMS_COMPONENT_BASE_URL_BOOK });

export const fetchVVCampaignContentData = withAuthHeader(TokenType.cms, (authHeaders, id) =>
  api.get('/vv-campaigns', {
    baseURL: env.CMS_BASE_URL,
    headers: authHeaders,
    params: { externalId: id },
  }),
);

export const fetchLegalContentData = withAuthHeader(TokenType.cms, (authHeaders, name) =>
  api.get('/legalcontents', {
    baseURL: env.CMS_BASE_URL,
    headers: authHeaders,
    params: { name },
  }),
);

export const fetchReferralCode = (reservationNumber) =>
  api.get(`/referral/booking/${reservationNumber}`, {
    baseURL: env.SEAWARE_BASE_URL,
  });

export const checkReferralEligibility = sharedCall((params) =>
  api.post('/referral/eligibility', params, {
    baseURL: env.SEAWARE_BASE_URL,
  }),
);

export const linkReferralData = (params) =>
  api.post('/referral', params, {
    baseURL: env.SEAWARE_BASE_URL,
  });

export const fetchSignedFields = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/signature', params, { headers: authHeaders }),
);

export const initiateUpliftPayment = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/initiatepayment', params, {
    baseURL: env.PAYMENT_URL,
    headers: authHeaders,
  }),
);

export const postPaymentResponse = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/reservation/pay', params, { headers: authHeaders }),
);

export const validateAccessKey = sharedCall((accessKey, config) => api.get(`/fetchPromoCode/${accessKey}`, config));

// TODO: In all api.get call here need to pass the parameters as the config.params field, and not form the url manually!

export const fetchDashboardData = (reservationNumber, processingId) => {
  const params = { reservationNumber, ...(processingId ? { processingId } : undefined) };
  return api.get('/voyagedashboard', { params });
};

export const fetchVoyageListData = withAuthHeader(TokenType.guest, async (authHeaders, searchQualifier, config) => {
  const fullConfig = { ...config, headers: { ...config?.headers, ...authHeaders } };
  return (await api.post('/voyages', searchQualifier, fullConfig)) || { packages: [] };
});

export const fetchCabinDetailsData = (chosenCategoryCode, cabinMetaCode, isCabinAccessible) =>
  api.get(
    `/cabins/${chosenCategoryCode}/details?cabinMetaCode=${cabinMetaCode}${
      isCabinAccessible ? `&isAccessible=${isCabinAccessible}` : ''
    }`,
  );

export const fetchCaluclateInvoiceData = sharedCall(
  ({
    cabins,
    chosenSubCategoryCode,
    currencyCode,
    externalRefId,
    isCabinAccessible,
    metaType,
    promoCode,
    sailors,
    voyageId,
  }) =>
    api.post(
      '/calculateinvoice',
      buildInvoiceDetailBody(
        voyageId,
        chosenSubCategoryCode,
        sailors,
        cabins,
        currencyCode,
        isCabinAccessible,
        metaType,
        promoCode,
        externalRefId,
      ),
      { validator: isValidCalculateInvoice },
    ),
  { timeout: 90000 },
);

export const cabinHold = (cabinDetails) => api.post('/cabin/hold', cabinDetails);

export const cabinUnhold = (cabinDetails) => api.post('/cabin/unhold', cabinDetails);

export const fetchWhatsIncludedData = () => api.get('/whatsIncluded');

export const fetchItinerarySummaryData = ({ packageCode, voyageId }) => {
  return api.get(`/sailings?voyageId=${voyageId}&packageCode=${packageCode}`);
};

export const validateAgency = (agentId, includeAgentDetails = false) =>
  api.get(`/reservations/agentdetail/validation?agentId=${agentId}&includeAgentDetails=${includeAgentDetails}`, {
    baseURL: env.DXP_CORE_BASE_URL,
  });

export const bookNow = sharedCall((payload) => api.post('/booknow', payload));

export const fetchTravelInsuranceFlyoutData = sharedCall(() => api.get('/travelInsurance'));

export const fetchAvailableZones = (availableZoneRequest) => api.post('/availablezones', availableZoneRequest);

export const getNewCabinByAttributes = (zone, parsedParameters) => {
  const { cabins, chosenSubCategoryCode, currencyCode, isCabinAccessible, sailors, shipCode, voyageId } =
    parsedParameters;
  return api.post(
    '/cabin/allotbyzone',
    buildNewCabinByAttributesBody(
      voyageId,
      chosenSubCategoryCode,
      sailors,
      cabins,
      shipCode,
      currencyCode,
      isCabinAccessible,
      zone,
    ),
  );
};

export const shortenUrl = sharedCall((shareUrl) => api.get(`/api/shorten?url=${encodeURIComponent(shareUrl)}`));

export const fetchAccessKeyDetailsData = (campaignKey, config) => {
  const accessKeyDetails = api.get(`/accesskeys/${campaignKey}`, config).then((data) => {
    const details = {};
    Object.assign(details, { ...data, campaignKey: data.accessKey });
    delete details.accessKey;
    return details;
  });

  return accessKeyDetails;
};

export const validateReferralCode = (referralCode) => {
  if (!referralCode) throw new TypeError(`Empty referralCode: ${referralCode}`);
  return api.get(`/referral/${referralCode}`, {
    baseURL: env.SEAWARE_BASE_URL,
  });
};

export const fetchConfirmationTodoList = (reservationNumber, isVip, clientTransactionId) =>
  api.get('/confirmationPage/landing', { params: { clientTransactionId, isVip, reservationNumber } });

export const applyFVC = (params) =>
  api.post('/sailors/applyVoyageCredit', params, {
    baseURL: env.SEAWARE_BASE_URL,
  });
