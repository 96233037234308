import cn from 'classnames';

import { Skeleton } from '@/components/Skeleton';
import formatMoney, { Fraction, type TFormatMoneyOptions } from '@/helpers/util/formatMoney';
import isSafari from '@/helpers/util/isSafari';
import { isDiscountAmount } from '@/helpers/util/misc';
import { type TMoneyAmount } from '@/types/common';

import './Price.scss';

export { Fraction };

type AmountProps = TFormatMoneyOptions & TMoneyAmount;

export const Amount = ({ amount, currencyCode, fraction, onlySymbol }: AmountProps) =>
  formatMoney(amount, currencyCode || 'USD', { fraction, onlySymbol });

const ChooseCabinPrice = ({ amount, currencyCode }: AmountProps) => {
  const originalAmountComp = <Amount amount={amount} currencyCode={currencyCode} />;
  return (
    <span className="price">
      <span className="amount">{originalAmountComp}</span>
    </span>
  );
};

type ChooseCabinDiscountProps = AmountProps & {
  originalAmount: number;
};

const ChooseCabinDiscount = ({ amount, currencyCode, originalAmount }: ChooseCabinDiscountProps) => {
  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const originalAmountComp = <Amount amount={originalAmount - amount} currencyCode={currencyCode} />;
  if (isDiscountPresent) {
    return (
      <span className="price">
        <span className="amount price-with-discount">{originalAmountComp}</span>
      </span>
    );
  }

  return originalAmountComp;
};

type PriceProps = AmountProps & {
  descriptionPrefix?: string;
  idForAriaLabelSafari?: string;
  isForceLineBreak?: boolean;
  isLoading?: boolean;
  isVariantBAndAmount?: boolean;
  isVariantBAndDiscount?: boolean;
  originalAmount?: number;
  roundUp?: boolean;
};

const Price = (props: PriceProps) => {
  const {
    currencyCode,
    descriptionPrefix,
    idForAriaLabelSafari,
    isForceLineBreak = false,
    isLoading,
    isVariantBAndAmount,
    isVariantBAndDiscount,
    onlySymbol,
    roundUp = false,
  } = props;

  const amount = roundUp && props.amount ? Math.ceil(props.amount) : props.amount;
  const originalAmount = roundUp && props.originalAmount ? Math.ceil(props.originalAmount) : props.originalAmount || 0;

  if (isLoading || isNaN(props.amount)) {
    return <Skeleton width="100px" />;
  }

  if (isVariantBAndAmount) {
    return <ChooseCabinPrice amount={amount} currencyCode={currencyCode} />;
  }

  if (isVariantBAndDiscount) {
    return <ChooseCabinDiscount amount={amount} currencyCode={currencyCode} originalAmount={originalAmount} />;
  }

  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const initialAmount = <Amount amount={amount} currencyCode={currencyCode} onlySymbol={onlySymbol} />;
  const initialAmountClassName = cn('amount', {
    'force-line-break': isForceLineBreak,
    'price-with-discount': isDiscountPresent,
  });

  const isSafariHack = Boolean(isSafari() && idForAriaLabelSafari);
  const priceContentSR = `${descriptionPrefix} Price ${formatMoney(amount, currencyCode, { onlySymbol })} ${isDiscountPresent ? 'Price without discount ' + formatMoney(originalAmount, currencyCode, onlySymbol) : ''}`;
  const originalAmountComp = (
    <span aria-hidden={isSafariHack} className={initialAmountClassName}>
      <span className="sr-only">Price</span>
      {initialAmount}
      {isSafariHack && (
        <span className="sr-only" id={idForAriaLabelSafari}>
          {priceContentSR}
        </span>
      )}{' '}
    </span>
  );

  if (isDiscountPresent) {
    return (
      <span aria-hidden={isSafariHack} className="price price-with-discount">
        {originalAmountComp}
        <span className="original-amount">
          <span className="sr-only"> Price without discount</span>
          {formatMoney(originalAmount!, currencyCode, { onlySymbol })}
        </span>{' '}
      </span>
    );
  }

  return originalAmountComp;
};

export default Price;
