import {
  CONFIGURATION_SERVICE_SUCCESS,
  SET_AUTHENTICATION_INITIALIZED_SUCCESS,
  SET_TOKEN_SUCCESS,
} from '@/constants/actionTypes';

const defaultState = {
  tokens: {
    Basic: null,
    BearerUser: null,
  },
};

// Reducers
const setStateToken = (state, type, value) => ({
  ...state,
  tokens: {
    ...state.tokens,
    [type]: value,
  },
});

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case CONFIGURATION_SERVICE_SUCCESS:
      return setStateToken(state, 'Basic', action.payload.basicToken);
    case SET_AUTHENTICATION_INITIALIZED_SUCCESS:
      return {
        ...state,
        authenticationInitialized: { data: action.payload },
      };
    case SET_TOKEN_SUCCESS:
      return setStateToken(state, action.tokenType, action.tokenValue);
    default:
      return state;
  }
};

export default auth;
