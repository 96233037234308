import { createReducer, type PayloadAction, type SerializedError } from '@reduxjs/toolkit';

import { CLEAR_LOGGED_IN_SESSION_DATA } from '@/constants/actionTypes';
import getThunkStates from '@/helpers/getThunkStates';
import { type TStoreLoadablePartition } from '@/infra/types/common';
import { type TUserProfile } from '@/infra/types/user';
import { type TOptional } from '@/types/common';

import { THUNK_PREFIXES } from './types';

export type TStoreProfile = TStoreLoadablePartition<TUserProfile, SerializedError>;

const initialState: TStoreProfile = {
  data: {} as TUserProfile,
  error: undefined,
  isLoaded: undefined,
  isLoading: undefined,
};

// There is a circular dependency in the file @/helpers/api/core (through store). So, use the thunk states names, not the thunk itself
const fetchUserProfile = getThunkStates(THUNK_PREFIXES.fetchUserProfile);

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(CLEAR_LOGGED_IN_SESSION_DATA, () => ({ ...initialState }))
    .addCase(fetchUserProfile.pending, () => ({ ...initialState, isLoading: true }))
    .addCase(fetchUserProfile.fulfilled, (_, { payload }: PayloadAction<TOptional<TUserProfile>>) => ({
      ...initialState,
      data: payload!,
      isLoaded: true,
    }))
    .addCase(fetchUserProfile.rejected, (_, { error }) => ({ ...initialState, error })),
);

export default reducer;
