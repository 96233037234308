import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { useAppDispatch, useAppSelector } from '@/store';

import { updateQuickSearchItemsAction } from './actions';

export const useQuickSearchItems = () => {
  const apolloClient = useApolloClient();
  const dispatch = useAppDispatch();

  const currencyCode = useAppSelector(selectCurrencyCode);
  const sailors = useAppSelector(selectSailors);

  useEffect(() => {
    dispatch(updateQuickSearchItemsAction(apolloClient, currencyCode, sailors));
  }, [apolloClient, currencyCode, sailors, dispatch]);
};
