import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { useAppSelector } from '@/store';

import type { RefinementPopoverProps } from '../RefinementPopover/useGetRefinementPopoverProps';

import DestinationRefinementPopupDesktop from './PopupDesktop';
import DestinationRefinementPopupMobile from './PopupMobile';
import { selectDestinationsFilterData } from './selectors';
import { type DestinationsFilterData, DestinationType } from './types';

type DestinationRefinementProps = {
  isMobile?: boolean;
  popoverProps: RefinementPopoverProps;
};

const isDataEmpty = (data: DestinationsFilterData) =>
  [DestinationType.ITINERARIES, DestinationType.PORTS_OF_CALL].every((key) => isEmpty(data?.[key]));

const DestinationRefinement = (props: DestinationRefinementProps) => {
  const { isMobile = false } = props;

  const data = useAppSelector(selectDestinationsFilterData);

  if (isDataEmpty(data)) {
    return;
  }

  return isMobile ? <DestinationRefinementPopupMobile {...props} /> : <DestinationRefinementPopupDesktop {...props} />;
};

export default DestinationRefinement;
