import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import type { NullablePartial } from '@/types';

import { getCookie, setCookie } from '@/ducks/cookies';
import { selectFilters, selectFiltersDependencies } from '@/ducks/filters/selectors';
import { FILTER_KEY, type FiltersKey, type FiltersTypes } from '@/ducks/filters/types';
import { type AppDispatch, type AppGetState, useAppDispatch, useAppSelector } from '@/store';

import { config } from './config';
import { actions } from './reducer';
import {
  addExplicitValuesToURL,
  getFilterValues,
  getInvalidFiltersSearchParamsKeys,
  getSearchParamsKeys,
  getUpdatedKeys,
} from './utils';

export const useRouterTranslator = () => {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch(updateValues({ searchParams }));
  }, [dispatch, pathname, searchParams]);
};

export const useFiltersValuesInit = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch(filtersValuesInit({ searchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useOnUpdateFiltersValues = (
  keys: FiltersKey[],
  handler: (values: NullablePartial<FiltersTypes>) => void,
) => {
  const valuesRef = useRef<NullablePartial<FiltersTypes> | undefined>();
  const allValues = useAppSelector(selectFilters);

  useEffect(() => {
    const values = keys.reduce((values, key) => ({ ...values, [key]: allValues[key] }), {});
    if (valuesRef.current && !isEqual(valuesRef.current, values)) {
      handler(allValues);
    }
    valuesRef.current = values;
  }, [handler, allValues, keys]);
};

const updateValues =
  ({ searchParams }: { searchParams: URLSearchParams }) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const currentState = getState();
    const data = getFilterValues({ getCookie, searchParams, state: currentState });
    if (data.dependencies !== selectFiltersDependencies(currentState)) {
      dispatch(actions.updateFilterValuesState(data));
    }

    const state = getState();
    getUpdatedKeys(selectFilters(currentState), data.values).forEach((key) => {
      const { onValueUpdate } = config[key] || {};
      if (typeof onValueUpdate === 'function') {
        onValueUpdate({ getCookie, searchParams, setCookie, state, value: data.values[key] });
      }
    });

    addExplicitValuesToURL({ state });
  };

const filtersValuesInit =
  ({ searchParams }: { searchParams: URLSearchParams }) =>
  (_: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const invalidSearchParamsKeys = getInvalidFiltersSearchParamsKeys({ getCookie, searchParams, state });
    const params = new URLSearchParams(window.location.search);

    if (invalidSearchParamsKeys.length > 0) {
      invalidSearchParamsKeys.forEach((key) => params.delete(key));
      const qs = params.toString();
      history.replaceState(null, '', `${window.location.pathname}${qs ? `?${qs}` : ''}`);
    }

    const values = selectFilters(state);
    (Object.keys(values) as FiltersKey[]).forEach((key) => {
      config[key]?.onValueInit?.({ getCookie, searchParams: params, setCookie, state, value: values[key] });
    });

    addExplicitValuesToURL({ state });
  };

export const useAnyAdvancedFilterApplied = () => {
  const searchParams = useSearchParams();

  const advancedFilters = [
    FILTER_KEY.accessible,
    FILTER_KEY.metaType,
    FILTER_KEY.durations,
    FILTER_KEY.homePorts,
    FILTER_KEY.ships,
    FILTER_KEY.cabins,
    FILTER_KEY.sailors,
    FILTER_KEY.weekend,
    FILTER_KEY.priceMax,
    FILTER_KEY.priceMin,
    FILTER_KEY.priceType,
    FILTER_KEY.voyageIds,
  ].reduce<string[]>((keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)], []);

  return advancedFilters.some((key) => searchParams.has(key));
};
