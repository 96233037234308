'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { type ReactNode, useEffect, useMemo } from 'react';

import isEqual from 'lodash/isEqual';

import Spinner from '@/components/elements/Spinner';
import redirect from '@/helpers/ssrSafe/redirect';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { useAppDispatch, useAppSelector } from '@/store';

import { selectForceRedirectTarget } from './selectors';
import { clearForceRedirect } from './slice';
import { type TRedirectTarget } from './types';

type TProps = {
  children: ReactNode;
};

const ForceRedirectGuard = ({ children }: TProps) => {
  const currentPath = usePathname();
  const currentSearchParams = useSearchParams();
  const dispatch = useAppDispatch();
  const { isReplace, path, urlParams } = useAppSelector(selectForceRedirectTarget) || ({} as TRedirectTarget);

  const isReached = useMemo(() => {
    if (path) {
      return (
        currentPath === path &&
        isEqual(urlParams || {}, currentSearchParams?.size ? searchParamsAsObject(currentSearchParams) : {})
      );
    }
  }, [currentPath, currentSearchParams, path, urlParams]);

  useEffect(() => {
    if (path && isReached) dispatch(clearForceRedirect());
  }, [path, isReached]);

  useEffect(() => {
    if (path && !isReached) redirect(path, { isReplace, params: urlParams });
  }, [currentPath, currentSearchParams, isReached, isReplace, path, urlParams]);

  if (path && !isReached) return <Spinner shouldFixed />;
  return children;
};

export default ForceRedirectGuard;
