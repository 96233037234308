import type { TOptional } from '@/types/common';

import getUserAgent from './getUserAgent';

export const enum OSName {
  Android = 'Android',
  MacOS = 'MacOS',
  Windows = 'Windows',
  iOS = 'iOS',
  // Add other OS if necessary
}

const MAP: [OSName, () => boolean][] = [
  [OSName.Android, () => getUserAgent().includes('Android')],
  [OSName.Windows, () => getUserAgent().includes('Windows')],
  [
    OSName.iOS,
    () => {
      const userAgent = getUserAgent();
      return (
        (typeof navigator !== 'undefined' &&
          ['iPad', 'iPad Simulator', 'iPhone', 'iPhone Simulator', 'iPod', 'iPod Simulator'].includes(
            navigator.platform,
          )) ||
        // iPad on iOS 13 detection
        (typeof document !== 'undefined' && userAgent.includes('Mac') && 'ontouchend' in document) ||
        /iPad|iPhone|iPod/.test(userAgent)
      );
    },
  ],
  [OSName.MacOS, () => getUserAgent().includes('Mac')],
];

const getOSName = (): TOptional<OSName> => {
  return MAP.find(([, checker]) => checker())?.[0] as TOptional<OSName>;
};

export const checkIfMobileOS = () => {
  const osName = getOSName();
  return osName && [OSName.iOS, OSName.Android].includes(osName);
};

export default getOSName;
