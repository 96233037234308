'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import AdvancedFilterRefinement from '@/components/AdvancedFilterRefinement';
import CurrencyDropdown from '@/components/ChooseVoyage/CurrencyDropdown';
import MainFilter from '@/components/ChooseVoyage/MainFilter';
import PromoBannerPlace, { PromoBannerPlaceId } from '@/components/PromoBanner/Place';
import { type OnChangeStatusHandler } from '@/components/ResultRefinements/RefinementPopover/types';
import useRefinementFiltersData from '@/components/ResultRefinements/RefinementPopover/useRefinementFiltersData';
import SearchAgainButton from '@/components/SearchAgainButton';
import UIResource from '@/components/UIResource';
// import { selectIsMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectors';
import switchListItem from '@/helpers/switchListItem';
import { useIsDesktop } from '@/hooks/useIsDevice';
import useOpenable from '@/hooks/useOpenable';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import SelectedFiltersLabelView from './AppliedFilters';
import FilterViewForSM from './FilterViewForSM';

import './ChooseVoyageTop.scss';

const ChooseVoyageTop = () => {
  const isDesktop = useIsDesktop();
  const [mobileSearchAgainClickedFlag, setMobileSearchAgainClickedFlag] = useState<boolean>(false);

  // const isMultipleVoyageFilter = useAppSelector(selectIsMultipleVoyageFilterActive);
  const sailingsCount = useAppSelector((state) => state?.cruisesCommon?.voyagesData?.sailingsCount);
  const [expandedSections, setExpandedSections] = useState([]);
  const [toggleMobileSearchAgain, setToggleMobileSearchAgain] = useState(false);
  const { close: closeFilterModal, isOpened: isOpenedFilterModal, open: openFilterModal } = useOpenable(false);

  const onSearchAgain = useCallback(() => {
    window.scrollTo(0, 0);
    if (!isDesktop) setMobileSearchAgainClickedFlag((prev: boolean) => !prev);
    tagmanager.tracker.voyagesFilter.trackReApplyFilters();
  }, [isDesktop]);

  const onCloseRefinementFilters = useCallback<OnChangeStatusHandler>(
    (isOpen, { closedByBackButton }) => {
      if (!isOpen && isOpenedFilterModal && !closedByBackButton) {
        closeFilterModal();
      }
    },
    [isOpenedFilterModal],
  );

  const refinementFiltersData = useRefinementFiltersData({ onChangeStatus: onCloseRefinementFilters });

  const onOpenFilterModal = useCallback(
    (section?: unknown) => {
      if (section && /* not event */ typeof section == 'string') {
        tagmanager.tracker.voyagesFilter.trackShowFilters();
        setExpandedSections(switchListItem(expandedSections, section, true));
      }
      openFilterModal();
      document.body.classList.toggle('-advFiltersOpen', true);
    },
    [expandedSections],
  );

  const onCloseFilterModal = useCallback(() => {
    setToggleMobileSearchAgain(false);
    closeFilterModal();
    setExpandedSections([]);
    document.body.classList.toggle('-advFiltersOpen', false);
  }, []);

  const expandSection = useCallback(
    (section: string) => {
      setExpandedSections(switchListItem(expandedSections, section, true));
    },
    [expandedSections],
  );

  const collapseSection = useCallback(
    (section: string) => {
      setExpandedSections(switchListItem(expandedSections, section, false));
    },
    [expandedSections],
  );

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setToggleMobileSearchAgain(true);
    }
  }, [mobileSearchAgainClickedFlag]);

  return (
    <>
      <CurrencyDropdown />

      {refinementFiltersData.popovers}
      <div className="ChooseVoyageTop">
        <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsA} />
        <h1>
          <span>
            <UIResource id="ChooseVoyage.Results.heading" values={{ N: sailingsCount }} />
          </span>
          <span className="highlighter" />
        </h1>
        <MainFilter onOpenFilterModal={onOpenFilterModal} refinementFiltersData={refinementFiltersData} />
        <FilterViewForSM
          // closeFilterModal={onCloseFilterModal}
          openFilterModal={onOpenFilterModal}
          refinementFiltersData={refinementFiltersData}
        />
        <SelectedFiltersLabelView onOpenFilterModal={onOpenFilterModal} />
        <AdvancedFilterRefinement
          closeFilterModal={onCloseFilterModal}
          collapseSection={collapseSection}
          expandedSections={expandedSections}
          expandSection={expandSection}
          // isMultipleVoyageFilter={isMultipleVoyageFilter}
          isOpen={isOpenedFilterModal || toggleMobileSearchAgain}
          refinementFiltersData={refinementFiltersData}
          renderMainFilter={onCloseFilterModal}
        />
        <div className="ChooseVoyageTop__shadow_container" />
      </div>

      <SearchAgainButton onClick={onSearchAgain} targetSelector=".PackageItineraryResults__body" />
    </>
  );
};

export default ChooseVoyageTop;
