import isObject from 'lodash/isObject';

import constants from '../../constants';
import { createTracker } from '../../tracker';
import builders from '../builders';

const trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const trackClick =
  (action) =>
  ({ eventEmitter = trackEvent, labels, labelsOptions, ...props }) => {
    const buildedLabel = builders.buildLabel(labels, labelsOptions);
    eventEmitter({
      action,
      label: buildedLabel,
      ...props,
    });
  };

export const trackLinkClick = (props) => {
  trackClick(constants.EVENT_ACTIONS.LINK_CLICK)(props);
};

export const trackButtonClick = (props) => {
  trackClick(constants.EVENT_ACTIONS.BUTTON_CLICK)(props);
};

export const trackSocialShare = (props) => {
  trackClick(constants.EVENT_ACTIONS.SOCIAL_SHARE)(props);
};

export const trackIconClick = (props) => {
  trackClick(constants.EVENT_ACTIONS.ICON_CLICK)(props);
};

export const trackPanelToggle = ({ state, ...props }) => {
  const { labels = [] } = props;

  const stateMessage = state ? constants.EVENT_ACTIONS.EXPAND : constants.EVENT_ACTIONS.COLLAPSE;
  const prefixedLabels = [stateMessage, ...labels];

  trackIconClick({ ...props, labels: prefixedLabels });
};

export const trackCheckboxToggle = ({ state, ...props }) => {
  trackClick(state ? constants.EVENT_ACTIONS.CHECKBOX_SELECTED : constants.EVENT_ACTIONS.CHECKBOX_DESELECTED)(props);
};

export const trackPopupState = ({ isOpen, ...props }) => {
  trackClick(isOpen ? constants.EVENT_ACTIONS.DISPLAYED : constants.EVENT_ACTIONS.CLOSE)(props);
};

export const trackViewOnScroll =
  ({ idField = 'id', onViewed, viewedIdsSelector = null }) =>
  (entities) =>
  (dispatch, getState) => {
    const state = getState();

    const viewed = entities ? [...entities] : entities;
    const trackedOld = viewedIdsSelector?.(state) ?? [];
    const trackedNew = trackedOld?.length
      ? viewed.filter((entity) => {
          const viewedEntity = isObject(entity) ? entity[idField] : entity;
          return !trackedOld.includes(viewedEntity);
        })
      : viewed;

    if (onViewed) {
      onViewed(trackedNew, dispatch, getState);
    }
  };
