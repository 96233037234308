import React, {
  type ForwardedRef,
  forwardRef,
  type MutableRefObject,
  type RefObject,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';

import getDefaultScreenReader, { ScreenReader } from '@/helpers/userAgent/getDefaultScreenReader';
import isSafari from '@/helpers/util/isSafari';

export type ListBoxProps<T> = Omit<React.HTMLProps<HTMLDivElement>, 'value'> & {
  children: React.ReactNode;
  className?: string;
  disableFocusRef?: MutableRefObject<boolean>;
  id?: string;
  infiniteNavigation?: boolean;
  navigationDisabled?: boolean;
  onBlur?: () => void;
  onFocusChange?: (value: T | undefined) => void;
  onItemFocusChange?: () => void;
  onPressEnterItem?: (value: T) => void;
  onSelectItem?: (value: T, e: React.KeyboardEvent | React.MouseEvent) => void;
  scrollIntoViewBlock?: ScrollLogicalPosition;
  selectOnFocusChange?: boolean;
  value?: T;
};

type useListBoxNavProps<T> = {
  infiniteNavigation?: ListBoxProps<T>['infiniteNavigation'];
  navigationDisabled?: ListBoxProps<T>['navigationDisabled'];
  onBlur?: () => void;
  onItemFocusChange?: () => void;
  onPressEnterItem: ListBoxProps<T>['onPressEnterItem'];
  onSelectItem: ListBoxProps<T>['onSelectItem'];
  ref: RefObject<HTMLDivElement>;
  scrollIntoViewBlock?: ListBoxProps<T>['scrollIntoViewBlock'];
  selectOnFocusChange?: ListBoxProps<T>['selectOnFocusChange'];
  setFocusedValue: (value?: T) => void;
  value: ListBoxProps<T>['value'];
};

const getCurrentOption = (listBox: HTMLElement | null) => {
  const activeDescendantId = listBox?.getAttribute('aria-activedescendant');

  let option = activeDescendantId && document.getElementById(activeDescendantId);

  if (!option) {
    option = (listBox?.querySelectorAll('.ListBoxItem._selected')?.[0] ||
      listBox?.querySelectorAll('.ListBoxItem')?.[0]) as HTMLElement;
  }

  return option;
};

const updateScroll = (selectedOption?: HTMLElement, block: ScrollLogicalPosition = 'nearest') => {
  selectedOption?.scrollIntoView({ block });
};

const useListBoxKeyboardNav = <T,>({
  infiniteNavigation,
  navigationDisabled,
  onBlur,
  onItemFocusChange,
  onPressEnterItem,
  onSelectItem,
  ref,
  scrollIntoViewBlock,
  selectOnFocusChange,
  setFocusedValue,
}: useListBoxNavProps<T>) => {
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (
        navigationDisabled ||
        (!ref.current && e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Enter' && e.key !== ' ')
      ) {
        return;
      }

      e.preventDefault();

      const allOptions = Array.from(ref.current?.querySelectorAll('.ListBoxItem') || []) as HTMLElement[];
      const currentOption = getCurrentOption(ref.current);

      if (!currentOption) {
        return;
      }

      const currentOptionIndex = allOptions.indexOf(currentOption);
      let nextOption: HTMLElement | undefined;

      if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        onItemFocusChange?.();
      }
      if (e.key === 'ArrowUp' && currentOptionIndex > -1 && currentOptionIndex > 0) {
        nextOption = allOptions[currentOptionIndex - 1];
      } else if (e.key === 'ArrowUp' && infiniteNavigation && currentOptionIndex <= 0) {
        nextOption = allOptions[allOptions.length - 1];
      } else if (e.key === 'ArrowDown' && currentOptionIndex > -1 && currentOptionIndex < allOptions.length - 1) {
        nextOption = allOptions[currentOptionIndex + 1];
      } else if (e.key === 'ArrowDown' && infiniteNavigation && currentOptionIndex >= allOptions.length - 1) {
        nextOption = allOptions[0];
      } else if (e.key === 'Enter' || e.key === ' ') {
        if (currentOption.dataset.value && !selectOnFocusChange) {
          ref.current?.setAttribute('aria-activedescendant', currentOption.id);
          onSelectItem?.(currentOption.dataset.value as T, e);
        } else if (currentOption.dataset.value && onPressEnterItem) {
          onPressEnterItem(currentOption.dataset.value as T);
        }
        return;
      }

      if (nextOption) {
        ref.current?.setAttribute('aria-activedescendant', nextOption.id);
        setFocusedValue?.(nextOption.dataset.value as T);
        if (nextOption.dataset.value) {
          updateScroll(nextOption, scrollIntoViewBlock);
        }
        if (selectOnFocusChange && nextOption.dataset.value) {
          onSelectItem?.(nextOption.dataset.value as T, e);
        }
      }
    },
    [
      ref,
      onSelectItem,
      onPressEnterItem,
      setFocusedValue,
      selectOnFocusChange,
      infiniteNavigation,
      navigationDisabled,
      onItemFocusChange,
      scrollIntoViewBlock,
    ],
  );

  const onFocus = useCallback(() => {
    const currentOption = getCurrentOption(ref.current);
    if (currentOption) {
      ref.current?.setAttribute('aria-activedescendant', currentOption.id);
      setFocusedValue(currentOption?.dataset.value as T);
    }
  }, [ref, setFocusedValue]);

  const handleOnBlur = useCallback(() => {
    onBlur?.();
    setFocusedValue?.(undefined);
  }, [setFocusedValue, onBlur]);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('keydown', onKeyDown);
      node.addEventListener('focus', onFocus);
      node.addEventListener('blur', handleOnBlur);
    }

    return () => {
      if (node) {
        node.removeEventListener('keydown', onKeyDown);
        node.removeEventListener('focus', onFocus);
        node.removeEventListener('blur', handleOnBlur);
      }
    };
  }, [ref, onKeyDown, handleOnBlur, onFocus]);
};

const ListBoxInner = <T,>(
  {
    children,
    className,
    disableFocusRef,
    id,
    infiniteNavigation,
    navigationDisabled,
    onBlur,
    onItemFocusChange,
    onPressEnterItem,
    onSelectItem,
    scrollIntoViewBlock,
    selectOnFocusChange,
    value,
    ...rest
  }: ListBoxProps<T>,
  externalRef: ForwardedRef<HTMLDivElement>,
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const randomId = `listBox${useId()}`;

  const [selectedValue, setSelectedValue] = useState<T | undefined>(undefined);
  const [focusedValue, setFocusedValue] = useState<T | undefined>(undefined);
  const currentValue = onSelectItem ? value : selectedValue;

  const handleSetFocusedValue = useCallback((value?: T) => {
    if (disableFocusRef?.current) {
      // Prevent focus then was open by click
      disableFocusRef.current = false;
    } else {
      setFocusedValue(value);
    }
  }, []);

  useListBoxKeyboardNav<T>({
    infiniteNavigation,
    navigationDisabled,
    onBlur,
    onItemFocusChange,
    onPressEnterItem,
    onSelectItem: onSelectItem || setSelectedValue,
    ref,
    scrollIntoViewBlock,
    selectOnFocusChange,
    setFocusedValue: handleSetFocusedValue,
    value: currentValue,
  });

  const handler = useCallback(
    (child: React.ReactNode): React.ReactNode => {
      const item = child as React.ReactElement;
      if (React.isValidElement(child)) {
        if (item.type === ListItem) {
          return React.cloneElement(child, {
            focused: !navigationDisabled && child.props.value && child.props.value === focusedValue,
            onSelectItem: onSelectItem || setSelectedValue,
            selected: child.props.value && child.props.value === currentValue,
            ...child.props,
          });
        }

        if (item.type === React.Fragment) {
          return React.cloneElement(child, {
            ...child.props,
            children: React.Children.map(child.props.children, handler),
          });
        }
      }
      return child;
    },
    [currentValue, focusedValue, onSelectItem, navigationDisabled],
  );

  const setRef = useCallback(
    (node: HTMLDivElement) => {
      ref.current = node;
      if (typeof externalRef === 'function') {
        externalRef(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [externalRef, ref],
  );

  return (
    <div
      {...rest}
      className={cn('ListBox', className)}
      id={id || randomId}
      ref={setRef}
      role="listbox"
      tabIndex={navigationDisabled ? -1 : 0}
    >
      {React.Children.map(children, handler)}
    </div>
  );
};

export const ListBox = forwardRef(ListBoxInner) as <T>(
  props: ListBoxProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof ListBoxInner>;

type ListItemProps<T> = Omit<React.HTMLProps<HTMLDivElement>, 'value'> & {
  children: React.ReactNode;
  className?: string;
  focused?: boolean;
  id?: string;
  onKeyDown?: (e: React.UIEvent) => void;
  onSelectItem?: ListBoxProps<T>['onSelectItem'];
  selected?: boolean;
  value?: T;
};

export const ListItem = <T,>({
  children,
  className,
  focused,
  id,
  onKeyDown,
  onSelectItem,
  selected,
  value,
  ...rest
}: ListItemProps<T>) => {
  const randomId = `listItem${useId()}`;
  const isNvda = getDefaultScreenReader() === ScreenReader.NVDA;
  const isBrowserSafari = isSafari();
  return (
    <div
      id={id || randomId}
      {...rest}
      {...(!isBrowserSafari ? { 'aria-selected': selected === true } : undefined)}
      className={cn('ListBoxItem', className, {
        _focused: focused === true,
        _selected: selected === true,
      })}
      data-value={value}
      onClick={value ? (e) => onSelectItem?.(value, e) : undefined}
      role="option"
      tabIndex={-1}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            focused,
            ...child.props,
          });
        }
        return child;
      })}
      {/*hack for https://github.com/nvaccess/nvda/issues/14352*/}
      {isNvda && selected && <span className="sr-only"> selected </span>}
    </div>
  );
};
