import { useEffect, useState } from 'react';

import useDateRefinementPopover from '@/components/ResultRefinements/DateRefinement/useDateRefinementPopover';
import useDestinationRefinementPopover from '@/components/ResultRefinements/DestinationRefinement/useDestinationRefinementPopover';
import { type OnChangeStatusHandler } from '@/components/ResultRefinements/RefinementPopover/types';

type UseRefinementFiltersDataProps = {
  onChangeStatus: OnChangeStatusHandler;
};

const useRefinementFiltersData = ({ onChangeStatus }: UseRefinementFiltersDataProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const travelDates = useDateRefinementPopover({ isMobile, onChangeStatus });
  const destinations = useDestinationRefinementPopover({ isMobile, onChangeStatus });

  return {
    destinations,
    popovers: [destinations.popover, travelDates.popover],
    travelDates,
  };
};

export type RefinementFiltersData = ReturnType<typeof useRefinementFiltersData>;

export default useRefinementFiltersData;
