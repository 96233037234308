import { PropTypes } from 'prop-types';
import React from 'react';

import cn from 'classnames';

import CloseImg from '@publicImages/svg/close.svg';

import Svg from '@/components/Svg';

const Close = ({ className, id = 'close', ...restProps }) => (
  <span className={cn('Icon Close', className)} id={id} {...restProps}>
    <Svg src={CloseImg} />
  </span>
);

Close.propTypes = {
  id: PropTypes.string,
};

export default Close;
