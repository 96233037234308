import type { TLookupShip } from '@/ducks/common/lookup/types';
import type { TGetCookie, TSetCookie } from '@/ducks/cookies';
import type { TCurrencyCode } from '@/infra/types/common/base';
import type { FiltersPriceType, FiltersSortType, TFiltersDuration } from '@/infra/types/common/filters';
import type { Port } from '@/infra/types/voyageInfo/port';
import type { RootState } from '@/store';

export enum FILTER_KEY {
  accessKey = 'accessKey',
  accessible = 'accessible',
  cabinType = 'cabinType',
  cabins = 'cabins',
  currencyCode = 'currencyCode',
  destPackages = 'destPackages',
  destPorts = 'destPorts',
  destPackagesRegions = 'destPackagesRegions',
  destPortsRegions = 'destPortsRegions',
  durations = 'durations',
  dateFrom = 'dateFrom',
  homePorts = 'homePorts',
  priceMax = 'priceMax',
  metaType = 'metaType',
  priceMin = 'priceMin',
  priceType = 'priceType',
  sailors = 'sailors',
  ships = 'ships',
  sortType = 'sortType',
  sortTypeChooseCabin = 'sortTypeChooseCabin',
  dateTo = 'dateTo',
  voyageIds = 'voyageIds',
  weekend = 'weekend',
}

export type FiltersTypes = {
  [FILTER_KEY.accessible]: boolean;
  [FILTER_KEY.accessKey]: string;
  [FILTER_KEY.cabins]: number;
  [FILTER_KEY.cabinType]: string;
  [FILTER_KEY.currencyCode]: TCurrencyCode;
  [FILTER_KEY.dateFrom]: string;
  [FILTER_KEY.dateTo]: string;
  [FILTER_KEY.destPackages]: string[];
  [FILTER_KEY.destPackagesRegions]: string[];
  [FILTER_KEY.destPorts]: string[];
  [FILTER_KEY.destPortsRegions]: string[];
  [FILTER_KEY.durations]: TFiltersDuration[];
  [FILTER_KEY.homePorts]: Port[];
  [FILTER_KEY.metaType]: string;
  [FILTER_KEY.priceMax]: number;
  [FILTER_KEY.priceMin]: number;
  [FILTER_KEY.priceType]: FiltersPriceType;
  [FILTER_KEY.sailors]: number;
  [FILTER_KEY.ships]: TLookupShip[];
  [FILTER_KEY.sortType]: FiltersSortType;
  [FILTER_KEY.sortTypeChooseCabin]: FiltersSortType;
  [FILTER_KEY.voyageIds]: string[];
  [FILTER_KEY.weekend]: boolean;
};

export type FiltersKey = keyof FiltersTypes;

export type FiltersConfigValue<T> = {
  /**
   * When `true` filter value will always be appended to query-string
   */
  explicit?: boolean;

  /**
   * Returns deserialized value
   */
  getValue?: (props: GetFilterValuesProps & { value: null | string | string[] }) => null | T;

  /**
   * Filter maked as readonly couldn't be changed via `filters/setters`
   */
  isReadOnly?: <T>(value: T) => boolean;

  /**
   * Additional value validation. When returned `false` corresponding query param removed from URL
   */
  isValueValidForURL?: (
    props: GetFilterValuesProps & {
      value: null | string | string[];
    },
  ) => boolean;

  /**
   * Handler will be called once on application start (will be triggered by hook `useFiltersValuesInit`)
   */
  onValueInit?: <T>(
    props: GetFilterValuesProps & {
      setCookie: FiltersSetCookie;
      value: null | T;
    },
  ) => void;

  /**
   * Handler will be called:
   *   1) every time when search-params/pathname changed
   *   2) when user exits flow (like MNVV, FM, celebration).
   */
  onValueUpdate?: <T>(
    props: GetFilterValuesProps & {
      setCookie: FiltersSetCookie;
      value: null | T;
    },
  ) => void;

  /**
   * Prepares value for query-string. In case when value is `null` param removed from URL
   */
  serializeValue?: <T>(value: T, props: { searchParams: URLSearchParams; state: RootState }) => null | T;
};

export type FiltersConfig = {
  [T in FiltersKey]: FiltersConfigValue<FiltersTypes[T]>;
};

export type FiltersGetCookie = TGetCookie;

export type FiltersSetCookie = TSetCookie;

export type FilterSetter<T extends FiltersKey> = (value?: FiltersTypes[T]) => void;

export type GetFilterValuesProps = {
  getCookie: FiltersGetCookie;
  searchParams: URLSearchParams;
  state: RootState;
};
