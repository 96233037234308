import isEqual from 'lodash/isEqual';

import limitTime from '@/helpers/limitTime';

export type TSharedCallOptions<A extends unknown[], R> = {
  compareFn?: (args1: A, args2: A) => boolean;
  timeout?: number;
  timeoutResult?: R;
};

type TCall<A extends unknown[], R> = {
  args: A;
  promise: Promise<R>;
};

const sharedCall = <A extends unknown[], R>(
  fn: (...args: A) => Promise<R>,
  options?: TSharedCallOptions<A, R>,
): ((...args: A) => Promise<R>) => {
  const calls: Array<TCall<A, R>> = [];
  const { compareFn, timeout, ...rest } = options || {};
  const compare = typeof compareFn === 'function' ? compareFn : isEqual;
  return (...args: A): Promise<R> => {
    let { promise } = calls.find((call) => compare(args, call.args)) || {};
    if (!promise) {
      promise = fn(...args).finally(() => {
        const i = calls.findIndex((call) => promise === call.promise);
        if (i !== -1) calls.splice(i, 1);
      });
      calls.push({ args, promise });
    }
    return (timeout ?? 0) > 0 ? limitTime(promise, timeout!, ...Object.values(rest)) : promise;
  };
};

export default sharedCall;
