import { createSelector } from '@reduxjs/toolkit';
import intersection from 'lodash/intersection';

import type { TConfigServiceUplift } from '@/ducks/common/settings/types';
import type { TCurrencyCode } from '@/infra/types/common/base';
import type { RootState } from '@/store';

import { selectCommon } from '@/ducks/common/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

export const selectSettings = createSelector(selectCommon, (common) => common.settings);

export const selectSettingsAlertTimeInMin = (state: RootState) => +selectSettings(state)?.alertTimeInMin || undefined;

export const selectFormValidations = (state: RootState) => selectSettings(state).formValidations;

// export const selectDefaultAllowedCurrencies = (state: RootState) => selectSettings(state)?.allowedCurrencies;

// export const selectAdditionalTermsAndConditionsLink = (state: RootState) => selectSettings(state)?.additionalTermsAndConditionsLink;

export const selectConfigServiceCelebrationURL = (state: RootState) => selectSettings(state)?.celebrationURL;

// export const selectConfigServiceContactUsUrl = (state: RootState) => selectSettings(state)?.ContactUsUrl;

export const selectDaysLeftForDeposit = (state: RootState) => selectSettings(state)?.daysLeftForDeposit;

export const selectDaysLeftForHold = (state: RootState) => selectSettings(state)?.daysLeftForHold;

// export const selectPrivacyPolicyLink = (state: RootState) => selectSettings(state)?.privacyPolicyLink;

// export const selectTermsAndConditionsLink = (state: RootState) => selectSettings(state)?.termsAndConditionsLink;

export const selectUpliftMaximumPrices = (state: RootState) => selectConfigServiceUplift(state)?.maximumPrice;

export const selectUpliftMinimumPrices = (state: RootState) => selectConfigServiceUplift(state)?.minimumPrice;

export const selectUpliftMinimumDays = (state: RootState) => selectConfigServiceUplift(state)?.minimumDays;

// export const selectDepositWindowEnabled = (state: RootState) => selectSettings(state)?.depositWindowEnabled;

export const selectIsFutureVoyageCreditEnabled = (state: RootState) =>
  selectSettings(state)?.enableFutureVoyageCredit || false;

export const selectConfigServiceFormValidations = (state: RootState) => selectSettings(state)?.formValidations;

export const selectConfigServiceFeatureFlags = (state: RootState) => selectSettings(state)?.featureFlags;

export const selectConfigServiceFeatureCelebrations = (state: RootState) =>
  selectConfigServiceFeatureFlags(state)?.celebrations;

export const selectConfigServiceFeaturePhoneCommunication = (state: RootState) =>
  selectConfigServiceFeatureFlags(state)?.phoneCommunication;

export const selectConfigServiceFeaturePhoneNumber = (state: RootState) =>
  selectConfigServiceFeatureFlags(state)?.phoneNumber;

export const selectConfigServiceFeatureSpecialSailings = (state: RootState) =>
  selectConfigServiceFeatureFlags(state)?.specialSailings;

// export const selectConfigServiceFeatureUrgencyLayer = (state: RootState) => selectConfigServiceFeatureFlags(state)?.urgencyLayer;

export const selectConfigServiceFeatureUplift = (state: RootState) => selectConfigServiceFeatureFlags(state)?.uplift;

export const selectConfigServicePayInFullPromo = (state: RootState) => selectSettings(state)?.payInFullPromo;

export const selectConfigServicePaymentUrl = (state: RootState) => selectSettings(state)?.paymentUrl;

export const selectConfigServiceUplift = (state: RootState) => selectSettings(state)?.uplift;

// Memoized selectors:

export const selectFormErrors = createMemoSelector(selectFormValidations, (formValidations) =>
  Object.fromEntries(formValidations?.map(({ key, value }) => [key, value]) || []),
);

const objectKeys = Object.keys as <R>(arg: R) => Array<keyof R>;

export const selectUpliftSupportedCurrencies = createMemoSelector(
  selectConfigServiceUplift,
  (data: TConfigServiceUplift): TCurrencyCode[] =>
    intersection(...[data?.maximumPrice, data?.minimumDays, data?.minimumPrice].filter(Boolean).map(objectKeys)).sort(),
);
