import type { CookieSetOptions } from 'universal-cookie';

import type { TCurrencyCode } from '@/infra/types/common';
import type { RootState } from '@/store';

import { DAY_IN_MS } from '@/constants/dates';
import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { getCookie as clientsGetCookie, removeCookie, setCookie } from '@/ducks/cookies';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import removeSearchParamsNative from '@/helpers/removeSearchParamsNative';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';

import {
  AGENCY_COOKIE,
  AGENCY_CURRENCY_COOKIE,
  AGENT_COOKIE,
  FM_AGENCY_ID_KEY,
  FM_AGENT_ID_KEY,
  FM_BOOKING_CHANNEL_KEY,
} from './constants';
import { selectAgencyCurrencies, selectAgencyDefaultCurrencyCode } from './selectors';

const cookieOptions: CookieSetOptions = { expires: 0, path: '/', sameSite: 'strict' };
const DAYS_TO_KEEP = 15;

export const calculateAgencyCurrencyCode = ({
  getCookie,
  state,
  value,
}: {
  getCookie: typeof clientsGetCookie;
  state: RootState;
  value: null | string | string[];
}) => {
  const currencyCodeFromCookie = getCookie(CURRENCY_CODE_COOKIE) as TCurrencyCode;
  const ipBasedCurrencyCode = selectDefaultCurrencyCode(state) as TCurrencyCode;
  const availableCurrencies = selectAgencyCurrencies(state);

  if (selectAgencyCurrencies(state)?.includes(value as TCurrencyCode)) {
    return value;
  } else if (availableCurrencies?.includes(currencyCodeFromCookie)) {
    return currencyCodeFromCookie;
  } else if (availableCurrencies?.includes(ipBasedCurrencyCode)) {
    return ipBasedCurrencyCode;
  }

  return selectAgencyDefaultCurrencyCode(state);
};

export const setAgencyData = (state: RootState) => {
  if (state) {
    const searchParams = getCurrentSearchParams();
    const currencyCode = calculateAgencyCurrencyCode({
      getCookie: clientsGetCookie,
      state,
      value: searchParams.get('currencyCode'),
    });
    const expiresAt = new Date(new Date().getTime() + DAYS_TO_KEEP * DAY_IN_MS).getTime();
    setCookie(AGENT_COOKIE, { ...state.agentData.agentDetails, expiresAt }, { ...cookieOptions });
    setCookie(AGENCY_COOKIE, state.agentData.agencyDetails, { ...cookieOptions });
    setAgencyCurrencyCode(currencyCode as TCurrencyCode);
  }
};

export const removeAgencyData = () => {
  removeCookie(AGENT_COOKIE, cookieOptions);
  removeCookie(AGENCY_COOKIE, cookieOptions);
  removeCookie(AGENCY_CURRENCY_COOKIE, cookieOptions);
};

export const removeAgencySearchParams = () => {
  const { isMNVV } = getMnvvReservation(getCurrentSearchParams());
  if (!isMNVV) removeSearchParamsNative([FM_AGENCY_ID_KEY, FM_AGENT_ID_KEY, FM_BOOKING_CHANNEL_KEY]);
};

export const setAgencyCurrencyCode = (currencyCode: TCurrencyCode) => {
  if (currencyCode) {
    setCookie(AGENCY_CURRENCY_COOKIE, currencyCode, cookieOptions);
  }
};

export const removeAgencyCurrencyCode = () => {
  removeCookie(AGENCY_CURRENCY_COOKIE, cookieOptions);
};
