import { BookingChannelTypes } from '@/constants/settings';
import { type TRedirectConfig, type TRouteConfig } from '@/features/routeConfig/types';
import { getAuthFromStorage } from '@/helpers/auth/core';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import { type TOptional } from '@/types/common';

import { type TRedirectDetector } from './types';

const detectNonAuthMnvv: TRedirectDetector = (routeConfig?: TRouteConfig): TOptional<TRedirectConfig> => {
  const { nonAuthMnvv } = routeConfig ?? {};
  if (nonAuthMnvv?.redirectTo) {
    const searchParams = getCurrentSearchParams();
    const isMnvv =
      !!searchParams.get('reservationNumber') && searchParams.get('channelID') === BookingChannelTypes.openTravel;
    if (isMnvv) {
      const isNonAuth = !getAuthFromStorage();
      if (isNonAuth) return nonAuthMnvv;
    }
  }
};

export default detectNonAuthMnvv;
