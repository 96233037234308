import type {
  TPromoBannerDoubleClickDetails,
  TPromoBannerDoubleProps,
  TWithOnClick,
} from '@/features/promoBanners/types';

import Grabbable from '@/components/Grabbable';

import HalfBanner from './BannerHalf';

import './index.scss';

type TProps = TWithOnClick<TPromoBannerDoubleProps, TPromoBannerDoubleClickDetails>;

const PromoBannerDouble = ({ appearance, onClick, one, overlayColor, two }: TProps) => {
  const commonProps = { appearance, onClick, overlayColor };

  return (
    <Grabbable className="PromoBannerDouble-wrapper" sliding="horizontal" withShadows>
      <div className="PromoBannerDouble">
        <HalfBanner {...{ ...commonProps, ...one }} />
        <HalfBanner {...{ ...commonProps, ...two }} right />
      </div>
    </Grabbable>
  );
};

export default PromoBannerDouble;
