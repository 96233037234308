import type { TBoxSide, TOptional } from '@/types/common';

const getOppositeSide = (side: TOptional<TBoxSide>): TOptional<TBoxSide> => {
  switch (side) {
    case 'bottom':
      return 'top';
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    case 'top':
      return 'bottom';
    default:
      return undefined;
  }
};

export default getOppositeSide;
