'use client';

export const STYLES = {
  backgroundColor: '#f2f2f2',
  height: 'calc(100vh - 100px - 10px)',
  width: '100%',
};

const ContentSkeleton = () => <div style={STYLES} />;

export default ContentSkeleton;
