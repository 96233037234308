import cn from 'classnames';

import { getAccessKey, getIsAccessKeyStateValid } from '@/ducks/accessKeys';
import { selectFilteredPackages, selectMainPackagesIsLoaded } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import useSsrInitialValues from '@/hooks/useSsrInitialValues';
import { type FilteredPackage } from '@/infra/types/voyageInfo/package';
import { useAppSelector } from '@/store';

import PackageCardSkeleton from './PackageCard/PackageCardSkeleton';
import PackageCardList from './PackageCardList/PackageCardList';

import './PackageCardDetails.scss';

type TProps = {
  ssrInitial?: {
    isLoaded: boolean;
    isLoading: boolean;
    packages: FilteredPackage[];
  };
};

const PackageCardDetails = ({ ssrInitial }: TProps) => {
  const values = {
    isLoaded: useAppSelector(selectMainPackagesIsLoaded),
    isLoading: useAppSelector(selectIsFilteringLoading),
    packages: useAppSelector(selectFilteredPackages),
  };
  const { isLoaded, isLoading, packages } = useSsrInitialValues(values, ssrInitial) ?? {};
  const accessKeyDetails = useAppSelector(getAccessKey);
  const isAKValid = getIsAccessKeyStateValid(accessKeyDetails?.state);

  const cssClasses = cn('PackageCardChooseVoyage', {
    isAKWrapper: isAKValid,
    isSinglePackage: packages.length === 1,
  });

  if (!isLoaded || isLoading) {
    return (
      <div className={cssClasses} id="PackageCardChooseVoyage">
        <PackageCardSkeleton key={1} />
        <PackageCardSkeleton key={2} />
        <PackageCardSkeleton key={3} />
      </div>
    );
  }

  return (
    <div className={cssClasses} id="PackageCardChooseVoyage">
      <PackageCardList packages={packages} />
    </div>
  );
};

export default PackageCardDetails;
