import { useMemo, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import { isServerSide } from '@/helpers/isServerSide';
import { type TOptional } from '@/types/common';

export const useIsChanged = (observedValues: unknown[], isOnlyAll?: boolean): boolean => {
  const startRef = useRef<unknown[]>(observedValues);
  return useMemo(() => {
    const check = (value: unknown, i: number) => !isEqual(value, startRef.current[i]);
    return isOnlyAll ? observedValues.every(check) : observedValues.some(check);
  }, observedValues);
};

const useSsrInitialValues = <V extends Record<string, unknown>>(
  values: V,
  ssrInitialValues: TOptional<V>,
  isOnlyAll?: boolean,
): TOptional<V> => {
  const isChanged = useIsChanged(Object.values(values), isOnlyAll);
  const isSsrStage = isServerSide();
  return useMemo(
    () => (!isSsrStage && (!ssrInitialValues || isChanged) ? values : ssrInitialValues!),
    [isChanged, isSsrStage, values],
  );
};

export default useSsrInitialValues;
