import {
  CLEAR_SUMMARY_SAILING_DATA,
  SUMMARY_SAILING_DATA_CLEAR_ERROR,
  SUMMARY_SAILING_DATA_ERROR,
  SUMMARY_SAILING_DATA_FETCHING_START,
  SUMMARY_SAILING_DATA_SUCCESS,
} from '@/constants/actionTypes';

const defaultState = {
  data: {},
  error: {},
  isLoaded: false,
};

export default function sailingDataReducer(store = defaultState, action) {
  switch (action.type) {
    case CLEAR_SUMMARY_SAILING_DATA:
      return defaultState;
    case SUMMARY_SAILING_DATA_ERROR:
      return { ...store, data: {}, error: action.payload };
    case SUMMARY_SAILING_DATA_CLEAR_ERROR:
      return { ...store, error: {} };
    case SUMMARY_SAILING_DATA_FETCHING_START:
      return { ...store, error: {} };
    case SUMMARY_SAILING_DATA_SUCCESS:
      return { ...store, data: action.payload || {}, error: !action.payload ? { error: true } : {}, isLoaded: true };
    default:
      return store;
  }
}
