import getSymbolFromCurrenciesData from './currency/currencySymbols';

export const enum Fraction {
  NOT_INT = 'not-int', // default
  NOT_ZERO = 'not-zero',
  ALWAYS = 'always',
}

export type TFormatMoneyOptions = {
  fraction?: Fraction;
  onlySymbol?: boolean;
};

const formatMoney = (amount: number, currencyCode: string, options?: TFormatMoneyOptions): string => {
  const { fraction, onlySymbol } = options || {};
  const asInteger =
    (!amount && fraction === Fraction.NOT_ZERO) || (Number.isInteger(amount) && fraction !== Fraction.ALWAYS);
  const fractionDigits = asInteger ? 0 : 2;
  return [
    amount < 0 ? '-' : '',
    getSymbolFromCurrenciesData(currencyCode, { onlySymbol }),
    Math.abs(amount).toLocaleString('en-US', {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }),
  ].join('');
};

export default formatMoney;
