import { type RefObject, useEffect, useRef } from 'react';

import { INTERACTIVE_ELEMENTS_SELECTOR } from '@/ducks/a11y/constants';
import delay from '@/helpers/delay';

// Since using the "autoFocus" prop on react element may cause to scroll up, so this hook can be used as replacement

const useAutoFocus = <E extends HTMLElement = HTMLElement>(
  isAllow?: boolean,
  selector?: string,
): null | RefObject<E> => {
  const ref = useRef<E>(null);

  useEffect(
    () => {
      if (isAllow) {
        delay(0).then(() =>
          ref.current?.querySelector<HTMLElement>(selector || INTERACTIVE_ELEMENTS_SELECTOR)?.focus(),
        );
      }
    },
    /* on mount - no dependencies */ [],
  );

  return ref;
};

export default useAutoFocus;
