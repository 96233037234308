import type { TImage } from '@/infra/types/common';

import { GET_SPECIAL_SAILINGS, useGQLQuery } from '@/helpers/api/graphql';
import { useAsyncError } from '@/hooks/useAsyncError';

type SpecialSailings = {
  description: string | string[];
  id: string;
  mobileModalHero: null | TImage;
  modalDescription: null | string;
  name: string;
  portraitCarousel: TImage[];
  priority: number;
  ribbonColorOverride: null | string;
  showRibbon: boolean;
  subtitle: string;
};

export const useGetSpecialSailings = (): SpecialSailings[] => {
  const { data, error, loading } = useGQLQuery(GET_SPECIAL_SAILINGS);
  const throwError = useAsyncError();

  if (error) {
    throwError(error);
  }

  if (loading) {
    return [];
  }

  const specialSailings = data.lookup.specialSailings;

  return specialSailings;
};
