import { useMemo } from 'react';

import getUserAgent from '@/helpers/userAgent/getUserAgent';

export const useIsMobile = (): boolean => {
  const userAgent = getUserAgent();
  return useMemo(
    () => /Android|BlackBerry|webOS|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Windows Phone/i.test(userAgent),
    [userAgent],
  );
};

export const useIsDesktop = () => !useIsMobile();
