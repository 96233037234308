import { type JSXElementConstructor, type NamedExoticComponent, useCallback, useRef } from 'react';

import { type TRouteChange, type TRouteChangeListener } from './types';
import useOnRouteChange from './useOnRouteChange';

export type TWithOnRouteChange = {
  onRouteChange: (listener: TRouteChangeListener) => void;
};

const withOnRouteChange = <TProps extends TWithOnRouteChange>(Component: JSXElementConstructor<TProps>) => {
  const Wrapped = (props: TProps) => {
    const ref = useRef<TRouteChangeListener>();

    const onRouteChange = useCallback((listener: TRouteChangeListener) => {
      ref.current = listener;
    }, []);

    const handler = useCallback((change: TRouteChange) => {
      ref.current?.(change);
    }, []);

    useOnRouteChange(handler);

    return <Component {...props} onRouteChange={onRouteChange} />;
  };

  Wrapped.displayName = `withOnRouteChange<${(Component as NamedExoticComponent).displayName || Component.name}>`;
  return Wrapped;
};

export default withOnRouteChange;
