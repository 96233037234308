import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { env } from '@/environment';
import subscribeOnDOMEvent from '@/helpers/subscribeOnDOMEvent';

import RouteChangeContext from './Context';

const useRouteChangeObserver = () => {
  const path = usePathname()!;
  const params = useSearchParams()!;
  const refPrevPath = useRef<string>();
  const refPrevParams = useRef<URLSearchParams>();
  const { fire } = useContext(RouteChangeContext);

  const update = useCallback((nextPath: string, nextParams: URLSearchParams) => {
    const prevPath = refPrevPath.current;
    const prevParams = refPrevParams.current;
    refPrevPath.current = nextPath;
    refPrevParams.current = nextParams;
    fire(nextPath, nextParams, prevPath, prevParams);
  }, []);

  useEffect(
    () =>
      subscribeOnDOMEvent(window, 'popstate', () => {
        const { pathname, search } = window.location;
        const prefix = env.CONTEXT;
        if (pathname.startsWith(prefix)) update(pathname.substring(prefix.length), new URLSearchParams(search));
      }),
    [],
  );

  useEffect(() => update(path, params), [params, path]);
};

export default useRouteChangeObserver;
