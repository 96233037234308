import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type FiltersTypes } from './types';

type FilterValuesInitialState = {
  dependencies?: string;
  values: FiltersTypes;
};

const initialState: FilterValuesInitialState = {
  values: {} as FiltersTypes,
};

const updateFilterValuesState: CaseReducer<FilterValuesInitialState, PayloadAction<FilterValuesInitialState>> = (
  state,
  { payload: { dependencies, values } },
) => {
  state.values = values;
  state.dependencies = dependencies;
};

export const { actions, reducer } = createSlice({
  initialState,
  name: 'filterValues',
  reducers: {
    updateFilterValuesState,
  },
});
