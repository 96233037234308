import React, { useCallback, useEffect, useId, useRef, useState } from 'react';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import cn from 'classnames';

import ChevronIcon from '@publicImages/svg/chevron-down.svg?icon';

import { ListBox, ListItem } from '@/components/ListBox';
import isSafari from '@/helpers/util/isSafari';

import styles from './Select.module.scss';

const getLabel = (label: React.ReactNode | string | string[]) => (Array.isArray(label) ? label[0] : label);

interface SelectProps<T> {
  'aria-label'?: string;
  className?: string;
  defaultValue?: T;
  disabled?: boolean;
  dropdownClassName?: string;
  icon?: React.ReactNode;
  id?: string;
  onValueChange: (value: T) => void;
  options: readonly {
    enabled?: boolean;
    label: [string] | React.ReactNode | string;
    value: T;
  }[];
  size?: 'default' | 'small';
  value: T;
}

const Select = <T,>({
  'aria-label': ariaLabel,
  className,
  defaultValue,
  disabled,
  dropdownClassName,
  icon,
  onValueChange,
  options,
  size,
  value,
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();

  const isNotDefaultValue = value !== (defaultValue || options[0]?.value);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const wasOpenedByClickRef = useRef<boolean>(false);

  const [shouldAnnounceCollapsedState, setShouldAnnounceCollapsedState] = useState(isSafari());
  const [shouldReadExpandedState, setShouldReadExpandedState] = useState(true);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setShouldReadExpandedState(isOpen);
      setIsOpen(isOpen);
    },
    [setIsOpen],
  );

  const { context, floatingStyles, refs } = useFloating({
    elements: {
      reference: buttonRef?.current,
    },
    middleware: [flip(), offset(6)],
    onOpenChange,
    open: isOpen,
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, { enabled: !disabled });
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  const onValueChangeHandler = useCallback(
    (value: T) => {
      onValueChange(value);
      setTimeout(() => {
        context.onOpenChange(false);
      }, 300); // Slightly delaying dropdown close so SR could spell newly selected value
    },
    [onValueChange, context],
  );

  const handleOnBlur = useCallback(() => {
    setShouldAnnounceCollapsedState(isSafari());
  }, []);

  useEffect(() => {
    const button = buttonRef?.current;

    const toggle = (event: KeyboardEvent) => {
      if (!isOpen && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
        event.preventDefault();
        context.onOpenChange(true);
      }
    };

    button?.addEventListener('keydown', toggle);

    return () => {
      button?.removeEventListener('keydown', toggle);
    };
  }, [buttonRef, context, isOpen]);

  const defaultId = useId();
  const selectedOptionIndex = options.findIndex((option) => option.value === value);
  const selectedOption = options[selectedOptionIndex];
  const comboboxId = id || defaultId;
  const ariaLabelId = `${comboboxId}Label`;
  const listboxId = `${comboboxId}Listbox`;

  const handleOnClick = () => {
    if (!isOpen) {
      wasOpenedByClickRef.current = true;
    }
  };

  return (
    <>
      {ariaLabel && (
        <div className="sr-only" id={ariaLabelId}>
          {ariaLabel}
          {selectedOption ? (
            <>
              <span>, current value </span>
              {selectedOption?.label}
            </>
          ) : undefined}
          {`${shouldAnnounceCollapsedState ? ', collapsed' : ''}`}
        </div>
      )}

      <div
        aria-controls={listboxId}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-labelledby={ariaLabelId}
        className={cn(styles.root, className, {
          [styles._smallPill]: size === 'small',
        })}
        data-applied={isNotDefaultValue}
        data-disabled={disabled}
        id={comboboxId}
        ref={buttonRef}
        role="button"
        tabIndex={0}
        {...getReferenceProps({ onClick: handleOnClick })}
      >
        {icon}

        <span className={styles.value}>
          <span>
            <span className="sr-only">current value</span> {selectedOption?.label}
          </span>
        </span>

        <span aria-hidden={true} className={styles.dropdownIndicator}>
          <ChevronIcon />
        </span>
      </div>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context}>
            <ListBox
              aria-labelledby={ariaLabelId}
              disableFocusRef={wasOpenedByClickRef}
              id={listboxId}
              infiniteNavigation
              onBlur={handleOnBlur}
              onItemFocusChange={() => setShouldReadExpandedState(false)}
              onSelectItem={onValueChangeHandler}
              ref={refs.setFloating}
              style={floatingStyles}
              value={value}
              {...getFloatingProps({ className: cn(styles.dropdown, dropdownClassName) })}
            >
              {options.map((option, index) => {
                if (option.enabled === false) {
                  return null;
                }

                return (
                  <ListItem
                    className={styles.option}
                    id={`${id}Option_${index}`}
                    key={`${comboboxId}, ${option.label}, ${option.value}`}
                    value={option.value}
                  >
                    {shouldReadExpandedState && <span className="sr-only">{ariaLabel}, expanded, </span>}
                    {getLabel(option.label)}
                  </ListItem>
                );
              })}
            </ListBox>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default Select;
