import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import { isProdEnv } from '@/helpers/util/env';

export const loadApolloLogs = () => {
  if (isProdEnv()) return;

  loadDevMessages();
  loadErrorMessages();
};
