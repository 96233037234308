import { isServerSide } from '@/helpers/isServerSide';

const getCurrentSearchParams = (): URLSearchParams => {
  if (isServerSide()) {
    const { headers } = require('next/headers');
    const hs = headers();
    return new URLSearchParams(hs.get('x-search'));
  }
  return new URLSearchParams(window.location.search);
};

export default getCurrentSearchParams;
