import {
  CLEAR_SUMMARY_SAILING_DATA,
  SUMMARY_SAILING_DATA_CLEAR_ERROR,
  SUMMARY_SAILING_DATA_ERROR,
  SUMMARY_SAILING_DATA_FETCHING_START,
  SUMMARY_SAILING_DATA_SUCCESS,
} from '@/constants/actionTypes';
import { fetchItinerarySummaryData } from '@/helpers/api/app';

export const fetchSailingDataAction =
  ({ packageCode, voyageId }) =>
  async (dispatch) => {
    let data = {};

    dispatch({
      payload: data,
      type: SUMMARY_SAILING_DATA_FETCHING_START,
    });

    try {
      data = await fetchItinerarySummaryData({ packageCode, voyageId });
      dispatch({
        payload: data,
        type: SUMMARY_SAILING_DATA_SUCCESS,
      });
    } catch (err) {
      dispatch({
        payload: `${err}`,
        type: SUMMARY_SAILING_DATA_ERROR,
      });
    }

    return data;
  };

export const updateSailingData = (sailingData) => async (dispatch) => {
  dispatch({
    payload: sailingData,
    type: SUMMARY_SAILING_DATA_SUCCESS,
  });
};

export const clearSailingData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUMMARY_SAILING_DATA,
  });
};

export const clearSailingDataError = () => async (dispatch) => {
  dispatch({
    type: SUMMARY_SAILING_DATA_CLEAR_ERROR,
  });
};

export default fetchSailingDataAction;
