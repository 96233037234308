'use client';

import { useCallback, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import UIResource from '@/components/UIResource';
import { MerchandisingBanner } from '@/features/promo/MerchandisingBanner';
import useSsrInitialValues from '@/hooks/useSsrInitialValues';
import { useAppSelector } from '@/store';

import { useIsPromoActive } from '../lib/useIsPromoActive';
import { selectHeadlineBackground } from '../QuickSearchCards/model/selectors';

import classes from './Headline.module.scss';

type TProps = {
  children: React.ReactNode;
  ssrInitial?: {
    background: string;
  };
};

export const Headline = ({ children, ssrInitial }: TProps) => {
  const values = {
    background: useAppSelector(selectHeadlineBackground),
  };
  const { background } = useSsrInitialValues(values, ssrInitial) ?? {};
  const backgroundImage = useMemo(
    () => (background ? `url("data:image/svg+xml,${encodeURIComponent(background!)}")` : undefined),
    [background],
  );

  const isBookingHeaderActive = useIsPromoActive('bookingHeader');

  const observer = useRef<null | ResizeObserver>(null);
  const [wrapped, setWrapped] = useState(false);
  const headingResizer = useCallback((el: HTMLDivElement | null) => {
    if (el == null) {
      return observer.current?.disconnect();
    }

    const syncIsWrapped = () => {
      const childrenSumWidth =
        [...el.children].reduce((acc, e) => acc + e.clientWidth, 0) +
        parseInt(getComputedStyle(el).getPropertyValue('gap'));
      setWrapped(childrenSumWidth > el.clientWidth);
    };
    syncIsWrapped();

    observer.current = new ResizeObserver(syncIsWrapped);
    observer.current.observe(el);
  }, []);

  if (!isBookingHeaderActive) {
    return children;
  }

  return (
    <div className={classes.root}>
      <div className={classes.backgroundMask} style={{ backgroundImage }} />
      <div className={cn(classes.heading, wrapped && classes.wrapped)} ref={headingResizer}>
        <div className={classes.underlineWrapper}>
          <h2 className={classes.title}>
            <UIResource id="Promotion.pageTitle" />
          </h2>
        </div>
        <MerchandisingBanner type="countdownClock" />
      </div>
      {children}
    </div>
  );
};
