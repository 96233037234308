import { isLockItCabin } from '@/ducks/programs/lockItInRate/isLockItCabin';

import { FILTER_KEY, type FiltersConfig } from '../types';
import { toBoolean, toStringArray } from '../utils';
import cabins from './cabins';
import currencyCode from './currencyCode';
import { fromDate, toDate } from './dates';
import { destPackages, destPackagesRegions, destPorts, destPortsRegions } from './destinations';
import durations from './durations';
import homePorts from './homePorts';
import metaType from './metaType';
import { maxPrice, minPrice, priceType } from './price';
import sailors from './sailors';
import ships from './ships';
import sortType from './sortType';
import sortTypeChooseCabin from './sortTypeChooseCabin';

export const config: FiltersConfig = {
  [FILTER_KEY.accessible]: {
    getValue: ({ value }) => toBoolean(value),
    serializeValue: (value) => (!value ? null : 'true'),
  },
  [FILTER_KEY.accessKey]: {},
  [FILTER_KEY.cabins]: cabins,
  [FILTER_KEY.cabinType]: {
    isReadOnly: (value) => isLockItCabin(value as string),
  },
  [FILTER_KEY.currencyCode]: currencyCode,
  [FILTER_KEY.dateFrom]: fromDate,
  [FILTER_KEY.dateTo]: toDate,
  [FILTER_KEY.destPackages]: destPackages,
  [FILTER_KEY.destPackagesRegions]: destPackagesRegions,
  [FILTER_KEY.destPorts]: destPorts,
  [FILTER_KEY.destPortsRegions]: destPortsRegions,
  [FILTER_KEY.durations]: durations,
  [FILTER_KEY.homePorts]: homePorts,
  [FILTER_KEY.metaType]: metaType,
  [FILTER_KEY.priceMax]: maxPrice,
  [FILTER_KEY.priceMin]: minPrice,
  [FILTER_KEY.priceType]: priceType,
  [FILTER_KEY.sailors]: sailors,
  [FILTER_KEY.ships]: ships,
  [FILTER_KEY.sortType]: sortType,
  [FILTER_KEY.sortTypeChooseCabin]: sortTypeChooseCabin,
  [FILTER_KEY.voyageIds]: {
    getValue: ({ value }) => toStringArray(value),
  },
  [FILTER_KEY.weekend]: {
    getValue: ({ value }) => toBoolean(value),
    serializeValue: (value) => (!value ? null : 'true'),
  },
};
