import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import type { TNullable } from '@/infra/types/common';

import IconButton from '@/components/elements/IconButton';
import Trash from '@/components/Icon/Trash';

import styles from './MarqueeBannerContainer.module.scss';

type TProps = {
  children: React.ReactNode;
  className?: string;
  desktopClearText?: string;
  mobileClearText?: string;
  onClearClick?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
  url?: TNullable<string>;
};

const MarqueeBannerContainer = ({
  children,
  className,
  desktopClearText = 'Clear',
  mobileClearText = 'Clear',
  onClearClick,
  onClick,
  url,
}: TProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setAnimationTime = () => {
      if (rootRef.current && contentRef.current) {
        const rootSpeed = 20 * (contentRef.current.clientWidth / rootRef.current.clientWidth);
        const screenWidthMultiplier = window.innerWidth / 1440;
        const animationTime = rootSpeed * screenWidthMultiplier;
        rootRef.current.style.setProperty('--marquee-animation-time', `${animationTime}s`);
      }
    };
    setAnimationTime();

    window.addEventListener('resize', setAnimationTime);
    return () => {
      window.removeEventListener('resize', setAnimationTime);
    };
  }, []);

  const Node = url ? 'a' : 'div';
  const linkProps = url ? { href: url, target: '_blank' } : undefined;

  return (
    <div className={cn(styles.root, className)} ref={rootRef}>
      <Node
        className={cn(styles.wrapper, { [styles._clickable as string]: url || onClick })}
        onClick={onClick}
        role="none"
        {...linkProps}
      >
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
        <div aria-hidden className={styles.content}>
          {children}
        </div>
      </Node>

      {onClearClick && (
        <IconButton aria-label={desktopClearText} border className={styles.clearButton} onClick={onClearClick}>
          {desktopClearText && <span className={cn(styles.label, styles._desktop)}>{desktopClearText}</span>}
          {mobileClearText && <span className={cn(styles.label, styles._mobile)}>{mobileClearText}</span>}

          <Trash />
        </IconButton>
      )}
    </div>
  );
};

export default MarqueeBannerContainer;
