import { kebabCase } from 'lodash';

import type { Package } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import constants from '@/tagmanager/constants';
import { trackButtonClick, trackLinkClick, trackPopupState, trackSocialShare } from '@/tagmanager/event/types/common';
import { correlateFullAndShortRegion } from '@/tagmanager/event/types/voyageDetails/fixCuttedRegionNames';
import { takeAlmostSoldOutLabel } from '@/tagmanager/event/types/voyageDetails/sailingLabels';
import { createTracker } from '@/tagmanager/tracker';

let trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const SOCIAL_SHARE_POSITIONS = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

export const initTracker = (selectedPackage: Package) => {
  const region = correlateFullAndShortRegion(selectedPackage);
  const moduleName = selectedPackage ? `${kebabCase(region)}|${kebabCase(selectedPackage?.packageName)}` : null;
  trackEvent = createTracker({
    event: constants.EVENTS.GTMevent,
    module: moduleName,
  });
};

export const trackOnBackClick = (label: string) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [label],
  });
};

export const trackOnShareClick = (platform: string, position: number) => {
  trackSocialShare({
    eventEmitter: trackEvent,
    labels: [platform, position],
  });
};

export const trackOnPortClick = (port: string) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [port],
  });
};

export const trackNextSailingDateClick = (sailing: SailingFromPackages) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.NEXT_SAILING_DATES, takeAlmostSoldOutLabel(sailing)],
  });
};

export const trackNextSailingDateFlyoutClick = (sailing: SailingFromPackages) => {
  trackLinkClick({
    eventEmitter: trackEvent,
    flyouts: [constants.FLYOUTS.ITINERARY_SAILING_DATES],
    labels: [constants.EVENT_LABELS.VOYAGE_FILTERS.NEXT_SAILING_DATES, takeAlmostSoldOutLabel(sailing)],
  });
};

export const trackCabinTypeClick = (cabinType: string) => {
  const transformedLabel = kebabCase(cabinType);
  trackEvent({
    action: constants.EVENT_ACTIONS.CARD_CLICK,
    label: transformedLabel,
  });
};

export const trackViewMoreDatesToggle = (state: boolean) =>
  trackPopupState({
    eventEmitter: trackEvent,
    flyouts: [constants.FLYOUTS.ITINERARY_SAILING_DATES],
    isOpen: state,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackFullCruiseClick = (args: any) =>
  trackButtonClick({
    eventEmitter: trackEvent,
    labels: [constants.EVENT_LABELS.FULL_CRUISE_DETAILS],
    ...args,
  });
