import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import defaultMessagesFile from '@/constants/defaultUIResources.json';
import parseCmsTemplate from '@/ducks/common/resources/helpers/parseCmsTemplate';
import { selectResources } from '@/ducks/common/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

import type {
  ResourcesSlice,
  TUIResourceId,
  TUIResourceImage,
  TUIResourceNode,
  TUIResourcePool,
  TUIResourceStorable,
  TUIResourceValueMap,
} from './types';

import safeJoin from './helpers/safeJoin';
import { ErrorPolicy } from './types';

const defaultMessages = defaultMessagesFile satisfies Record<string, string | { alt?: string; src: string }>;

export const combineUIResourcePool = <TKey extends string, TImageKey extends string = never>(
  resources: ResourcesSlice,
  resourceIds: Record<TKey, TUIResourceId>,
) =>
  mapValues(resourceIds, (id: TUIResourceId) => resources?.[id] ?? defaultMessages[id]) as TUIResourcePool<
    TKey,
    TImageKey
  >;

export const combineUIResource = <R extends string | TUIResourceImage | TUIResourceNode = string>(
  resources: ResourcesSlice,
  resourceId: TUIResourceId,
  values?: TUIResourceValueMap,
): R => {
  const defaultResource = defaultMessages[resourceId];
  const resource: TUIResourceStorable = resources?.[resourceId] ?? defaultResource;
  if (resource && typeof resource === 'string' && values && !isEmpty(values)) {
    let parts;
    try {
      parts = parseCmsTemplate(resource, values, ErrorPolicy.THROW);
    } catch (err) {
      parts =
        defaultResource && typeof defaultResource === 'string'
          ? parseCmsTemplate(defaultResource, values, ErrorPolicy.LEAVE_AS_IS)
          : [''];
    }
    return safeJoin(parts, resourceId) as R;
  }
  return resource as R;
};

// When using single value caching, need to memoize a more particular selector, not a general one. Therefore:

export const makeUIResourcePoolSelector = <TKey extends string, TImageKey extends string = never>(
  resourceIds: Record<TKey, TUIResourceId>,
) => createMemoSelector(selectResources, (resources) => combineUIResourcePool<TKey, TImageKey>(resources, resourceIds));

export const makeUIResourceSelector = <R extends string | TUIResourceImage | TUIResourceNode = string>(
  resourceId: TUIResourceId,
  values?: TUIResourceValueMap,
) => createMemoSelector(selectResources, (resources) => combineUIResource<R>(resources, resourceId, values));
