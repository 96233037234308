import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

import type { TCabinHoldData } from '@/infra/types/cabin';
import type { TOptional } from '@/types/common';

import { SUMMARY_UNHOLD_CABIN_SUCCESS } from '@/constants/actionTypes';

type HoldStates = 'holding' | 'holded' | 'unholding' | 'empty';

export type TStoreCabinHold = {
  data: TCabinHoldData;
  error: TOptional<string>;
  state: HoldStates;
};

const initialState: TStoreCabinHold = {
  data: {} as TCabinHoldData,
  error: undefined,
  state: 'empty',
};

/**
 * Case Reducers
 */

const setDataCase: CaseReducer<TStoreCabinHold, PayloadAction<TCabinHoldData>> = (stateDraft, { payload }) => {
  stateDraft.data = payload;
  stateDraft.error = initialState.error;
  stateDraft.state = 'holded';
};

const setErrorCase: CaseReducer<TStoreCabinHold, PayloadAction<string>> = (stateDraft, { payload }) => {
  stateDraft.data = initialState.data;
  stateDraft.error = payload;
  stateDraft.state = 'empty';
};

const setState: CaseReducer<TStoreCabinHold, PayloadAction<HoldStates>> = (stateDraft, { payload }) => {
  stateDraft.state = payload;
};

const clearCase: CaseReducer<TStoreCabinHold, PayloadAction<undefined>> = () => ({ ...initialState });

/* eslint-disable perfectionist/sort-objects */
const { actions, reducer } = createSlice({
  name: 'cabinHold',
  initialState,
  reducers: {
    setData: setDataCase,
    setError: setErrorCase,
    setState: setState,
    clear: clearCase,
  },
  extraReducers: (builder) => {
    builder.addMatcher((action) => action.type === SUMMARY_UNHOLD_CABIN_SUCCESS, clearCase);
  },
});
/* eslint-enable perfectionist/sort-objects */

export const {
  clear: cabinHoldClear,
  setData: cabinHoldSetData,
  setError: cabinHoldSetError,
  setState: cabinHoldSetState,
} = actions;

export default reducer;
