import { SET_PAY_IN_FULL_PROMO_APPLIED_FLAG } from '@/constants/actionTypes.js';

export const setIsPayInFullPromoAppliedFlag = (payInFullPromoFlag) => ({
  payload: payInFullPromoFlag,
  type: SET_PAY_IN_FULL_PROMO_APPLIED_FLAG,
});

export const payInFullPromoAppliedAction = (payInFullPromoFlag) => async (dispatch) => {
  dispatch(setIsPayInFullPromoAppliedFlag(payInFullPromoFlag));
};

export default payInFullPromoAppliedAction;
