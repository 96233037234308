import { type PropsWithChildren, type RefObject, useCallback, useRef } from 'react';

import cn from 'classnames';

import IconPause from '@publicImages/svg/playback-pause-small.svg?static';
import IconPlay from '@publicImages/svg/playback-play-small.svg?static';

import type { TPromoBannerCtaLink, TPromoBannerFeaturetteProps, TWithOnClick } from '@/features/promoBanners/types';
import type { TLink } from '@/infra/types/common';

import Button from '@/components/elements/Button';
import ImageSet from '@/components/Media/ImageSet';
import VideoSet, { type TVideoControls } from '@/components/Media/VideoSet';

import CTA from '../CTA';
import Eyebrow from '../Eyebrow';
import verifyValue from '../helpers/verifyValue';

import styles from './PromoBannerFeaturette.module.scss';

const APPEARANCES = ['standard', 'adaLockup'];
const CONTENT_ALIGNS = ['left', 'right'];
const THEMES = ['lightMode', 'darkMode'];
const OVERLAY_COLORS = [
  'virginRedLight',
  'virginRedDark',
  'rockstarLight',
  'rockstarDark',
  'upliftLight',
  'upliftDark',
  'squidInkLight',
  'squidInkDark',
  'oceanBlueLight',
  'oceanBlueDark',
];

type TVideoControlProps = PropsWithChildren<{
  actionHandler: () => void;
  ariaLabel: string;
  controlRef: RefObject<HTMLButtonElement>;
  wrapperClassName: string;
}>;

const VideoControl = ({ actionHandler, ariaLabel, children, controlRef, wrapperClassName }: TVideoControlProps) => (
  <Button
    aria-label={ariaLabel}
    className={cn(wrapperClassName, styles.controlIconWrapper)}
    onClick={actionHandler}
    ref={controlRef}
  >
    {children}
  </Button>
);

const PromoBannerFeaturette = ({
  appearance,
  contentAlign,
  cta,
  description,
  eyebrow,
  imageSet,
  onClick,
  overlayColor,
  theme,
  title,
  videoSet,
}: TWithOnClick<TPromoBannerFeaturetteProps>) => {
  const playControlRef = useRef<HTMLButtonElement>(null);
  const pauseControlRef = useRef<HTMLButtonElement>(null);
  const hasVideo = !!videoSet?.srcSet;
  const hasImage = !!imageSet?.srcSet;
  const hasCTA = !!cta?.url && !!cta?.label;
  const rootClasses = [
    `_appearance-${verifyValue(appearance, APPEARANCES)}`,
    `_align-${verifyValue(contentAlign, CONTENT_ALIGNS)}`,
    `_theme-${verifyValue(theme, THEMES)}`,
    `_overlay-${verifyValue(overlayColor, OVERLAY_COLORS)}`,
  ];

  const refVideoControls = useRef<TVideoControls>();
  const onVideoReady = useCallback((videoControls: TVideoControls) => {
    refVideoControls.current = videoControls;
  }, []);

  const onPlay = () => refVideoControls.current?.play();
  const onPause = () => refVideoControls.current?.pause();

  const getControlActionHandler = (actionHandler: () => void, nextFocusRef: RefObject<HTMLButtonElement>) => () => {
    actionHandler();
    setTimeout(() => nextFocusRef.current?.focus(), 0);
  };

  const videoFallback = hasImage ? <ImageSet className={styles.picture} {...imageSet} /> : undefined;

  return (
    <div className={cn(styles.root, ...rootClasses)}>
      {hasVideo && (
        <>
          <VideoSet
            autoPlay
            className={styles.video}
            fallback={videoFallback}
            loop
            muted
            onReady={onVideoReady}
            srcSet={videoSet.srcSet}
          >
            <VideoControl
              actionHandler={getControlActionHandler(onPause, playControlRef)}
              ariaLabel="Pause"
              controlRef={pauseControlRef}
              key="pause"
              wrapperClassName={styles.pause}
            >
              <IconPause aria-hidden className={cn(styles.controlIcon)} />
            </VideoControl>
            <VideoControl
              actionHandler={getControlActionHandler(onPlay, pauseControlRef)}
              ariaLabel="Play"
              controlRef={playControlRef}
              key="play"
              wrapperClassName={styles.play}
            >
              <IconPlay aria-hidden className={cn(styles.controlIcon)} />
            </VideoControl>
          </VideoSet>
        </>
      )}
      {!hasVideo && videoFallback}
      <div className={styles.content}>
        {!!eyebrow && <Eyebrow className={styles.eyebrow} {...eyebrow} isOmitPastCountdown />}
        <div className={styles.textual}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        {hasCTA && <CTA className={styles.cta} onClick={onClick} {...(cta as TLink & TPromoBannerCtaLink)} />}
      </div>
    </div>
  );
};

export default PromoBannerFeaturette;
